import React from "react";

const QuerieEditIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.9641 7.21044L24.7867 10.0331M23.7787 4.72377L16.1427 12.3598C15.747 12.7529 15.4777 13.2553 15.3694 13.8024L14.6641 17.3331L18.1947 16.6264C18.7414 16.5171 19.2427 16.2491 19.6374 15.8544L27.2734 8.21844C27.5029 7.98897 27.6849 7.71656 27.8091 7.41675C27.9332 7.11694 27.9972 6.79561 27.9972 6.4711C27.9972 6.14659 27.9332 5.82526 27.8091 5.52545C27.6849 5.22564 27.5029 4.95323 27.2734 4.72377C27.0439 4.49431 26.7715 4.31229 26.4717 4.1881C26.1719 4.06392 25.8506 4 25.5261 4C25.2016 4 24.8802 4.06392 24.5804 4.1881C24.2806 4.31229 24.0082 4.49431 23.7787 4.72377Z"
        stroke="#387CFF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.332 19.9998V23.9998C25.332 24.7071 25.0511 25.3854 24.551 25.8855C24.0509 26.3856 23.3726 26.6665 22.6654 26.6665H7.9987C7.29145 26.6665 6.61318 26.3856 6.11308 25.8855C5.61298 25.3854 5.33203 24.7071 5.33203 23.9998V9.33317C5.33203 8.62593 5.61298 7.94765 6.11308 7.44755C6.61318 6.94746 7.29145 6.6665 7.9987 6.6665H11.9987"
        stroke="#387CFF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default QuerieEditIcon;
