import React from "react";

const ArrowBack = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.13047 15.166L15.6638 21.6994L14.0013 23.3327L4.66797 13.9993L14.0013 4.66602L15.6638 6.29935L9.13047 12.8327H23.3346V15.166H9.13047Z" fill="#1D1B20" />
    </svg>
  );
};

export default ArrowBack;
