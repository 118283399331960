import React from "react";

function DocumentIcon() {
  return (
    <svg
      width="79"
      height="79"
      viewBox="0 0 79 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.5 1.49976C66.2348 1.49976 65.9804 1.60511 65.7929 1.79265C65.6054 1.98019 65.5 2.23454 65.5 2.49976C65.5 2.76497 65.6054 3.01933 65.7929 3.20686C65.9804 3.3944 66.2348 3.49976 66.5 3.49976C66.7652 3.49976 67.0196 3.3944 67.2071 3.20686C67.3946 3.01933 67.5 2.76497 67.5 2.49976C67.5 2.23454 67.3946 1.98019 67.2071 1.79265C67.0196 1.60511 66.7652 1.49976 66.5 1.49976Z"
        fill="#3F42FF"
      />
      <path
        d="M39.5 2.49976C29.687 2.49976 20.2759 6.39796 13.337 13.3368C6.3982 20.2757 2.5 29.6867 2.5 39.4998C2.5 49.3128 6.3982 58.7239 13.337 65.6627C20.2759 72.6015 29.687 76.4998 39.5 76.4998C49.313 76.4998 58.7241 72.6015 65.6629 65.6627C72.6018 58.7239 76.5 49.3128 76.5 39.4998C76.5 29.6867 72.6018 20.2757 65.6629 13.3368C58.7241 6.39796 49.313 2.49976 39.5 2.49976Z"
        fill="#E4E4F9"
      />
      <path
        d="M72.5 0.499756C71.4391 0.499756 70.4217 0.921183 69.6716 1.67133C68.9214 2.42147 68.5 3.43889 68.5 4.49976C68.5 5.56062 68.9214 6.57804 69.6716 7.32818C70.4217 8.07833 71.4391 8.49976 72.5 8.49976C73.5609 8.49976 74.5783 8.07833 75.3284 7.32818C76.0786 6.57804 76.5 5.56062 76.5 4.49976C76.5 3.43889 76.0786 2.42147 75.3284 1.67133C74.5783 0.921183 73.5609 0.499756 72.5 0.499756Z"
        fill="#3F42FF"
      />
      <path
        d="M76.5 11.4998C75.9696 11.4998 75.4609 11.7105 75.0858 12.0855C74.7107 12.4606 74.5 12.9693 74.5 13.4998C74.5 14.0302 74.7107 14.5389 75.0858 14.914C75.4609 15.289 75.9696 15.4998 76.5 15.4998C77.0304 15.4998 77.5391 15.289 77.9142 14.914C78.2893 14.5389 78.5 14.0302 78.5 13.4998C78.5 12.9693 78.2893 12.4606 77.9142 12.0855C77.5391 11.7105 77.0304 11.4998 76.5 11.4998Z"
        fill="#8889B9"
      />
      <path
        d="M70.5 63.4998C69.9696 63.4998 69.4609 63.7105 69.0858 64.0855C68.7107 64.4606 68.5 64.9693 68.5 65.4998C68.5 66.0302 68.7107 66.5389 69.0858 66.914C69.4609 67.289 69.9696 67.4998 70.5 67.4998C71.0304 67.4998 71.5391 67.289 71.9142 66.914C72.2893 66.5389 72.5 66.0302 72.5 65.4998C72.5 64.9693 72.2893 64.4606 71.9142 64.0855C71.5391 63.7105 71.0304 63.4998 70.5 63.4998ZM4.5 48.4998C3.43913 48.4998 2.42172 48.9212 1.67157 49.6713C0.921427 50.4215 0.5 51.4389 0.5 52.4998C0.5 53.5606 0.921427 54.578 1.67157 55.3282C2.42172 56.0783 3.43913 56.4998 4.5 56.4998C5.56087 56.4998 6.57828 56.0783 7.32843 55.3282C8.07857 54.578 8.5 53.5606 8.5 52.4998C8.5 51.4389 8.07857 50.4215 7.32843 49.6713C6.57828 48.9212 5.56087 48.4998 4.5 48.4998Z"
        fill="#3F42FF"
      />
      <path
        d="M14.5 74.4998C13.9696 74.4998 13.4609 74.7105 13.0858 75.0855C12.7107 75.4606 12.5 75.9693 12.5 76.4998C12.5 77.0302 12.7107 77.5389 13.0858 77.914C13.4609 78.289 13.9696 78.4998 14.5 78.4998C15.0304 78.4998 15.5391 78.289 15.9142 77.914C16.2893 77.5389 16.5 77.0302 16.5 76.4998C16.5 75.9693 16.2893 75.4606 15.9142 75.0855C15.5391 74.7105 15.0304 74.4998 14.5 74.4998Z"
        fill="#8889B9"
      />
      <path
        d="M8 38.4998C7.33696 38.4998 6.70107 38.7631 6.23223 39.232C5.76339 39.7008 5.5 40.3367 5.5 40.9998C5.5 41.6628 5.76339 42.2987 6.23223 42.7675C6.70107 43.2364 7.33696 43.4998 8 43.4998C8.66304 43.4998 9.29893 43.2364 9.76777 42.7675C10.2366 42.2987 10.5 41.6628 10.5 40.9998C10.5 40.3367 10.2366 39.7008 9.76777 39.232C9.29893 38.7631 8.66304 38.4998 8 38.4998ZM69 21.4998C68.6022 21.4998 68.2206 21.6578 67.9393 21.9391C67.658 22.2204 67.5 22.6019 67.5 22.9998C67.5 23.3976 67.658 23.7791 67.9393 24.0604C68.2206 24.3417 68.6022 24.4998 69 24.4998C69.3978 24.4998 69.7794 24.3417 70.0607 24.0604C70.342 23.7791 70.5 23.3976 70.5 22.9998C70.5 22.6019 70.342 22.2204 70.0607 21.9391C69.7794 21.6578 69.3978 21.4998 69 21.4998Z"
        fill="white"
      />
      <path
        d="M36.2239 55.7998C34.0049 55.7998 32.2009 53.9328 32.2009 51.6368V17.3628C32.2009 15.0668 34.0059 13.1998 36.2239 13.1998H53.2509L63.8009 24.0608L63.7999 51.6368C63.7999 53.9328 61.9949 55.7998 59.7769 55.7998H36.2239Z"
        fill="#FFFEF4"
      />
      <path
        d="M52.955 13.8998L57.911 19.0028L63.099 24.3448V35.7588V42.2778V49.4628V51.6368C63.099 53.5468 61.608 55.0998 59.775 55.0998H57.33H50.525H45.33H36.224C34.391 55.0998 32.9 53.5458 32.9 51.6368V17.3628C32.9 15.4528 34.391 13.8998 36.224 13.8998H36.91H38.443H43.369H52.955ZM53.547 12.4998H43.368H38.442H36.91H36.224C33.626 12.4998 31.5 14.6888 31.5 17.3628V51.6358C31.5 54.3118 33.626 56.4998 36.224 56.4998H45.33H50.525H57.33H59.776C62.374 56.4998 64.5 54.3108 64.5 51.6368V49.4628V42.2778V35.7588V23.7768C60.24 19.3918 57.807 16.8858 53.547 12.4998Z"
        fill="#472B29"
      />
      <path
        d="M53.042 13.8328V19.9828C53.042 22.7418 55.056 24.9998 57.517 24.9998H63.5"
        fill="#FEF6AA"
      />
      <path
        d="M63.5 25.4998H57.517C54.774 25.4998 52.542 23.0248 52.542 19.9828V13.8328H53.542V19.9828C53.542 22.4728 55.325 24.4998 57.517 24.4998H63.5V25.4998Z"
        fill="#472B29"
      />
      <path
        d="M53 29.2498H37C36.862 29.2498 36.75 29.1378 36.75 28.9998C36.75 28.8618 36.862 28.7498 37 28.7498H53C53.138 28.7498 53.25 28.8618 53.25 28.9998C53.25 29.1378 53.138 29.2498 53 29.2498ZM59 33.2498H37C36.862 33.2498 36.75 33.1378 36.75 32.9998C36.75 32.8618 36.862 32.7498 37 32.7498H59C59.138 32.7498 59.25 32.8618 59.25 32.9998C59.25 33.1378 59.138 33.2498 59 33.2498ZM59 37.2498H37C36.862 37.2498 36.75 37.1378 36.75 36.9998C36.75 36.8618 36.862 36.7498 37 36.7498H59C59.138 36.7498 59.25 36.8618 59.25 36.9998C59.25 37.1378 59.138 37.2498 59 37.2498ZM59 41.2498H37C36.862 41.2498 36.75 41.1378 36.75 40.9998C36.75 40.8618 36.862 40.7498 37 40.7498H59C59.138 40.7498 59.25 40.8618 59.25 40.9998C59.25 41.1378 59.138 41.2498 59 41.2498ZM59 45.2498H37C36.862 45.2498 36.75 45.1378 36.75 44.9998C36.75 44.8618 36.862 44.7498 37 44.7498H59C59.138 44.7498 59.25 44.8618 59.25 44.9998C59.25 45.1378 59.138 45.2498 59 45.2498ZM59 29.2498H55C54.862 29.2498 54.75 29.1378 54.75 28.9998C54.75 28.8618 54.862 28.7498 55 28.7498H59C59.138 28.7498 59.25 28.8618 59.25 28.9998C59.25 29.1378 59.138 29.2498 59 29.2498Z"
        fill="#472B29"
      />
      <path
        d="M21.2239 64.7998C19.0049 64.7998 17.2009 62.9328 17.2009 60.6368V26.3628C17.2009 24.0668 19.0059 22.1998 21.2239 22.1998H38.2509L48.8009 33.0608L48.7999 60.6368C48.7999 62.9328 46.9949 64.7998 44.7769 64.7998H21.2239Z"
        fill="#FFFEF4"
      />
      <path
        d="M37.955 22.8998L42.911 28.0028L48.099 33.3448V44.7588V51.2778V58.4628V60.6368C48.099 62.5468 46.608 64.0998 44.775 64.0998H42.33H35.525H30.33H21.224C19.391 64.0998 17.9 62.5458 17.9 60.6368V26.3628C17.9 24.4528 19.391 22.8998 21.224 22.8998H21.91H23.443H28.369H37.955ZM38.547 21.4998H28.368H23.442H21.91H21.224C18.626 21.4998 16.5 23.6888 16.5 26.3628V60.6358C16.5 63.3118 18.626 65.4998 21.224 65.4998H30.33H35.525H42.33H44.776C47.374 65.4998 49.5 63.3108 49.5 60.6368V58.4628V51.2778V44.7588V32.7768C45.24 28.3918 42.807 25.8858 38.547 21.4998Z"
        fill="#472B29"
      />
      <path
        d="M38.042 22.8328V28.9828C38.042 31.7418 40.056 33.9998 42.517 33.9998H48.5"
        fill="#FEF6AA"
      />
      <path
        d="M48.5 34.4998H42.517C39.774 34.4998 37.542 32.0248 37.542 28.9828V22.8328H38.542V28.9828C38.542 31.4728 40.325 33.4998 42.517 33.4998H48.5V34.4998ZM38 38.2498H22C21.862 38.2498 21.75 38.1378 21.75 37.9998C21.75 37.8618 21.862 37.7498 22 37.7498H38C38.138 37.7498 38.25 37.8618 38.25 37.9998C38.25 38.1378 38.138 38.2498 38 38.2498ZM44 42.2498H22C21.862 42.2498 21.75 42.1378 21.75 41.9998C21.75 41.8618 21.862 41.7498 22 41.7498H44C44.138 41.7498 44.25 41.8618 44.25 41.9998C44.25 42.1378 44.138 42.2498 44 42.2498ZM44 46.2498H22C21.862 46.2498 21.75 46.1378 21.75 45.9998C21.75 45.8618 21.862 45.7498 22 45.7498H44C44.138 45.7498 44.25 45.8618 44.25 45.9998C44.25 46.1378 44.138 46.2498 44 46.2498ZM26 50.2498H22C21.862 50.2498 21.75 50.1378 21.75 49.9998C21.75 49.8618 21.862 49.7498 22 49.7498H26C26.138 49.7498 26.25 49.8618 26.25 49.9998C26.25 50.1378 26.138 50.2498 26 50.2498ZM44 50.2498H32C31.862 50.2498 31.75 50.1378 31.75 49.9998C31.75 49.8618 31.862 49.7498 32 49.7498H44C44.138 49.7498 44.25 49.8618 44.25 49.9998C44.25 50.1378 44.138 50.2498 44 50.2498ZM30 50.2498H28C27.862 50.2498 27.75 50.1378 27.75 49.9998C27.75 49.8618 27.862 49.7498 28 49.7498H30C30.138 49.7498 30.25 49.8618 30.25 49.9998C30.25 50.1378 30.138 50.2498 30 50.2498Z"
        fill="#472B29"
      />
      <path
        d="M44 54.2498H22C21.862 54.2498 21.75 54.1378 21.75 53.9998C21.75 53.8618 21.862 53.7498 22 53.7498H44C44.138 53.7498 44.25 53.8618 44.25 53.9998C44.25 54.1378 44.138 54.2498 44 54.2498Z"
        fill="#472B29"
      />
      <path
        d="M44 38.2498H40C39.862 38.2498 39.75 38.1378 39.75 37.9998C39.75 37.8618 39.862 37.7498 40 37.7498H44C44.138 37.7498 44.25 37.8618 44.25 37.9998C44.25 38.1378 44.138 38.2498 44 38.2498Z"
        fill="#472B29"
      />
    </svg>
  );
}

export default DocumentIcon;
