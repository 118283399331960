import { useState, useEffect } from "react";
import moment from "moment";

const useStaticFormattedDate = (dateInput, format = "MMM DD, YYYY") => {
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (dateInput) {
      const formattedDate = moment.utc(dateInput).local().format(format);
      setDate(formattedDate);
    }
  }, [dateInput, format]);

  return { date };
};

export default useStaticFormattedDate;
