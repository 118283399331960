import {
  Divider,
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
  Select,
  MenuItem,
  Box,
  FormControl,
  InputLabel,
  FormHelperText,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import axios from "axios";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade";
import { theme } from "../../../constant/Theme";
import { FilterCancelBtn, FilterSubmitBtn } from "../../Button";
const DocumentPleadingModel = ({ closeModal, filter, setFilter }) => {
  const user = useSelector((state) => state?.reducer?.user);
  // persons api
  const [allPerson, setAllPerson] = useState([]);
  const [isCleardisable, setisCleardisbale] = useState(
    filter && typeof filter == "object" && Object.keys(filter).length > 0
  );
  const [allCase, setAllCase] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [allTitle, setAllTitle] = useState([]);
  // for hidden the filter
  const { matter } = useMultiFileUpload();

  const getAllCase = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "pleadingGenerateFilter",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status == 200) {
        setAllCase(response?.data?.cases);
        setAllTitle(response?.data?.titles);
        setAllStatus(response?.data?.statuses);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllCase();
  }, []);
  const formik = useFormik({
    initialValues: {
      caseTitle: filter?.caseTitle || "",
      title: filter?.title || "",
      status: filter?.status || "",
    },
    onSubmit: async (values, { resetForm }) => {
      setFilter(values);
      closeModal();
    },
  });

  const handleClearFilter = () => {
    setFilter({});
    closeModal();
  };

  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
        maxHeight: 48 * 4.5 + 8,
      },
    },
  };
  return (
    <>
      <Grid container spacing={{ xs: 0, sm: 2 }}>
        <Grid
          item
          xs={11}
          sm={11}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            variant="h5"
            sx={{
              color: theme?.palette?.primary?.main,
              fontFamily: theme?.typography?.fontFamily,
            }}
          >
            Filters
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          sx={{ display: "flex", justifyContent: "flex-end" }}
          onClick={closeModal}
        >
          <CloseIcon
            sx={{ color: theme?.palette?.primary?.main, cursor: "pointer" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider
            sx={{ backgroundColor: theme?.palette?.divider, marginY: "10px" }}
          />
        </Grid>

        {/* filter input */}
      </Grid>
      <Box
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
        sx={{ mt: 3, position: "relative" }}
      >
        <Grid
          container
          spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}
        >
          {!matter && (
            <Grid item xs={12} sm={12}>
              <Typography
                variant="body1"
                sx={{
                  color: theme?.palette?.primary?.main,
                  marginBottom: "4px", // Spacing between label and input
                  fontFamily: theme?.typography?.fontFamily,
                }}
              >
                Matter
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Autocomplete
                  id="caseTitle"
                  options={allCase}
                  fullWidth
                  size="small"
                  disableClearable
                  getOptionLabel={(option) => option || ""}
                  isOptionEqualToValue={(option, value) => option === value}
                  value={
                    allCase?.find(
                      (option) => option === formik.values.caseTitle
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue("caseTitle", newValue || "");
                    formik.setFieldValue("caseId", newValue?._id || ""); // Update formik field for caseId
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Matter"
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        sx: {
                          color: theme?.palette?.primary?.main,
                          "&.Mui-focused": {
                            color: theme?.palette?.primary?.main,
                          },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          color: theme?.palette?.primary?.placeholderColor, // Set text color
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme?.palette?.primary?.inputColor, // Normal border color
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor:
                              theme?.palette?.primary?.placeholderColor, // Yellow border color on focus
                          },
                        },
                        "& .MuiSvgIcon-root": {
                          color: theme?.palette?.primary?.main,
                        },
                      }}
                    />
                  )}
                  PaperComponent={(props) => (
                    <Paper
                      {...props}
                      sx={{
                        backgroundColor: "#FFFFFF",
                        color: theme?.palette?.primary?.placeholderColor,
                      }}
                    />
                  )}
                  ListboxProps={{
                    sx: {
                      maxHeight: 300,
                      overflowY: "auto",
                      "& .MuiAutocomplete-option": {
                        "&:hover": {
                          backgroundColor: "#686DFC26",
                          color: theme?.palette?.primary?.heading,
                        },
                      },
                    },
                  }}
                  noOptionsText="No matters found"
                />
              </Box>
            </Grid>
          )}

          {/* witness */}
          <Grid item xs={12} sm={12} style={{ paddingTop: "10px" }}>
            <Typography
              variant="body1"
              sx={{
                color: theme?.palette?.primary?.main,
                marginBottom: "4px", // Spacing between label and input
                fontFamily: theme?.typography?.fontFamily,
                ...theme?.typography?.inputHeading,
              }}
            >
              Title
            </Typography>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: theme?.palette?.primary?.placeholderColor, // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.primary?.inputColor,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.primary?.placeholderColor, // Yellow border color on focus
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: theme?.palette?.primary?.main,
                },
              }}
            >
              <Select
                labelId="title-label"
                id="witness"
                autoComplete="off"
                name="title"
                placeholder="Title"
                value={formik.values.title}
                onBlur={formik.handleBlur}
                displayEmpty
                onChange={(e) => {
                  formik.setFieldValue("title", e.target.value);
                  formik.setFieldValue("title", e.target.value);
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#FFFFFF",
                      color: theme?.palette?.primary?.placeholderColor,
                      maxHeight: 300, // Adjust as needed
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor: "#686DFC26", // Hover background color
                          color: "#686DFC", // Hover text color
                        },
                      },
                    },
                  },
                }}
                sx={{
                  width: "100%",
                  "& .MuiSelect-select": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography
                    sx={{
                      fontStyle: "normal",
                      color: theme?.palette?.primary?.dropDownColor,
                    }}
                  >
                    Select Title
                  </Typography>
                </MenuItem>
                {allTitle?.map((row) => (
                  <MenuItem key={row?._id} value={row}>
                    {row}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography
              variant="body1"
              sx={{
                color: theme?.palette?.primary?.main,
                marginBottom: "4px", // Spacing between label and input
                fontFamily: theme?.typography?.fontFamily,
                ...theme?.typography?.inputHeading,
              }}
            >
              Status
            </Typography>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: theme?.palette?.primary?.placeholderColor, // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.primary?.inputColor,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.primary?.placeholderColor, // Yellow border color on focus
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: theme?.palette?.primary?.main,
                },
              }}
            >
              <Select
                labelId="status-label"
                id="status"
                autoComplete="off"
                name="status"
                displayEmpty
                placeholder="Status"
                value={formik.values?.status}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#FFFFFF",
                      color: theme?.palette?.primary?.placeholderColor,
                      maxHeight: 300, // Adjust as needed
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor: "#686DFC26", // Hover background color
                          color: "#686DFC", // Hover text color
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography
                    sx={{
                      fontStyle: "normal",
                      color: theme?.palette?.primary?.dropDownColor,
                    }}
                  >
                    Select Status{" "}
                  </Typography>
                </MenuItem>
                {allStatus?.map((row, index) => (
                  <MenuItem
                    key={index}
                    value={row}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {row}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* button */}
        <Grid
          container
          spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}
        >
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",

              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "flex-end",
              gap: 1,
            }}
          >
            <FilterCancelBtn
              text={"clear filter"}
              onClick={handleClearFilter}
              disabled={!isCleardisable}
            />
            <FilterSubmitBtn title={"Apply Filter"} type={"submit"} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DocumentPleadingModel;
