// PleadingsFormStyles.js

import { theme } from "../../../constant/Theme";

const styles = {
  paper: {
    width: "100%",
    backgroundColor: theme?.palette?.background?.paper,
    color: "lightgray",
    // margin: "25px 0 ",
    boxShadow: "none",
    padding: { xs: 0, sm: 2 },
    borderRadius: "8px",
  },
  buttonCommon: {
    width: { xs: "100%", sm: "120px" },
    bottom: "0px",
    mt: { xs: 1, sm: 1.5 },
    mb: { xs: 0, sm: 2 },
    color: theme?.palette?.secondary?.backgroundColor,
    fontSize: "12px",
    borderColor: "white",
    border: "2px solid",
    backgroundColor: "transparent",
    "&:hover": {
      border: "2px solid",
      borderColor: "rgba(104, 109, 252, 0.7)",
      color: "rgba(104, 109, 252, 0.7)",
      backgroundColor: theme?.palette?.secondary?.backgroundColor,
      color: "white",
    },
    "&.Mui-disabled": {
      border: "2px solid",
      borderColor: "rgba(104, 109, 252, 0.7)",
      color: "rgba(104, 109, 252, 0.7)",
    },
    fontFamily: "sans-serif",
  },
  submitButton: {
    width: { xs: "100%", sm: "150px" },
    mt: { xs: 1, sm: 1.5 },
    mb: { xs: 0, sm: 2 },
    height: "45px",
    "&:hover, &:focus": {
      backgroundColor: "white",
      color: "#FFFFFF",
      backgroundColor: theme?.palette?.secondary?.btnPrimary,
      boxShadow: 0,
    },
    "&:disabled": {
      backgroundColor: theme?.palette?.secondary?.btnPrimary,
      color: "white",
      boxShadow: 0,
    },
    boxShadow: 0,
    fontSize: "12px",
    backgroundColor: theme?.palette?.secondary?.btnPrimary,
    fontWeight: "600",
    fontFamily: theme?.typography?.fontFamily,
  },
  box: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    pt: 2,
  },
  buttonSkip: {
    mr: 1,
  },
};

export default styles;
