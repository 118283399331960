import { Divider, Grid, Typography, TextField, Button, Autocomplete, Select, MenuItem, Box, FormControl, InputLabel, FormHelperText, Paper } from "@mui/material"; // import PopupModel from "../Modal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import SubscriptionCards from "../SubscriptionCard";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

// import { useState } from "react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { state } from "../../../constant/ConstantStateData";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade";
import { theme } from "../../../constant/Theme";
import { FilterCancelBtn, FilterSubmitBtn } from "../../Button";

const PersonsMatterModel = ({ closeModal, filter, setFilter }) => {
  let stateNames = state;
  const { user } = useSelector((state) => state?.reducer);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allRoll, setAllRoll] = useState([]);
  const [isCleardisable, setisCleardisbale] = useState(filter && typeof filter == "object" && Object.keys(filter).length > 0);
  const [allCase, setAllCase] = useState([]);
  // for filter hidden
  const { matter } = useMultiFileUpload();

  const formik = useFormik({
    initialValues: {
      caseId: filter?.caseId || "",
      caseTitle: filter?.caseTitle || "",
      companyName: filter?.companyName || "",
      role: filter?.role || "",
    },
    onSubmit: async (values, { resetForm }) => {
      setFilter(values);
      closeModal();
    },
  });

  const getAllFilter = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "personFilter", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const companiesData = response?.data?.companies;

        // Check if companies data is empty
        if (!companiesData || companiesData.length === 0) {
          setAllCompanies(["N/A"]); // Set "N/A" if the companies array is empty or undefined
        } else {
          setAllCompanies(companiesData);
        }

        // Set the other data
        setAllCase(response?.data?.cases);
        setAllRoll(response?.data?.roles);
      }
    } catch (error) {
      console.error("Error fetching filters:", error);
    }
  };
  useEffect(() => {
    getAllFilter();
  }, []);

  const handleClearFilter = () => {
    setFilter({});
    closeModal();
  };
  return (
    <>
      <Grid container spacing={{ xs: 0, sm: 2 }}>
        <Grid item xs={11} sm={11} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h5" sx={{ color: theme?.palette?.primary?.main, fontFamily: theme?.typography?.fontFamily }}>
            Filters
          </Typography>
        </Grid>
        <Grid item xs={1} sm={1} sx={{ display: "flex", justifyContent: "flex-end" }} onClick={closeModal}>
          <CloseIcon sx={{ color: theme?.palette?.primary?.main, cursor: "pointer" }} />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ backgroundColor: theme?.palette?.divider, marginY: "10px" }} />
        </Grid>

        {/* filter input */}
      </Grid>
      <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3, position: "relative" }}>
        <Grid container spacing={3}>
          {!matter && (
            <Grid item xs={12} sm={12} style={{ paddingTop: "10px" }}>
              <Typography
                variant="body1"
                sx={{
                  color: theme?.palette?.primary?.main,
                  marginBottom: "4px", // Spacing between label and input
                  fontFamily: theme?.typography?.fontFamily,
                  ...theme?.typography?.inputHeading,
                }}
              >
                Matter
              </Typography>
              <Autocomplete
                id="caseTitle"
                options={allCase || []}
                value={formik.values.caseTitle}
                onChange={(event, newValue) => formik.setFieldValue("caseTitle", newValue)}
                onBlur={formik.handleBlur}
                size="small"
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Matter"
                    variant="outlined"
                    error={formik.touched.caseId && Boolean(formik.errors.caseId)}
                    helperText={formik.touched.caseId && formik.errors.caseId}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        color: "#A6A7C1", // Set text color
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: formik.touched.caseId && Boolean(formik.errors.caseId) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor,
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: formik.touched.caseId && Boolean(formik.errors.caseId) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: theme?.palette?.primary?.placeholderColor,
                      },
                      "& label": {
                        color: "#C8C9DB", // Change label color
                        "&.Mui-focused": {
                          color: "#C8C9DB", // Change label color when focused
                        },
                      },
                    }}
                  />
                )}
                disableClearable
                PaperComponent={(props) => <Paper {...props} sx={{ backgroundColor: "#FFFFFF", color: theme?.palette?.primary?.placeholderColor }} />}
                ListboxProps={{
                  sx: {
                    maxHeight: 200,
                    overflowY: "auto",
                    "& .MuiAutocomplete-option": {
                      "&:hover": {
                        backgroundColor: "#686DFC26",
                        color: "#686DFC",
                      },
                    },
                    "& .MuiAutocomplete-noOptions": {
                      color: "#FF7799", // Customize the color of the noOptionsText
                    },
                  },
                }}
                sx={{
                  "& .MuiAutocomplete-paper": {
                    backgroundColor: "#FFFFFF",
                    color: "#C8C9DB",
                    maxHeight: 300, // Adjust as needed
                    overflowY: "auto",
                  },
                  "& .MuiAutocomplete-option": {
                    "&:hover": {
                      backgroundColor: "#686DFC26", // Hover background color
                      color: "#686DFC", // Hover text color
                    },
                  },
                }}
              />
            </Grid>
          )}
          {/* role */}
          <Grid item xs={12} sm={12} style={{ paddingTop: "10px" }}>
            <Typography
              variant="body1"
              sx={{
                color: theme?.palette?.primary?.main,
                marginBottom: "4px", // Spacing between label and input
                fontFamily: theme?.typography?.fontFamily,
                ...theme?.typography?.inputHeading,
              }}
            >
              Role
            </Typography>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              helperText={formik.touched?.role && formik.errors?.role}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: theme?.palette?.primary?.placeholderColor, // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.secondary?.inputColor, // Yellow border color on focus
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: theme?.palette?.primary?.placeholderColor,
                },
                "& label": {
                  color: theme?.palette?.primary?.placeholderColor, // Change label color
                },
              }}
            >
              <Select
                labelId="role-label"
                id="role"
                autoComplete="off"
                // IconComponent={KeyboardArrowDownIcon}
                name="role"
                placeholder="Role"
                value={formik.values.role}
                displayEmpty
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#FFFFFF",
                      color: theme?.palette?.primary?.placeholderColor,
                      maxHeight: 300, // Adjust as needed
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor: "#686DFC26", // Hover background color
                          color: "#686DFC", // Hover text color
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography sx={{ fontStyle: "normal", color: theme?.palette?.primary?.dropDownColor }}>Select Role</Typography>
                </MenuItem>
                {allRoll?.map((value, index) => {
                  return (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {/* role */}
        </Grid>
        <Grid container spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "flex-end",
              gap: 1,
            }}
          >
            <FilterCancelBtn text={"clear filter"} onClick={handleClearFilter} disabled={!isCleardisable} />
            <FilterSubmitBtn title={"Apply Filter"} type={"submit"} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PersonsMatterModel;
