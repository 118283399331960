import React from "react";
import { Formik, Form } from "formik";
import { Box, Paper, Button } from "@mui/material";
import { SettlementsRenderStep } from "../../Validation/SettlementValidation";
import LoaderIcon from "../../Icons/LoaderIcon";
import styles from "./styles";
import useSettlementForm from "./useSettlementsForm";
import { ButtonUICancel, ButtonUIPrevious } from "../../Button";

const UpdateSettlementsForm = ({ activeStep, steps, handleBack, handleNext }) => {
  const {
    initialUpdateValues,
    isLoading,
    currentValidationSchema,
    navigate,
    handleUpdateSubmit
  } = useSettlementForm(activeStep, handleNext);

  return (
    <Formik
      initialValues={initialUpdateValues}
      validationSchema={currentValidationSchema}
      onSubmit={handleUpdateSubmit}
      enableReinitialize
    >
      {(formik) => (
        <Form>
          <Paper sx={styles.paper} elevation={0}>
            {/* Render the current step based on activeStep */}
            {SettlementsRenderStep(activeStep, formik)}

            {/* Button Controls */}
            <Box sx={styles.box}>
              {/* Cancel Button on the first step */}
              {activeStep === 0 && (
                <ButtonUICancel
                  text="Cancel"
                  onClick={() => navigate("/settlements")}
                  variant="contained"
                />
              )}

              {/* Previous Button for Steps > 0 */}
              {activeStep > 0 && activeStep < steps.length - 1 && (
                <ButtonUIPrevious
                  variant="outlined"
                  text="Previous"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                />
              )}

              <Box sx={{ flex: "1 1 auto" }} />

              {/* Submit Button with Loader Logic */}
              <Button
                type="submit"
                disabled={isLoading}
                variant="contained"
                sx={styles.submitButton}
              >
                {activeStep === steps.length - 1 && isLoading ? (
                  <>
                    <LoaderIcon color="#f7f7f7" /> Processing...
                  </>
                ) : activeStep === steps.length - 1 ? (
                  "Finish"
                ) : (
                  "Next"
                )}
              </Button>
            </Box>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateSettlementsForm;
