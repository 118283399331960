import React from "react";

const StudentIcon = ({ colors }) => {
  return (
    <div>
      <svg width="24" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.3649 16.1606C18.9947 16.8237 20.1965 19.5441 20.1965 22.7998H3.80469C3.80469 19.5441 5.00646 16.8237 9.63623 16.1606C10.1524 16.9944 10.9099 17.4877 12.0006 17.4877C13.0913 17.4877 13.8488 16.9944 14.3649 16.1606Z"
          fill={colors}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.708 6.96704C16.5443 7.86948 17.0558 9.07727 17.0558 10.4047C17.0558 13.1988 14.7907 15.4638 11.9966 15.4638C9.20255 15.4638 6.9375 13.1988 6.9375 10.4047C6.9375 9.07727 7.44893 7.86948 8.28526 6.96704C9.36092 7.10568 10.6331 7.18583 11.9966 7.18583C13.3602 7.18583 14.6324 7.10568 15.708 6.96704Z"
          fill={colors}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.7127 5.09717V6.33052C14.637 6.46916 13.3647 6.54937 12.0009 6.54937C10.6371 6.54937 9.36483 6.46916 8.28906 6.33052V5.09717L10.8806 5.69648C11.6526 5.87501 12.3491 5.87501 13.1211 5.69648L15.7127 5.09717Z"
          fill={colors}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.66989 3.00245L10.8829 1.33436C11.6549 1.1558 12.3514 1.1558 13.1234 1.33436L20.3365 3.00245C20.4464 3.02788 20.522 3.12296 20.522 3.23577C20.522 3.34858 20.4464 3.44367 20.3365 3.4691L18.8456 3.8139V6.79166L19.6489 8.18296C19.7606 8.3759 19.6947 8.62299 19.5018 8.7347C19.3089 8.84642 19.0618 8.7806 18.9501 8.58766L18.8456 8.40668V8.61534C18.8456 8.83887 18.6643 9.02009 18.4408 9.02009C18.2173 9.02009 18.0361 8.83887 18.0361 8.61534V8.40658L17.9316 8.58756C17.8199 8.7805 17.5728 8.84632 17.3798 8.73461C17.1869 8.62289 17.1211 8.3758 17.2328 8.18286L18.0361 6.79156V4.00109L13.1234 5.13719C12.3514 5.31573 11.6549 5.31573 10.8829 5.13719L3.66989 3.4691C3.55999 3.44367 3.48438 3.34858 3.48438 3.23577C3.48438 3.12296 3.55994 3.02788 3.66989 3.00245Z"
          fill={colors}
        />
      </svg>
    </div>
  );
};

export default StudentIcon;
