import React, { useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import {
  Box,
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TableHead,
  Table,
  TableContainer,
  Stack,
  FormControlLabel,
  Checkbox,
  Chip,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import useStaticFormattedDate from "../../hooks/useStaticDate";
import { inputSettlementStyles } from "./style";
import { theme } from "../../constant/Theme";
import { useAxios } from "../../services/http.service";
import { useSelector } from "react-redux";
import { PaperComponent } from "../../Components/Card/SettingCard";
import { BackBtn } from "../../Components/Button";
// Function to create row data

const ViewSettlementsDetails = () => {
  const location = useLocation();
  const { viewRecord } = location.state || {};

  console.log("data view record ", viewRecord);
  // State to manage which row is open

  return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PaperComponent>
              <Box sx={inputSettlementStyles?.viewPaper}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color: theme?.palette?.primary?.main,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "600",
                          lineHeight: "33.6px",
                          fontSize: "28px !important",
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                        Settlements Details
                      </Typography>

                      <BackBtn title={"back"} link={"/settlements"} />
                    </Box>
                  </Grid>
                </Grid>
                {/* here is defin it  */}
                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  ></Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputSettlementStyles?.viewHeading}>
                        Matter
                      </Typography>
                      <Typography sx={inputSettlementStyles?.viewSubHeading}>
                        {viewRecord?.case ? viewRecord?.case : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputSettlementStyles?.viewHeading}>
                        Matter Type
                      </Typography>
                      <Typography sx={inputSettlementStyles?.viewSubHeading}>
                        {viewRecord?.type ? viewRecord.type : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputSettlementStyles?.viewHeading}>
                        Settling Party
                      </Typography>
                      <Typography sx={inputSettlementStyles?.viewSubHeading}>
                        {viewRecord?.settlingPartyName || "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputSettlementStyles?.viewHeading}>
                        Amount
                      </Typography>
                      <Typography sx={inputSettlementStyles?.viewSubHeading}>
                        ${viewRecord?.amount ? viewRecord?.amount : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputSettlementStyles?.viewHeading}>
                      Total Medical Bills
                      </Typography>
                      <Typography sx={inputSettlementStyles?.viewSubHeading}>
                        ${viewRecord?.totalMedicalBills ? viewRecord?.totalMedicalBills : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputSettlementStyles?.viewHeading}>
                        Months Treated
                      </Typography>
                      <Typography sx={inputSettlementStyles?.viewSubHeading}>
                        {viewRecord?.monthTreated
                          ? viewRecord?.monthTreated
                          : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputSettlementStyles?.viewHeading}>
                        Disputed Liability
                      </Typography>
                      <Typography sx={inputSettlementStyles?.viewSubHeading}>
                        {viewRecord?.disputedLiabilityName === "Yes"
                          ? "Yes"
                          : viewRecord?.disputedLiabilityName === "No"
                          ? "No"
                          : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid container spacing={2} sx={{ margin: "2px 0 20px 0" }}>
   {/* Diagnoses Tags Section */}
<Grid item xs={12} sx={{ margin: "2px 0 20px 0" }}>
    <Typography sx={inputSettlementStyles?.viewHeading}>
        Diagnoses Tags
    </Typography>
    <Box
        sx={{
            marginTop: "5px",
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
            border: "1px solid #E0E0E0",
            borderRadius: "5px",
            padding: "15px",
        }}
    >
        {viewRecord?.diagnoseTag ? (
            viewRecord.diagnoseTag
                .split(',')
                .map((tag, index) => (
                    <Chip
                        key={index}
                        label={tag.trim()}
                        sx={{
                            backgroundColor: "#F9FAFB",
                            outline: "1px solid #E6E7F2",
                            color: theme?.palette?.primary?.main,
                            fontSize: "14px",
                        }}
                    />
                ))
        ) : (
            <Typography sx={inputSettlementStyles?.viewSubHeading}>N/A</Typography>
        )}
    </Box>
</Grid>

{/* Treatment Tags Section */}
<Grid item xs={12} sx={{ margin: "2px 0 20px 0" }}>
    <Typography sx={inputSettlementStyles?.viewHeading}>
        Treatment Tags
    </Typography>
    <Box
        sx={{
            marginTop: "5px",
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
            border: "1px solid #E0E0E0",
            borderRadius: "5px",
            padding: "15px",
        }}
    >
        {viewRecord?.treatmentsTag ? (
            viewRecord.treatmentsTag
                .split(',')
                .map((tag, index) => (
                    <Chip
                        key={index}
                        label={tag.trim()}
                        sx={{
                          backgroundColor: "#F9FAFB",
                          outline: "1px solid #E6E7F2",
                          color: theme?.palette?.primary?.main,
                          fontSize: "14px",
                      }}
                    />
                ))
        ) : (
            <Typography sx={inputSettlementStyles?.viewSubHeading}>N/A</Typography>
        )}
    </Box>
</Grid>

</Grid>

                </Grid>
              </Box>
            </PaperComponent>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default ViewSettlementsDetails;
