import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import FeedIcons from "../../Components/Icons/FeedIcon";
import { theme } from "../../constant/Theme";
import {
  CustomStepper,
  PaperComponent,
} from "../../Components/Card/SettingCard";
import SettlementsForm from "../../Components/Forms/SettlementsForm";
import FinancialIcon from "../../Components/Icons/Financial";
import SettlementMedicalIcon from "../../Components/Icons/SettlementMedicalIcon";
const steps = [
  {
    text: "Basic Details",
    label: "Settlement Information",
    icon: <FeedIcons />,
  },
  {
    text: "Financial Details",
    label: "Amount Information",
    icon: <FinancialIcon />,
  },
  {
    text: "Medical Details",
    label: "Tags Information",
    icon: <SettlementMedicalIcon />,
  },
];

const AddNewSettlementPage = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const isStepOptional = (step) => {
    return step === 1;
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              color={theme?.palette?.primary?.main}
              sx={{
                color: theme?.palette?.primary?.main,
                ...theme?.typography?.title,
              }}
            >
              Settlements /{" "}
              <Box
                component="span"
                color={theme?.palette?.primary?.secondaryheading}
                sx={{
                  color: theme?.palette?.primary?.subPrimary,
                  ...theme?.typography?.title,
                }}
              >
                {" "}
                Add New Settlements{" "}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PaperComponent>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: theme?.palette?.background?.paper,
                  color: theme?.palette?.secondary?.main,
                  borderRadius: "8px",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      background: theme?.palette?.primary?.sidebar,
                      borderRadius: "8px",
                    }}
                  >
                    <CustomStepper
                      steps={steps}
                      activeStep={activeStep}
                      isStepSkipped={isStepSkipped}
                      theme={theme}
                    />
                    {activeStep === steps.length ? (
                      <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          All steps completed - you&apos;re finished
                        </Typography>
                        <Box
                          sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                        >
                          <Box sx={{ flex: "1 1 auto" }} />
                          <Button onClick={handleReset}>Reset</Button>
                        </Box>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <SettlementsForm
                          activeStep={activeStep}
                          handleNext={handleNext}
                          handleSkip={handleSkip}
                          handleBack={handleBack}
                          steps={steps}
                          isStepOptional={isStepOptional}
                        />
                      </React.Fragment>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </PaperComponent>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default AddNewSettlementPage;
