import {
  Divider,
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
  Select,
  MenuItem,
  Box,
  FormControl,
  InputLabel,
  FormHelperText,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { theme } from "../../../constant/Theme";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FilterCancelBtn, FilterSubmitBtn } from "../../Button";
const PleadingModel = ({ closeModal, filter, setFilter }) => {
  const [isCleardisable, setisCleardisbale] = useState(
    filter && typeof filter == "object" && Object.keys(filter).length > 0
  );
  const { user } = useSelector((state) => state?.reducer);
  const [allPersons, setAllPerson] = useState([]);
  const [allType, setAllType] = useState([]);
  // for hidden filter
  const { matter } = useMultiFileUpload();

  const formik = useFormik({
    initialValues: {
      caseTitle: filter?.caseTitle || "",
      date: filter?.date || 0,
      type: filter?.type || "",
      fillingParty: filter?.fillingParty || "",
    },
    onSubmit: async (values, { resetForm }) => {
      setFilter(values);
      closeModal();
    },
  });
  const handleClearFilter = () => {
    setFilter({});
    closeModal();
  };
  const timestampToDateInputString = (timestamp) => {
    if (!timestamp) return "";
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  // Helper function to convert ISO date string (YYYY-MM-DD) to timestamp
  const dateInputStringToTimestamp = (dateString) => {
    return new Date(dateString).getTime();
  };
  const today = dayjs();
  const [allCase, setAllCase] = useState([]);
  const getAllCase = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "pleadingsFilter",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status == 200) {
        setAllCase(response?.data?.data?.cases);
        setAllType(response?.data?.data?.types);
        setAllPerson(response?.data?.data?.fillingParty);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllCase();
  }, []);

  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
        maxHeight: 48 * 4.5 + 8,
      },
    },
  };
  return (
    <>
      <Grid container spacing={{ xs: 0, sm: 2 }}>
        <Grid
          item
          xs={11}
          sm={11}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            variant="h5"
            sx={{
              color: theme?.palette?.primary?.main,
              fontFamily: theme?.typography?.fontFamily,
            }}
          >
            Filters
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          sx={{ display: "flex", justifyContent: "flex-end" }}
          onClick={closeModal}
        >
          <CloseIcon
            sx={{ color: theme?.palette?.primary?.main, cursor: "pointer" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider
            sx={{ backgroundColor: theme?.palette?.divider, marginY: "10px" }}
          />
        </Grid>

        {/* filter input */}
      </Grid>
      <Box
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
        sx={{ mt: 2, position: "relative" }}
      >
        <Grid container spacing={3}>
          {!matter && (
            <Grid item xs={12} sm={12} style={{ paddingTop: "10px" }}>
              <Typography
                variant="body1"
                sx={{
                  color: theme?.palette?.primary?.main,
                  marginBottom: "8px", // Spacing between label and input
                  fontFamily: theme?.typography?.fontFamily,
                  ...theme?.typography?.inputHeading,
                }}
              >
                Matter
              </Typography>
              <Autocomplete
                id="caseTitle"
                disableClearable
                fullWidth
                size="small"
                options={allCase || []}
                getOptionLabel={(option) => option?.title || ""}
                isOptionEqualToValue={(option, value) =>
                  option?.title === value?.title
                }
                value={
                  allCase.find(
                    (option) => option?.title === formik.values.caseTitle
                  ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue("caseTitle", newValue?.title || "");
                  // formik.setFieldValue("caseId", newValue?._id || "");
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Matter"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={
                      formik.touched.caseTitle &&
                      Boolean(formik.errors.caseTitle)
                    }
                    InputLabelProps={{
                      sx: {
                        color: theme?.palette?.primary?.main,
                        "&.Mui-focused": {
                          color: theme?.palette?.primary?.main,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",

                        color: theme?.palette?.primary?.placeholderColor, // Set text color
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme?.palette?.primary?.inputColor, // Normal border color
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor:
                            theme?.palette?.primary?.placeholderColor, // Yellow border color on focus
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: theme?.palette?.primary?.placeholderColor,
                      },
                    }}
                  />
                )}
                PaperComponent={(props) => (
                  <Paper
                    {...props}
                    sx={{
                      backgroundColor: "#FFFFFF",
                      color: theme?.palette?.primary?.placeholderColor,
                    }}
                  />
                )}
                ListboxProps={{
                  sx: {
                    maxHeight: 300,
                    overflowY: "auto",
                    "& .MuiAutocomplete-option": {
                      "&:hover": {
                        backgroundColor: "#686DFC26", // Hover background color
                        color: "#686DFC", // Hover text color
                      },
                    },
                  },
                }}
                noOptionsText="No matters found"
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12} style={{ paddingTop: "10px" }}>
            <FormControl fullWidth>
              <Typography
                variant="body1"
                sx={{
                  color: theme?.palette?.primary?.main,
                  fontFamily: theme?.typography?.fontFamily,
                  ...theme?.typography?.inputHeading,
                }}
              >
                Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]} size="small">
                  <DatePicker
                    value={
                      formik?.values?.date
                        ? dayjs(new Date(formik?.values?.date).toString())
                        : null
                    }
                    autoComplete="given-date"
                    slotProps={{ textField: { size: "small" } }} //for height of date input
                    error={formik.touched.date && formik.errors?.date}
                    // onChange={(date) => formik.setFieldValue("date", new Date(date).getTime())}
                    onChange={(date) => {
                      // If the selected date is in the future, reset it to today's date
                      if (date && dayjs(date).isAfter(today)) {
                        formik.setFieldValue("date", today.valueOf());
                      } else {
                        formik.setFieldValue(
                          "date",
                          date ? new Date(date).getTime() : ""
                        );
                      }
                    }}
                    maxDate={today}
                    helperText={formik.touched?.date && formik.errors?.date}
                    sx={{
                      width: "100%",
                      borderRadius: "5px",

                      "& .MuiOutlinedInput-root": {
                        color: theme?.palette?.secondary?.main,
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme?.palette?.primary?.inputColor, // Normal border color
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme?.palette?.primary?.hoverColor, // Border color on hover
                        },
                        "& .MuiInputBase-input": {
                          color: "#505058 !important",
                        },
                      },
                      "& .MuiInputLabel-root.MuiInputLabel-formControl": {
                        color: theme?.palette?.primary?.placeholderColor, // Error label color
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#A6A7C1", // Yellow border color on focus
                      },
                      "& .MuiSvgIcon-root": {
                        color: theme?.palette?.primary?.placeholderColor,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: theme?.palette?.primary?.main,
                      },
                    }}
                    InputProps={{
                      style: {
                        color: theme?.palette?.primary?.placeholderColor,
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          {/* date  */}
          {/* Type */}
          <Grid item xs={12} sm={12} style={{ paddingTop: "10px" }}>
            <Typography
              variant="body1"
              sx={{
                color: theme?.palette?.primary?.main,
                marginBottom: "8px", // Spacing between label and input
                fontFamily: theme?.typography?.fontFamily,
                ...theme?.typography?.inputHeading,
              }}
            >
              Type
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: theme?.palette?.primary?.placeholderColor, // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.primary?.inputColor,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.primary?.placeholderColor, // Yellow border color on focus
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: theme?.palette?.primary?.main,
                },
              }}
            >
              <Select
                labelId="type-label"
                id="type"
                autoComplete="off"
                name="type"
                displayEmpty
                placeholder="Select type"
                value={formik.values?.type}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#FFFFFF",
                      color: theme?.palette?.primary?.placeholderColor,
                      maxHeight: 300, // Adjust as needed
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor: "#686DFC26", // Hover background color
                          color: "#686DFC", // Hover text color
                        },
                      },
                    },
                  },
                  MenuProps,
                }}
              >
                {" "}
                <MenuItem value="" disabled>
                  <Typography
                    sx={{
                      fontStyle: "normal",
                      color: theme?.palette?.primary?.dropDownColor,
                    }}
                  >
                    Select Type
                  </Typography>
                </MenuItem>
                {allType?.map((row, index) => (
                  <MenuItem key={index} value={row}>
                    {row}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* type */}

          {/* filling party */}
          <Grid item xs={12} sm={12} style={{ paddingTop: "10px" }}>
            <Typography
              variant="body1"
              sx={{
                color: theme?.palette?.primary?.main,
                marginBottom: "8px", // Spacing between label and input
                fontFamily: theme?.typography?.fontFamily,
                ...theme?.typography?.inputHeading,
              }}
            >
              Filing Party
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: theme?.palette?.primary?.placeholderColor, // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.primary?.inputColor,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.primary?.placeholderColor, // Yellow border color on focus
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: theme?.palette?.primary?.main,
                },
              }}
            >
              <Select
                labelId="fillingParty-label"
                id="fillingParty"
                autoComplete="off"
                name="fillingParty"
                placeholder="Select filing Party"
                displayEmpty
                value={formik.values?.fillingParty}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#323249",
                      color: "#C8C9DB",
                      maxHeight: 300, // Adjust as needed
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor: "#686DFC26", // Hover background color
                          color: "#686DFC", // Hover text color
                        },
                      },
                    },
                  },
                  MenuProps,
                }}
              >
                <MenuItem value="" disabled>
                  <Typography
                    sx={{
                      fontStyle: "normal",
                      color: theme?.palette?.primary?.dropDownColor,
                    }}
                  >
                    Select Filing Party
                  </Typography>
                </MenuItem>
                {allPersons?.map((row, index) => {
                  return (
                    <MenuItem key={index} value={row._id}>
                      {`${row?.personName}`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* button */}
        <Grid
          container
          spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}
        >
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",

              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "flex-end",
              gap: 1,
            }}
          >
            <FilterCancelBtn
              text={"clear filter"}
              onClick={handleClearFilter}
              disabled={!isCleardisable}
            />
            <FilterSubmitBtn title={"Apply Filter"} type={"submit"} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PleadingModel;
