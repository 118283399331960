import React, { useCallback, useState } from "react";

import { theme } from "./Theme";
import { FormControl, TextField, Paper, FormHelperText, Typography, Autocomplete, InputLabel, MenuItem, Select } from "@mui/material";
import { useField } from "formik";
import { useTheme } from "@emotion/react";


const LabeledTextField = ({
  formik,
  name,
  label,
  placeholder,
  required = false,
  error,
  helperText,
  inputStyles = {},
  labelStyles = {},
  InputProps = {},
  InputLabelProps = {},
  disable = false,
  ...props
}) => {
  return (
    <FormControl fullWidth variant="outlined" size="small">
      <Typography
        variant="body1"
        disable={disable}
        sx={{
          marginBottom: "4px",
          ...labelStyles, // Custom styles for label
          color:
            formik.touched[name] && Boolean(formik.errors[name])
              ? theme?.palette?.secondary?.dangerMain
              : theme?.palette?.primary?.main,
          fontFamily: theme?.typography?.fontFamily,
          ...theme?.typography?.inputHeading,
        }}
      >
        {label}
        {required && (
          <span
            style={{
              color:
                formik.touched[name] && Boolean(formik.errors[name])
                  ? theme?.palette?.secondary?.dangerMain
                  : theme?.palette?.secondary?.dangerMain,
              marginLeft: "4px",
            }}
          >
            *
          </span>
        )}{" "}
      </Typography>

      <TextField
        name={name}
        required={required}
        variant="outlined"
        size="small"
        placeholder={placeholder}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values[name]}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={helperText || (formik.touched[name] && formik.errors[name])}
        sx={{ ...inputStyles }} // Custom styles for input field
        InputProps={{
          ...InputProps, // Spread InputProps here
          style: {
            color: theme.palette.primary?.placeholderColor,
            ...theme?.typography?.inputPlaceHolder,
            // ...InputProps.style, // Merge with existing styles if needed
          },
        }}
        InputLabelProps={{
          ...InputLabelProps, // Spread InputLabelProps here
          style: {
            color:
              formik.touched[name] && Boolean(formik.errors[name])
                ? theme.palette.secondary.dangerMain
                : theme.palette.primary.placeholderColor,
            ...InputLabelProps.style, // Merge with existing styles if needed
            ...InputProps.style,
            ...theme?.typography?.inputHeading,
          },
        }}
        {...props} // Additional props
      />
    </FormControl>
  );
};

export default LabeledTextField;
 

// const LabeledTextField = ({
//   formik,
//   name,
//   label,
//   placeholder,
//   required = false,
//   error,
//   helperText,
//   inputStyles = {},
//   labelStyles = {},
//   InputProps = {},
//   InputLabelProps = {},
//   disable = false,
//   ...props
// }) => {

//   // Format phone number to (xxx) xxx-xxxx
//   const formatPhoneNumber = (value) => {
//     if (!value) return value;

//     // Remove non-numeric characters
//     const digits = value.replace(/\D/g, '');
//     if (digits.length <= 3) return `(${digits}`;
//     if (digits.length <= 6) return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
//     return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
//   };
// console.log(formatPhoneNumber)
//   return (
//     <FormControl fullWidth variant="outlined" size="small">
//       <Typography
//         variant="body1"
//         disable={disable}
//         sx={{
//           marginBottom: "4px",
//           ...labelStyles, // Custom styles for label
//           color:
//             formik.touched[name] && Boolean(formik.errors[name])
//               ? theme?.palette?.secondary?.dangerMain
//               : theme?.palette?.primary?.main,
//           fontFamily: theme?.typography?.fontFamily,
//           ...theme?.typography?.inputHeading,
//         }}
//       >
//         {label}
//         {required && (
//           <span
//             style={{
//               color:
//                 formik.touched[name] && Boolean(formik.errors[name])
//                   ? theme?.palette?.secondary?.dangerMain
//                   : theme?.palette?.secondary?.dangerMain,
//               marginLeft: "4px",
//             }}
//           >
//             *
//           </span>
//         )}
//       </Typography>

//       <TextField
//         name={name}
//         required={required}
//         variant="outlined"
//         size="small"
//         placeholder={placeholder}
//         onBlur={formik.handleBlur}
//         onChange={(e) => {
//           const formattedValue = formatPhoneNumber(e.target.value);
//           formik.handleChange({ target: { name, value: formattedValue } });
//         }}
//         value={formik.values[name]}
//         error={formik.touched[name] && Boolean(formik.errors[name])}
//         helperText={helperText || (formik.touched[name] && formik.errors[name])}
//         sx={{ ...inputStyles }} // Custom styles for input field
//         InputProps={{
//           ...InputProps, // Spread InputProps here
//           style: {
//             color: theme.palette.primary?.placeholderColor,
//             ...theme?.typography?.inputPlaceHolder,
//             // ...InputProps.style, // Merge with existing styles if needed
//           },
//         }}
//         InputLabelProps={{
//           ...InputLabelProps, // Spread InputLabelProps here
//           style: {
//             color:
//               formik.touched[name] && Boolean(formik.errors[name])
//                 ? theme.palette.secondary.dangerMain
//                 : theme.palette.primary.placeholderColor,
//             ...InputLabelProps.style, // Merge with existing styles if needed
//             ...InputProps.style,
//             ...theme?.typography?.inputHeading,
//           },
//         }}
//         {...props} // Additional props
//       />
//     </FormControl>
//   );
// };

// export default LabeledTextField;

export const CustomSelectDropDown = ({
  label,
  id,
  name,
  value,
  error,
  helperText,
  options,
  formik,
  theme,
  required = true,
  inputStyles = {},
  placeholder,
  handleBlur,
  ...props // Any other props you want to pass to the Select component
}) => {
  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      error={error}
      // required={required}
      sx={{
        ...inputStyles, // Apply any passed styles here
      }}
    >
      <Select
        labelId={`${name}-label`}
        id={name}
        autoComplete="off"
        name={name}
        value={value}
        placeholder={placeholder}
        displayEmpty
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: theme?.palette?.background?.paper,
              color: theme?.palette?.primary?.placeholderColor,
              maxHeight: 200,
              overflowY: "auto",
              "& .MuiMenuItem-root": {
                "&:hover": {
                  backgroundColor: "#686DFC26", // Hover background color
                  color: "#686DFC", // Hover text color
                },
              },
            },
          },
        }}
        sx={{
          ...theme?.typography?.inputPlaceHolder, // Match placeholder text style
          color: theme.palette.primary?.placeholderColor,
          ...inputStyles, // Additional input styles
        }}
        {...props} // Spread any other props
      >
        <MenuItem value="" disabled>
          <Typography
            sx={{
              fontStyle: "normal",
              color: theme?.palette?.primary?.dropDownColor,
            }}
          >
            {label}
          </Typography>
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem
            key={option?._id || index} // Use `_id` if available, otherwise fallback to `index`
            value={option?._id || option} // Use `_id` for object, or `option` directly for plain value
            sx={{ textTransform: "capitalize" }}
          >
            {typeof option === "object" && option?.name ? option.name : option}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

//  state field
export const StateSelect = ({
  id = "state",
  name = "state",
  placeholder,
  options = [],
  formik,
  noOptionsText = "No state found",
  inputPersonStyles,
  theme,
  required,
  disableClearable = true,
  size = "small",
  onKeyDown,
  customOnChange, // Optional custom onChange handler
}) => {
  return (
    <>
      <Autocomplete
        id={id}
        options={options}
        disableClearable={disableClearable}
        getOptionLabel={(option) => option}
        value={formik.values[name] || ""}
        onChange={(event, newValue) => {
          formik.setFieldValue(name, newValue);
          if (customOnChange) customOnChange(newValue); // Call the custom onChange if provided
        }}
        onBlur={formik.handleBlur}
        PaperComponent={({ children }) => (
          <Paper
            style={{
              backgroundColor: "#FFFFFF",
              "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-hover": {
                bgcolor: "red",
              },
              color: theme?.palette?.primary?.main,
              maxHeight: 300, // Adjust as needed
              overflowY: "auto",
            }}
          >
            {children}
          </Paper>
        )}
        ListboxProps={{
          sx: {
            maxHeight: 200,
            overflowY: "auto",
            "& .MuiAutocomplete-option": {
              "&:hover": {
                backgroundColor: "#686DFC26",
                color: "#686DFC",
              },
            },
            "& .MuiAutocomplete-noOptions": {
              color: "#FF7799", // Customize the color of the noOptionsText
            },
          },
        }}
        noOptionsText={noOptionsText}
        renderInput={(params) => (
          <TextField
            {...params}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            id={id}
            name={name}
            required={required}
            variant="outlined"
            placeholder={placeholder}
            onKeyDown={onKeyDown}
            size={size}
            autoComplete={name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values[name]}
            helperText={formik.touched[name] && formik.errors[name]}
            sx={inputPersonStyles?.state(formik)}
            InputLabelProps={{
              style: {
                color:
                  formik.touched[name] && Boolean(formik.errors[name])
                    ? theme?.palette?.secondary?.dangerMain
                    : theme?.palette?.primary?.main,
              },
            }}
          />
        )}
      />
    </>
  );
};

export const NoteInput = ({
  label = "Note",
  placeholder = "Your note here...",
  rows = 6,
  name = "note",
  formik,
  inputStyles = {},
  theme,
  required = false,
  ...props
}) => {
  return (
    <FormControl fullWidth>
      <Typography
        variant="body1"
        sx={{
          marginBottom: "4px",
          color: theme?.palette?.primary?.main, // Dynamic label color
          fontFamily: theme?.typography?.fontFamily,
          ...theme?.typography?.inputHeading,
        }}
      >
        {label} {required && "*"}
      </Typography>

      <TextField
        fullWidth
        multiline
        rows={rows}
        name={name}
        variant="outlined"
        value={formik.values?.[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched?.[name] && Boolean(formik.errors?.[name])} // Handle errors
        helperText={formik.touched?.[name] && formik.errors?.[name]} // Show error text
        placeholder={placeholder}
        required={required} // Ensure required prop works
        sx={{
          ...inputStyles, // Spread input styles
        }}
        InputLabelProps={{
          style: { color: theme?.palette?.secondary?.main }, // Dynamic label color
        }}
        InputProps={{
          style: {
            color: theme?.palette?.primary?.main, // Dynamic input text color
          },
        }}
        {...props} // Spread additional props
      />
    </FormControl>
  );
};
export const ViewFileNoteInput = ({
  value,
  setValue,
  label = "Note",
  placeholder = "Your note here...",
  rows = 6,
  name = "note",
  inputStyles = {},
  theme,
  required = false,
  ...props
}) => {
  const [touched, setTouched] = useState(false);

  // useCallback to memoize event handlers
  const handleChange = useCallback(
    (e) => {
      setValue(e.target.value);
    },
    [setValue]
  );

  const handleBlur = useCallback(() => {
    setTouched(true);
  }, []);

  return (
    <FormControl fullWidth>
      <Typography
        variant="body1"
        sx={{
          marginBottom: "4px",
          color: theme?.palette?.primary?.main,
          fontFamily: theme?.typography?.fontFamily,
          ...theme?.typography?.inputHeading,
        }}
      >
        {label} {required && "*"}
      </Typography>

      <TextField
        fullWidth
        multiline
        rows={rows}
        name={name}
        variant="outlined"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        // error={touched && Boolean(error)}
        // helperText={touched && error}
        placeholder={placeholder}
        required={required}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#d9daeb",
            },
            "&:hover fieldset": {
              borderColor: "#d9daeb",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#d9daeb",
            },
          },
          ...inputStyles,
        }}
        InputLabelProps={{
          style: { color: theme?.palette?.secondary?.main },
        }}
        InputProps={{
          style: {
            color: theme?.palette?.primary?.main,
          },
        }}
        {...props}
      />
    </FormControl>
  );
};
// export const ViewFileNoteInput = ({
//   value,
//   setValue,
//   label = "Note",
//   placeholder = "Enter your note here...",
//   rows = 6,
//   name = "note",
//   inputStyles = {},
//   theme,
//   required = false,
//   ...props
// }) => {
//   const [touched, setTouched] = useState(false);
//   const [error, setError] = useState("");

//   const handleChange = (e) => {
//     setValue(e.target.value);
//     // Add custom validation logic if needed
//     setError(""); // Clear error if you want validation logic
//   };

//   const handleBlur = () => {
//     setTouched(true);
//     // Add any onBlur validation logic if needed
//   };

//   return (
//     <FormControl fullWidth>
//       <Typography
//         variant="body1"
//         sx={{
//           marginBottom: "4px",
//           color: theme?.palette?.primary?.main,
//           fontFamily: theme?.typography?.fontFamily,
//           ...theme?.typography?.inputHeading,
//         }}
//       >
//         {label} {required && "*"}
//       </Typography>

//       <TextField
//         fullWidth
//         multiline
//         rows={rows}
//         name={name}
//         variant="outlined"
//         value={value}
//         onChange={handleChange}
//         onBlur={handleBlur}
//         error={touched && Boolean(error)}
//         helperText={touched && error}
//         placeholder={placeholder}
//         required={required}
//         sx={{
//           ...inputStyles,
//         }}
//         InputLabelProps={{
//           style: { color: theme?.palette?.secondary?.main },
//         }}
//         InputProps={{
//           style: {
//             color: theme?.palette?.primary?.main,
//           },
//         }}
//         {...props}
//       />
//     </FormControl>
//   );
// };
// matter input field
// export const MatterInput =()=>{
//   return(
//     <Grid item xs={12} sm={6}>
//     <Typography
//       variant="body1"
//       sx={{
//         color: formik.touched.caseId && Boolean(formik.errors?.caseId) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.primary?.main,
//         marginBottom: "4px", // Spacing between label and input
//         fontFamily: theme?.typography?.fontFamily,
//         ...theme?.typography?.inputHeading,
//         display: "flex",
//         gap: "10px",
//       }}
//     >
//       Matters{" "}
//       <Box
//         variant="span"
//         sx={{
//           color: theme?.palette?.error?.main, // Set the asterisk color to red
//         }}
//       >
//         *
//       </Box>
//     </Typography>
//     <FormControl required variant="outlined" size="small" fullWidth error={formik.touched.case && Boolean(formik.errors.case)} sx={inputMatterStyles?.formControl(formik)}>
//       <Autocomplete
//         id="case"
//         options={allCase}
//         disableClearable={true}
//         getOptionLabel={(option) => option.title}
//         autoComplete
//         size="small"
//         includeInputInList
//         value={allCase.find((option) => option._id === formik.values.caseId) || null}
//         onChange={(event, newValue) => {
//           formik.setFieldValue("case", newValue?._id || "");
//           formik.setFieldValue("caseId", newValue?._id || "");
//         }}
//         onBlur={formik.handleBlur}
//         renderInput={(params) => (
//           <TextField
//             {...params}
//             placeholder="Select Matter"
//             variant="outlined"
//             required
//             fullWidth
//             error={formik.touched.case && Boolean(formik.errors.case)}
//             helperText={formik.touched.case && formik.errors.case ? formik.errors.case : ""}
//             InputLabelProps={{
//               sx: {
//                 color: theme?.palette?.primary?.main,
//                 ...theme?.typography?.inputPlaceHolder,
//                 "&.Mui-focused": {
//                   color: theme?.palette?.primary?.main,
//                 },
//               },
//             }}
//             sx={inputMatterStyles?.TextField}
//           />
//         )}
//         PaperComponent={(props) => (
//           <Paper
//             {...props}
//             sx={{
//               backgroundColor: "#FFFFFF",
//               color: theme?.palette?.primary?.placeholderColor,
//             }}
//           />
//         )}
//         ListboxProps={{
//           sx: inputMatterStyles.listbox,
//         }}
//         noOptionsText="No matters found"
//       />

//       {allCase.length <= 0 && <FormHelperText sx={{ color: "#D32F2F" }}>No matter is added.</FormHelperText>}
//     </FormControl>
//   </Grid>

//   )
// }
