import React from "react";

const FinancialIcon = ({ color }) => {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 13.5H11.75C12.575 13.5 13.25 12.825 13.25 12C13.25 11.175 12.575 10.5 11.75 10.5H10.25C9.425 10.5 8.75 9.825 8.75 9C8.75 8.175 9.425 7.5 10.25 7.5H14M11 5.25V7.0005M11 12.75V15.75M20.75 10.5C20.75 13.0859 19.7228 15.5658 17.8943 17.3943C16.0658 19.2228 13.5859 20.25 11 20.25C8.41414 20.25 5.93419 19.2228 4.10571 17.3943C2.27723 15.5658 1.25 13.0859 1.25 10.5C1.25 7.91414 2.27723 5.43419 4.10571 3.60571C5.93419 1.77723 8.41414 0.75 11 0.75C13.5859 0.75 16.0658 1.77723 17.8943 3.60571C19.7228 5.43419 20.75 7.91414 20.75 10.5Z"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FinancialIcon;
