import { Divider, Grid, Typography, Box, TextField, Button } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { theme } from "../../../constant/Theme";
import { useQueryClient } from "react-query";

const AddCompanyModel = ({formik,  closeModal, customCompanyName, url, cahcheKey, data }) => {
  const [newCompany, setCompany] = useState(customCompanyName);
  const user = useSelector((state) => state?.reducer?.user);
  const [typeError, setTypeError] = useState(false);
  const queryClient = useQueryClient();

  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };
  const handleClose = async () => {
    // Check if the This value is already in use. Please select a different one. in the local `data` array (case-insensitive)
    if (Array.isArray(data) && data.some((existingType) => existingType.toLowerCase() === newCompany.trim().toLowerCase())) {
      setTypeError(true);
       return;
    }

    // Check if the newCompany value is empty
    if (!newCompany.trim()) {
      showErrorToastMessage("Type cannot be empty.");
      setTypeError(true);
      return;
    }

    // Set the new company name in Formik
    formik.setFieldValue("companyName", newCompany);

    try {
      const token = user?.userData?.token;
      const data = {
        name: newCompany,
      };
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      // Handle success
      if (response?.status === 200) {
        queryClient.invalidateQueries(cahcheKey);
      } else {
        throw new Error("Failed to add Company type");
      }
    } catch (error) {
      console.error("Error:", error);
      setTypeError(true);
      // showErrorToastMessage("An unexpected error occurred while adding the company type.");
    }

    // Close the modal if everything is successful
    closeModal();
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  // Handle input change
  const handleChange = (e) => {
    const value = e.target.value;
    setCompany(capitalizeFirstLetter(value));
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form from submitting if there's a form tag
      handleClose(); // Trigger handleClose on Enter key
    }
  };
  return (
    <Grid container spacing={{ xs: 0, sm: 2 }}>
      <Grid item xs={11} sm={11} sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h5" sx={{ color: theme?.palette?.primary?.main, fontFamily: theme?.typography?.fontFamily }}>
          Add Company
        </Typography>
      </Grid>
      <Grid item xs={1} sm={1} sx={{ display: "flex", justifyContent: "flex-end" }} onClick={closeModal}>
        <CloseIcon sx={{ color: theme?.palette?.primary?.main, cursor: "pointer" }} />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ backgroundColor: theme?.palette?.divider, marginY: "10px" }} />
      </Grid>
      {/* here is form  */}
      <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3, position: "relative", width: "100%" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{
                color: formik.touched.newPassword && Boolean(formik.errors?.newPassword) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.primary?.main,
                marginBottom: "4px", // Spacing between label and input
                fontFamily: theme?.typography?.fontFamily,
                ...theme?.palette?.inputHeading,
                display: "flex",
                gap: "10px",
              }}
            >
              Company
              <Box
                variant="span"
                sx={{
                  color: theme?.palette?.error?.main, // Set the asterisk color to red
                }}
              >
                *
              </Box>
            </Typography>
            <TextField
              fullWidth
              autoComplete="given-companyName"
              name="companyName"
              id="companyName"
              placeholder="Company Name"
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              value={formik?.values?.newCompany}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
               sx={{
                // width: "100%",
                borderRadius: "5px",
                "& .MuiOutlinedInput-root": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
                  },
                  "&::placeholder": {
                    textTransform: "capitalize", // Capitalize the placeholder text
                    color: theme?.palette?.primary?.placeholderColor, // Placeholder text color
                  },
                },
              }}
              InputLabelProps={{
                style: { color: theme?.palette?.primary?.main, textTransform: "capitalize" },
              }}
              InputProps={{
                style: {
                  color: theme?.palette?.primary?.placeholderColor,
                  textTransform: "capitalize",
                },
              }}
            />
            {typeError && (
              <Typography
                sx={{
                  color: theme?.palette.secondary?.dangerMain,
                  fontSize: "14px",
                  marginTop: "6px",
                }}
              >
                This value is already in use. Please select a different one.
              </Typography>
            )}
          </Grid>
        </Grid>
        {/* button */}
        <Grid container spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "flex-end",
              gap: 1,
            }}
          >
            {" "}
            <Button
              onClick={closeModal}
              variant="contained"
              sx={{
                width: { xs: "100%", sm: "150px" },
                height: "45px",
                bottom: "0px", // Adjust as needed
                mt: { xs: 1, sm: 1.5 },
                mb: { xs: 0, sm: 2 },
                borderRadius: "4px",
                boxShadow: 0,
                "&:hover, &:focus": {
                  boxShadow: 0,
                  backgroundColor: theme?.palette?.secondary?.cancelBtn,
                  color: theme?.palette?.secondary?.secondary,
                },
                fontSize: "12px",
                backgroundColor: theme?.palette?.secondary?.cancelBtn,
                color: theme?.palette?.secondary?.secondary,
                fontWeight: "600",
                fontFamily: theme?.typography?.fontFamily,
                ...theme?.typography?.smallText,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                width: { xs: "100%", sm: "150px" },
                mt: { xs: 1, sm: 1.5 },
                mb: { xs: 0, sm: 2 },
                height: "45px",
                "&:hover, &:focus": {
                  boxShadow: theme?.shadows?.[1],
                  backgroundColor: "white",
                  color: "#FFFFFF",
                  color: "#FFFFFF",
                  backgroundColor: theme?.palette?.secondary?.btnPrimary,
                  boxShadow: 0,
                },
                "&:disabled": {
                  backgroundColor: theme?.palette?.secondary?.btnPrimary,
                  color: "white",
                  boxShadow: 0,
                },
                boxShadow: 0,
                fontSize: "12px",
                backgroundColor: theme?.palette?.secondary?.btnPrimary,
                fontWeight: "600",
                fontFamily: theme?.typography?.fontFamily,
                ...theme?.typography?.smallText,
              }}
            >
              save changes
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default AddCompanyModel;
