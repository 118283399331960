import { Box, Typography } from "@mui/material";
import React from "react";
import QueriesunableIcon from "../../Icons/QueriesunableIcon";
import { theme } from "../../../constant/Theme";

const QuerieIcon = () => {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: "25px", marginTop: "10px" }}>
        <Box
          component="img"
          src="/images/logoD.png"
          alt="logo"
          sx={{
            width: "60px",
            height: "auto",
            p: 1,
          }}
        ></Box>
        <Typography sx={{ color: theme?.palette?.primary?.main, ...theme?.typography?.subheading, textAlign: "center", fontFamily: theme?.typography?.fontFamily }}>
          {/* Embracing DocuLex AI Chatbot for <br /> Document Generation */}
          The DocuLex AI Chatbot answers queries based <br /> on substantive content from case materials.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          border: "1px solid #D5BBFD",
          borderRadius: "8px",
          maxWidth: "75%",
          // margin: "auto",
          padding: "15px",
          backgroundColor: theme?.palette?.secondary?.cancelBtn,
        }}
      >
        <QueriesunableIcon />
        <Typography
          sx={{
            fontSize: "14px",
            fontFamily: theme?.typography?.fontFamily,
            color: theme?.palette?.primary?.placeholderColor,
            ...theme?.applyStyles.typography?.minorText,
            textAlign: "center",
          }}
        >
          To begin, select the appropriate filters from the dropdowns above. Once you have made your selections, type your query below to chat with DocuLex AI.{" "}
        </Typography>
      </Box>
    </>
  );
};

export default QuerieIcon;
