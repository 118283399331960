import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useAxios } from '../services/http.service';
import { useSelector } from 'react-redux';
import { useMultiFileUpload } from '../context/useMultiFileUploade';

const useChatMessages = () => {
  const { chatHistory, setChatHistory, allChat, setAllChat } = useMultiFileUpload();
    // const [chatHistory, setChatHistory] = useState(null);
  // const [allChat, setAllChat] = useState([]); // Store all chat messages
  const [newMessageId, setNewMessageId] = useState(null); // Track the ID of the new message
  const [pastConversationLoaded, setPastConversationLoaded] = useState(false); // Track if past conversation is loaded
  const { user } = useSelector((state) => state.reducer);
  const { get, setBearerToken } = useAxios();

  // Fetch data from API
  const fetchData = async () => {
     if (chatHistory) {
      let endpoint = `chatbot/${chatHistory?.thread_id}`;
      const token = user?.userData?.token;
      setBearerToken(token);

      const response = await get(endpoint);

      if (!response?.data || response?.data.length === 0) {
        setAllChat([])
        throw new Error("No data found");
      }

      setAllChat(response?.data)

      return response?.data;
    }else{
        setAllChat(null)
    }
  };

  // Use React Query to fetch chat data
  const { data=[], isLoading } = useQuery(['chatbot', chatHistory], fetchData, {
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchOnMount: true,
    staleTime: 0,
    cacheTime: 10 * 60 * 1000,
    enabled: !!chatHistory?.thread_id, // Ensure the query only runs if chatHistory is not null
  });

  // Detect new messages and update state
//   useEffect(() => {
//     if (chatHistory === null) {
//       // If chatHistory is null, clear the chat
//       setAllChat([]); // Clear the chat
//       setPastConversationLoaded(false); // Reset conversation load state
//       setNewMessageId(null); // Reset new message ID
//     } else if (data) {
//       // When data is first loaded, treat it as past conversation (no animation)
//       if (!pastConversationLoaded) {
//         setAllChat(data); // Load entire conversation
//         setPastConversationLoaded(true); // Mark past conversation as loaded
//         return;
//       }

//       // Check if a new message is added (data.length > allChat.length)
//       if (data.length > allChat.length) {
//         const newMessage = data[data.length - 1];
//         setNewMessageId(newMessage.id); // Set the new message ID (for animation)
//         setAllChat(data); // Update the chat data with new message
//       }
//     }
//   }, [chatHistory, data, allChat.length, pastConversationLoaded]);

  return { isLoading, allChat, newMessageId,setAllChat };
};

export default useChatMessages;
