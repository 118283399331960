import React from 'react'

const MedicalIcon = ({color}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M6.878 4.7V3.349l-1.53 1.53H6.7c.049 0 .093-.02.126-.053l.052-.126zM5 5.37h1.7a.67.67 0 00.672-.672V3h9.966c.261 0 .476.215.476.476V19.14a.478.478 0 01-.476.476H5.476A.476.476 0 015 19.14V5.37zM9.413 7.76h-.774c0 1.481.214 1.267-1.268 1.267V9.8c1.482 0 1.268-.214 1.268 1.268h.774c0-1.482-.214-1.268 1.267-1.268v-.774c-1.481 0-1.267.214-1.267-1.267zm-1.02-.493a.247.247 0 00-.248.247v1.02h-1.02a.247.247 0 00-.247.247v1.267c0 .136.11.247.247.247h1.02v1.02c0 .137.11.247.247.247H9.66c.136 0 .247-.11.247-.246v-1.021h1.02c.137 0 .247-.11.247-.247V8.78a.247.247 0 00-.246-.247H9.906v-1.02a.247.247 0 00-.247-.247H8.392zm-1.268 6.683h8.564c.325 0 .325-.493 0-.493H7.125c-.325 0-.325.493 0 .493zm0 1.685h8.564c.325 0 .325-.493 0-.493H7.125c-.325 0-.325.493 0 .493zm0 1.686h4.747c.324 0 .324-.493 0-.493H7.125c-.325 0-.325.493 0 .493zM18.307 4.384h.415c.261 0 .476.215.476.476v15.664a.477.477 0 01-.476.476H6.86c-.552 0-.476-.628-.476-.891h10.954c.533 0 .969-.436.969-.97V4.385z"
      clipRule="evenodd"
    ></path>
  </svg>
  )
}

export default MedicalIcon
