import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { theme } from "../../constant/Theme.js";
import { PaperComponent } from "../../Components/Card/SettingCard.js";
import UpdateGenerateBillingForm from "../../Components/Forms/GenerateBillingForm/UpdategenerateMedical.js";

const EditGenerateMedical = () => {
  return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ color: theme?.palette?.primary?.main, fontFamily: theme?.typography?.fontFamily, ...theme?.typography?.title }}>
              Medicals /
              <Box component="span" sx={{ color: theme?.palette?.primary?.subPrimary, fontFamily: theme?.typography?.fontFamily, ...theme?.typography?.title }}>
                {" "}
                Generate New Billing Codes Report
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: "18px" }}>
            <PaperComponent>
              <Box sx={{ width: "100%" }}>
                <UpdateGenerateBillingForm />
              </Box>
            </PaperComponent>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default EditGenerateMedical;
