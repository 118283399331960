import React, { useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { inputPleadingStyles } from "../../pages/Pleadings/style";
import { theme } from "../../constant/Theme";
import LabeledTextField from "../../constant/ConstantInputField";

import { inputSettlementStyles } from "../../pages/Settlements/style";
import { useAxios } from "../../services/http.service";
import { useQuery } from "react-query";

// validations for step 1
export const validationSchemaStepOneSettlements = Yup.object({
  case: Yup.string().required("Case selection is required"),
  caseId: Yup.string().required("Case selection is required"), // Ensures caseId is also captured
  settlingParty: Yup.string().required("Settling Party is required"),
});

export const validationSchemaStepTwoSettlements = Yup.object({
  // Validate amount as a number, required and typeError for invalid input
  amount: Yup.number()
    .required("Amount is required")
    .typeError("Amount must be a valid number"),
    totalMedicalBills: Yup.number()
    .required("Medical Bill is required")
    .typeError("Medical Bill must be a valid number"),
  // Validate monthTreated as a number, required and positive
  monthTreated: Yup.number()
    .required("Month is required")
    .typeError("Month must be a valid number")
    .positive("Month must be a positive number")
    .integer("Month must be an integer")
    .min(1, "Month must be at least 1"), // Optional: To ensure at least 1 month is treated
   
  disputedLiability: Yup.boolean()

    .default(false)
    .typeError("Disputed liability must be true or false"),
});
export const validationSchemaStepThreeSettlements = Yup.object({
  diagnosesTags: Yup.array().of(Yup.string()),
  treatmentTags: Yup.array().of(Yup.string()),
});

// defined state
export const SettlementsRenderStep = (activeStep, formik) => {
  const { user } = useSelector((state) => state?.reducer);
  const { get, setBearerToken } = useAxios();

  // cases api
  const [allCase, setAllCase] = useState([]);

  const getAllCase = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "caseDropdownType",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status == 200) {
        setAllCase(response?.data?.data);
      }
    } catch (error) {}
  };

  const [allPersons, setAllPerson] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [allClient, setAllClient] = useState([]);
  const [newDiagnoseTag, setNewDiagnoseTag] = useState("");
  const [newTreatmentTag, setNewTreatmentTag] = useState("");
  let getAllClient = allClient?.map(
    (value) => value.firstName + " " + value.lastName
  );
  const getAllPerson = async () => {
    const token = user?.userData?.token;

    let endpoint;
    if (formik.values?.caseId) {
      endpoint = `personsDropdown?global=${formik.values?.caseId}`;
    }
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + endpoint,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status == 200) {
        setAllPerson(response?.data);
      }
    } catch (error) {}
  };
  const getTags = async () => {
    const token = user?.userData?.token;
  
    if (!formik.values?.caseId) return; // Avoid unnecessary calls if caseId is not available
  
    const endpoint = `getMedicalTags?case=${formik.values.caseId}`;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}${endpoint}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Updated to application/json
          },
        }
      );
  
      if (response.status === 200 && response.data) {
        const { DiagnosisTags = [], TreatmentTags = [] } = response.data;
  
        // Log the response (optional for debugging)
        console.log(response.data);
  
        // Merge the fetched tags with the user-added tags
        const mergedDiagnoseTags = [
          ...new Set([...DiagnosisTags, ...formik.values.diagnoseTag])
        ];
        const mergedTreatmentTags = [
          ...new Set([...TreatmentTags, ...formik.values.treatmentsTag])
        ];
  
        // Update Formik values with merged tags
        formik.setFieldValue("diagnoseTag", mergedDiagnoseTags);
        formik.setFieldValue("treatmentsTag", mergedTreatmentTags);
      }
    } catch (error) {
      console.error("Failed to fetch medical tags:", error);
    }
  };
  
  
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([getAllCase(), getAllPerson(), getTags()]);
    };
  
    fetchData();
  }, [formik.values?.caseId]); // Runs when caseId changes
  
  

  // Helper function to convert ISO date string (YYYY-MM-DD) to timestamp

  const getAllClients = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "clientDropdown",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setAllClient(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getAllClients();
  }, []);

 

  const handleAddTag = (tagType, setTagState, value) => {
    const currentTags = formik.values[tagType];
    if (value.trim() !== "" && !currentTags.includes(value.trim())) {
      // Add to Formik values
      formik.setFieldValue(tagType, [...currentTags, value.trim()]);
  
      // Add to user-added tags state
      if (tagType === "diagnoseTag") {
        setNewDiagnoseTag((prev) => [...prev, value.trim()]);
      } else if (tagType === "treatmentsTag") {
        setNewTreatmentTag((prev) => [...prev, value.trim()]);
      }
  
      // Clear the input
      setTagState("");
    } else {
      console.error("Tag is either empty or already exists.");
    }
  };
  

  

  const handleDeleteTag = (tagType, tagToDelete) => {
    formik.setFieldValue(
      tagType,
      formik.values[tagType].filter((tag) => tag !== tagToDelete)
    );
  };

  // console.log("formik values:", formik.values);
  switch (activeStep) {
    case 0:
      return (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{
                  color:
                    formik.touched.case && Boolean(formik.errors?.case)
                      ? theme?.palette?.secondary?.dangerMain
                      : theme?.palette?.primary?.main,
                  marginBottom: "4px",
                  fontFamily: theme?.typography?.fontFamily,
                  ...theme?.typography?.inputHeading,
                  display: "flex",
                  gap: "10px",
                }}
              >
                Matter
                <Box
                  variant="span"
                  sx={{
                    color: theme?.palette?.error?.main,
                  }}
                >
                  *
                </Box>
              </Typography>
              <FormControl
                required
                variant="outlined"
                size="small"
                fullWidth
                error={formik.touched.case && Boolean(formik.errors.case)}
                sx={inputPleadingStyles?.cases(formik)}
              >
                <Autocomplete
                  id="case"
                  options={allCase}
                  disableClearable={true}
                  getOptionLabel={(option) => option.title}
                  autoComplete
                  size="small"
                  includeInputInList
                  value={
                    allCase.find(
                      (option) => option._id === formik.values.caseId
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue("case", newValue?._id || "");
                    formik.setFieldValue("caseId", newValue?._id || "");
                    formik.setFieldValue("type", newValue?.caseType || "");
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Matter"
                      variant="outlined"
                      required
                      fullWidth
                      error={formik.touched.case && Boolean(formik.errors.case)}
                      helperText={
                        formik.touched.case && formik.errors.case
                          ? formik.errors.case
                          : ""
                      }
                      InputLabelProps={{
                        sx: {
                          color: theme?.palette?.primary?.main,
                          ...theme?.typography?.inputPlaceHolder,
                          "&.Mui-focused": {
                            color: theme?.palette?.primary?.main,
                          },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          color: theme?.palette?.primary?.main,
                          "& fieldset": {
                            borderColor: theme?.palette?.secondary?.inputColor,
                          },
                          "&:hover fieldset": {
                            borderColor: theme?.palette?.secondary?.main,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor:
                              theme?.palette?.primary?.placeholderColor,
                          },
                          ...theme?.typography?.inputPlaceHolder,
                        },
                        "& .MuiSvgIcon-root": {
                          color: theme?.palette?.primary?.placeholderColor,
                        },
                      }}
                    />
                  )}
                  PaperComponent={(props) => (
                    <Paper
                      {...props}
                      sx={{
                        backgroundColor: "#FFFFFF",
                        color: theme?.palette?.primary?.placeholderColor,
                      }}
                    />
                  )}
                  ListboxProps={{
                    sx: {
                      maxHeight: 200,
                      overflowY: "auto",
                      "& .MuiAutocomplete-option": {
                        "&:hover": {
                          backgroundColor: "#686DFC26",
                          color: "#686DFC",
                        },
                      },
                      "& .MuiAutocomplete-noOptions": {
                        color: "#FF7799",
                      },
                    },
                  }}
                  noOptionsText="No matter found"
                />
                {allCase.length <= 0 && (
                  <FormHelperText
                    sx={{ color: theme?.palette?.secondary?.dangerMain }}
                  >
                    No matter is added.
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
  <Typography
    variant="body1"
    sx={{
      color:
        formik.touched.type && Boolean(formik.errors?.type)
          ? theme?.palette?.secondary?.dangerMain
          : theme?.palette?.primary?.main,
      marginBottom: "4px",
      fontFamily: theme?.typography?.fontFamily,
      ...theme?.typography?.inputHeading,
      display: "flex",
      gap: "10px",
    }}
  >
    Matter Type
  </Typography>
  <Box>
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      error={formik.touched?.type && Boolean(formik.errors?.type)}
      sx={{
        ...inputSettlementStyles?.Type(formik),
        "& .MuiOutlinedInput-root": {
          color: "#C8C9DB",
          border: "none",
          boxShadow: "none",
          "&.Mui-disabled": {
            backgroundColor: "#F9FAFB",
            color: "#C8C9DB",
          },
        },
      }}
    >
      <TextField
        value={formik.values.type }
        disabled
        placeholder={
          formik.values.type 
            ? "This matter has no type" 
            : "Matter Type will be auto-selected"
        }
        sx={{
          border: "none",
          padding: "0",
          "& .MuiInputBase-input": {
            padding: "8px 12px",
          },
        }}
      />
    </FormControl>
  </Box>
</Grid>



            {/* Settling_Party  */}
            {/* <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{
                  color:
                    formik.touched.settlingParty &&
                      Boolean(formik.errors?.settlingParty)
                      ? theme?.palette?.secondary?.dangerMain
                      : theme?.palette?.primary?.main,
                  marginBottom: "4px", // Spacing between label and input
                  fontFamily: theme?.typography?.fontFamily,
                  ...theme?.typography?.inputHeading,
                  display: "flex",
                  gap: "10px",
                }}
              >
                Settling Party{" "}
                <Box
                  variant="span"
                  sx={{
                    color: theme?.palette?.error?.main, // Set the asterisk color to red
                  }}
                >
                  *
                </Box>
              </Typography>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                error={
                  formik.touched.settlingParty &&
                  Boolean(formik.errors.settlingParty)
                }
                sx={inputSettlementStyles?.settlingParty(formik)}
              >
                <Select
                  labelId="settlingParty-label"
                  id="settlingParty"
                  required
                  autoComplete="off"
                  name="settlingParty"
                  displayEmpty
                  placeholder="Select Settling Party"
                  value={formik.values.settlingParty || ""} // Ensure default value is empty if nothing is selected
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    // Update both settlingParty and personsId when selecting a settling party
                    const selectedSettlingParty = e.target.value;
                    formik.setFieldValue("settlingParty", selectedSettlingParty);
                    formik.setFieldValue("personsId", selectedSettlingParty);
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "#FFFFFF",
                        color: theme?.palette?.primary?.placeholderColor,
                        maxHeight: 200, // Adjust as needed
                        overflowY: "auto",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            backgroundColor: "#686DFC26", // Hover background color
                            color: "#686DFC", // Hover text color
                          },
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    <Typography
                      sx={{
                        fontStyle: "normal",
                        color: theme?.palette?.primary?.dropDownColor,
                      }}
                    >
                      Select Settling Party
                    </Typography>
                  </MenuItem>

                  {allPersons?.map((row, index) => (
                    <MenuItem key={index} value={row._id}>
                      {row?.personName?.length >= 50
                        ? row?.personName.substring(0, 50) + "...."
                        : row?.personName}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.settlingParty && formik.errors.settlingParty && (
                  <FormHelperText>{formik.errors.settlingParty}</FormHelperText>
                )}
              </FormControl>
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{
                  color:
                    formik.touched.settlingParty &&
                    Boolean(formik.errors?.settlingParty)
                      ? theme?.palette?.secondary?.dangerMain
                      : theme?.palette?.primary?.main,
                  marginBottom: "4px",
                  fontFamily: theme?.typography?.fontFamily,
                  ...theme?.typography?.inputHeading,
                  display: "flex",
                  gap: "10px",
                }}
              >
                Settling Party
                <Box
                  variant="span"
                  sx={{
                    color: theme?.palette?.error?.main,
                  }}
                >
                  *
                </Box>
              </Typography>

              {allPersons.length > 0 ? (
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={
                    formik.touched.settlingParty &&
                    Boolean(formik.errors.settlingParty)
                  }
                  sx={inputSettlementStyles?.settlingParty(formik)}
                >
                  <Select
                    labelId="settlingParty-label"
                    id="settlingParty"
                    required
                    autoComplete="off"
                    name="settlingParty"
                    displayEmpty
                    value={formik.values.settlingParty || ""}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.setFieldValue("settlingParty", e.target.value);
                      formik.setFieldValue("personsId", e.target.value);
                    }}
                  >
                    <MenuItem value="" disabled>
                      <Typography
                        sx={{
                          fontStyle: "normal",
                          color: theme?.palette?.primary?.dropDownColor,
                        }}
                      >
                        Select Settling Party
                      </Typography>
                    </MenuItem>
                    {allPersons.map((row, index) => (
                      <MenuItem key={index} value={row._id}>
                        {row.personName.length >= 50
                          ? row.personName.substring(0, 50) + "..."
                          : row.personName}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.settlingParty &&
                    formik.errors.settlingParty && (
                      <FormHelperText>
                        {formik.errors.settlingParty}
                      </FormHelperText>
                    )}
                </FormControl>
              ) : (
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  sx={{
                    ...inputSettlementStyles?.settlingParty(formik),
                    backgroundColor: theme?.palette?.grey?.[200],
                  }}
                >
                  <Select
                    labelId="settlingParty-label"
                    id="settlingParty"
                    displayEmpty
                    value=""
                  >
                    <MenuItem value="">
                      Please add a person to this matter.
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </>
      );
    case 1:
      return (
        <>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={6}>
              <LabeledTextField
                formik={formik}
                name="amount"
                // type="number"
                label="Amount"
                placeholder="Amount"
                required={true}
                inputStyles={inputSettlementStyles?.Title(formik)} // Custom styles
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <LabeledTextField
                formik={formik}
                name="amount"
                label="Amount"
                placeholder="Enter Amount"
                required={true}
                inputStyles={inputSettlementStyles?.Title(formik)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LabeledTextField
                formik={formik}
                name="totalMedicalBills"
                label="Total Medical Bills"
                placeholder="Enter Medical Bill"
                required={true}
                inputStyles={inputSettlementStyles?.Title(formik)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LabeledTextField
                formik={formik}
                name="monthTreated"
                label="Month Treated"
                placeholder="Month Treated"
                required={true}
                inputStyles={inputSettlementStyles?.Title(formik)} //
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{
                  color:
                    formik.touched.settlingParty &&
                    Boolean(formik.errors?.settlingParty)
                      ? theme?.palette?.secondary?.dangerMain
                      : theme?.palette?.primary?.main,
                  marginBottom: "4px",
                  fontFamily: theme?.typography?.fontFamily,
                  ...theme?.typography?.inputHeading,
                  display: "flex",
                  gap: "4px",
                }}
              >
                Disputed Liability
                <Box
                  variant="span"
                  sx={{
                    color: theme?.palette?.error?.main,
                  }}
                >
                  *
                </Box>
              </Typography>

              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                error={
                  formik.touched.disputedLiability &&
                  Boolean(formik.errors.disputedLiability)
                }
                sx={inputSettlementStyles?.settlingParty(formik)}
              >
                <Select
                  labelId="disputedLiability-label"
                  id="disputedLiability"
                  autoComplete="off"
                  name="disputedLiability"
                  displayEmpty
                  value={formik.values.disputedLiability?.toString() || ""}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const boolValue = e.target.value === "true"; // Convert string to boolean
                    formik.setFieldValue("disputedLiability", boolValue);
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "#FFFFFF",
                        color: theme?.palette?.primary?.placeholderColor,
                        maxHeight: 200,
                        overflowY: "auto",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            backgroundColor: "#686DFC26",
                            color: "#686DFC",
                          },
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    <Typography
                      sx={{
                        fontStyle: "normal",
                        color: theme?.palette?.primary?.dropDownColor,
                      }}
                    >
                      Select Disputed Liability
                    </Typography>
                  </MenuItem>
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
                {formik.touched.disputedLiability &&
                  formik.errors.disputedLiability && (
                    <FormHelperText>
                      {formik.errors.disputedLiability}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
          </Grid>
        </>
      );
    case 2:
      return (
//         <Grid container spacing={1}>
//           {/* Diagnose Tags Section */}
//           <Grid item xs={12} sm={12}>
//             <FormControl fullWidth>
//               <Typography
//                 sx={{
//                   color:
//                     formik.touched.case && Boolean(formik.errors?.case)
//                       ? theme?.palette?.secondary?.dangerMain
//                       : theme?.palette?.primary?.main,
//                   marginBottom: "4px",
//                   fontFamily: theme?.typography?.fontFamily,
//                   ...theme?.typography?.inputHeading,
//                   display: "flex",
//                   gap: "3px",
//                 }}
//               >
//                 Diagnose Tags
//               </Typography>
//               <Box
//                 sx={{
//                   marginTop: "10px",
//                   border: "1px solid #E0E0E0",
//                 }}
//               >
//                    <Box
//                   sx={{
//                     display: "flex",
//                     flexWrap: "wrap",
//                     gap: "8px",
                   
//                     margin: "5px 0 8px 10px",
//                   }}
//                 >
//                   {formik.values.diagnoseTag.map((tag, index) => (
//                     <Chip
//                       key={index}
//                       label={tag}
//                       onDelete={() => handleDeleteTag("diagnoseTag", tag)}
//                       sx={{
//                         backgroundColor: "#F9FAFB",
//                         outline: "1px solid #E6E7F2",
//                         color: theme?.palette?.primary?.main,
//                         fontSize: "14px",
//                       }}
//                     />
//                   ))}
//                 </Box>
//                 {/* <TextField
//                   fullWidth
//                   rows={1}
//                   name="diagnoseTag"
//                   placeholder="Add Diagnose Tags"
//                   value={newDiagnoseTag}
//                   InputProps={{
//                     disableUnderline: true, // Removes the underline styling
                    
//                   }}
//                   sx={{
//                     "& fieldset": { border: "none" },
//                   }}
//                   onChange={(e) => setNewDiagnoseTag(e.target.value)}
//                   onKeyDown={(e) => {
//                     if (e.key === "Enter") {
//                       e.preventDefault(); // Prevent form submission on Enter
//                       handleAddTag(
//                         "diagnoseTag",
//                         setNewDiagnoseTag,
//                         newDiagnoseTag
//                       )
//                     }
//                   }}
                 
//                 />
//                  */}
//                  <TextField
//   fullWidth
//   rows={1}
//   name="diagnoseTag"
//   placeholder="Add Diagnose Tags"
//   value={newDiagnoseTag}
//   InputProps={{
//     disableUnderline: true, // Removes the underline styling
//   }}
//   sx={{
//     "& fieldset": { border: "none" },
//   }}
//   onChange={(e) => setNewDiagnoseTag(e.target.value)}
//   onKeyDown={(e) => {
//     if (e.key === "Enter") {
//       e.preventDefault(); // Prevent form submission on Enter
//       handleAddTag("diagnoseTag", setNewDiagnoseTag, newDiagnoseTag);
//     }
//   }}
// />

             
//               </Box>
//             </FormControl>
//           </Grid>

//           {/* Treatments Tags Section */}
//           <Grid item xs={12} sm={12}>
//   <FormControl fullWidth>
//     <Typography
//       sx={{
//         color:
//           formik.touched.case && Boolean(formik.errors?.case)
//             ? theme?.palette?.secondary?.dangerMain
//             : theme?.palette?.primary?.main,
//         marginBottom: "4px",
//         fontFamily: theme?.typography?.fontFamily,
//         ...theme?.typography?.inputHeading,
//         display: "flex",
//         gap: "3px",
//       }}
//     >
//       Treatments Tags
//     </Typography>
//     <Box
//       sx={{
//         marginTop: "5px",
//         border: "1px solid #E0E0E0",
//       }}
//     >
//            <Box
//         sx={{
//           display: "flex",
//           flexWrap: "wrap",
//           gap: "8px",
//           paddingTop: "5px",
//           margin: "0 0 8px 10px",
//         }}
//       >
//         {formik.values.treatmentsTag.map((tag, index) => (
//           <Chip
//             key={index}
//             label={tag}
//             onDelete={() => handleDeleteTag("treatmentsTag", tag)}
//             sx={{
//               backgroundColor: "#F9FAFB",
//               outline: "1px solid #E6E7F2",
//               color: theme?.palette?.primary?.main,
//               fontSize: "14px",
//             }}
//           />
//         ))}
//       </Box>
//       {/* <TextField
//         fullWidth
//         rows={1}
//         name="treatmentsTag"
//         placeholder="Add Treatments Tags"
//         value={newTreatmentTag}
//         onChange={(e) => setNewTreatmentTag(e.target.value)}
//         onKeyDown={(e) => {
//           if (e.key === "Enter") {
//             e.preventDefault(); // Prevent form submission on Enter
//             handleAddTag("treatmentsTag", setNewTreatmentTag, newTreatmentTag);
//           }
//         }}
//         sx={{
//           "& fieldset": { border: "none" },
//         }}
//       /> */}
//       <TextField
//   fullWidth
//   rows={1}
//   name="treatmentsTag"
//   placeholder="Add Treatments Tags"
//   value={newTreatmentTag}
//   onChange={(e) => setNewTreatmentTag(e.target.value)}
//   onKeyDown={(e) => {
//     if (e.key === "Enter") {
//       e.preventDefault(); // Prevent form submission on Enter
//       handleAddTag("treatmentsTag", setNewTreatmentTag, newTreatmentTag);
//     }
//   }}
//   sx={{
//     "& fieldset": { border: "none" },
//   }}
// />

 
//     </Box>
//   </FormControl>
// </Grid>

//         </Grid>
<Grid container spacing={1}>
  {/* Diagnose Tags Section */}
  <Grid item xs={12} sm={12}>
    <FormControl fullWidth>
      <Typography
        sx={{
          color:
            formik.touched.case && Boolean(formik.errors?.case)
              ? theme?.palette?.secondary?.dangerMain
              : theme?.palette?.primary?.main,
          marginBottom: "4px",
          fontFamily: theme?.typography?.fontFamily,
          ...theme?.typography?.inputHeading,
          display: "flex",
          gap: "3px",
        }}
      >
        Diagnose Tags
      </Typography>
      <Box
        sx={{
          marginTop: "10px",
          border: "1px solid #E0E0E0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
            margin: "5px 0 8px 10px",
          }}
        >
          {formik.values.diagnoseTag.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              onDelete={() => handleDeleteTag("diagnoseTag", tag)}
              sx={{
                backgroundColor: "#F9FAFB",
                outline: "1px solid #E6E7F2",
                color: theme?.palette?.primary?.main,
                fontSize: "14px",
              }}
            />
          ))}
        </Box>

        <TextField
          fullWidth
          rows={1}
          name="diagnoseTag"
          placeholder="Add Diagnose Tags"
          value={newDiagnoseTag}
          onChange={(e) => setNewDiagnoseTag(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault(); // Prevent form submission on Enter
              handleAddTag("diagnoseTag", setNewDiagnoseTag, newDiagnoseTag);
            }
          }}
          sx={{
            "& fieldset": { border: "none" },
          }}
        />
      </Box>
    </FormControl>
  </Grid>

  {/* Treatments Tags Section */}
  <Grid item xs={12} sm={12}>
    <FormControl fullWidth>
      <Typography
        sx={{
          color:
            formik.touched.case && Boolean(formik.errors?.case)
              ? theme?.palette?.secondary?.dangerMain
              : theme?.palette?.primary?.main,
          marginBottom: "4px",
          fontFamily: theme?.typography?.fontFamily,
          ...theme?.typography?.inputHeading,
          display: "flex",
          gap: "3px",
        }}
      >
        Treatments Tags
      </Typography>
      <Box
        sx={{
          marginTop: "5px",
          border: "1px solid #E0E0E0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
            paddingTop: "5px",
            margin: "0 0 8px 10px",
          }}
        >
          {formik.values.treatmentsTag.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              onDelete={() => handleDeleteTag("treatmentsTag", tag)}
              sx={{
                backgroundColor: "#F9FAFB",
                outline: "1px solid #E6E7F2",
                color: theme?.palette?.primary?.main,
                fontSize: "14px",
              }}
            />
          ))}
        </Box>

        <TextField
          fullWidth
          rows={1}
          name="treatmentsTag"
          placeholder="Add Treatments Tags"
          value={newTreatmentTag}
          onChange={(e) => setNewTreatmentTag(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault(); // Prevent form submission on Enter
              handleAddTag("treatmentsTag", setNewTreatmentTag, newTreatmentTag);
            }
          }}
          sx={{
            "& fieldset": { border: "none" },
          }}
        />
      </Box>
    </FormControl>
  </Grid>
</Grid>

      );

    default:
      return null;
  }
};
