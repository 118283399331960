import React, { useState } from "react";
import { Formik, Form } from "formik";
import { Box, Paper, Button } from "@mui/material";
import { validationSchemaStepTwo } from "../../Validation/PersonMatterValidation";
import { useSelector } from "react-redux";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { theme } from "../../../constant/Theme";
import { PersonMatterRenderStep } from "../../Validation/PersonMatterValidation";
import LoaderIcon from "../../Icons/LoaderIcon";
import { ButtonUICancel, ButtonUIPrevious } from "../../Button";
const PersonMatterForm = ({ activeStep, steps, handleBack, handleNext }) => {
  const location = useLocation();
  const { userData } = location.state || {};
  const { personId } = useParams();
  const validationSchemas = [validationSchemaStepTwo];
  const currentValidationSchema = validationSchemas[activeStep];
  const { user } = useSelector((state) => state?.reducer);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const initialValues = {
    personId: personId,
    caseId: "",
    role: userData?.role,
    policyLimit: "",
    insuranceType: "",
    physicianType: "",
    policyNumber: "",
    note: "",
    pleadingServe: false,
    depositionRequired: false,
  };

  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };
  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };

  const handleFormSubmit = async (values, actions) => {
    try {
      // Check if the fields of the current step are valid using the validation schema
      await currentValidationSchema.validate(values, { abortEarly: false });

      actions.setTouched({
        role: true,
        caseId: true,
        // Optionally set other fields touched if needed
      });

      actions.setSubmitting(true);

      const token = user?.userData?.token;
      setIsSubmitting(true);
      setEmailError(null);

      // Prepare the form data
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      // Make the API call to submit the form
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}personAssinMatter`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response?.status === 200) {
        // Show success message
        showSuccessToastMessage("Person Matters has been added successfully.");

        navigate(`/persons/personsMatter/${personId}`);
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        // Handle form validation errors
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        actions.setErrors(validationErrors);
      } else {
        // Handle API errors
        const apiError = error?.response?.data?.emailError || "An error occurred.";
        showErrorToastMessage(apiError);
      }
    } finally {
      // Reset submitting state after completion
      actions.setSubmitting(false);
      setIsSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={currentValidationSchema} onSubmit={handleFormSubmit} enableReinitialize>
      {(formik) => (
        <Form>
          <Paper
            sx={{
              width: "100%",
              backgroundColor: theme?.palette?.background?.paper,
              color: "lightgray",
              // margin: "25px 0 ",
              boxShadow: "none",
              padding: { xs: 0, sm: 2 },
              borderRadius: "8px",
            }}
            style={{ boxShadow: 0 }}
          >
            {PersonMatterRenderStep(activeStep, formik, emailError, setEmailError)}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                pt: 2,
              }}
            >
              {activeStep === 0 && <ButtonUICancel text={"Cancel"} onClick={() => navigate(`/persons/personsMatter/${personId}`)} variant={"contined"} />}

              {activeStep > 0 && activeStep != 2 && <ButtonUIPrevious variant="outlined" text={"Previous"} onClick={handleBack} disabled={activeStep === 0} />}
              <Box sx={{ flex: "1 1 auto" }} />

              <Box sx={{ flex: "1 1 auto" }} />

              <Button
                type="submit"
                variant="contained"
                // disabled={isSubmitting}
                sx={{
                  width: { xs: "100%", sm: "150px" },
                  mt: { xs: 1, sm: 1.5 },
                  mb: { xs: 0, sm: 2 },
                  "&:hover, &:focus": {
                    backgroundColor: "white",
                    color: "#FFFFFF",
                    backgroundColor: theme?.palette?.secondary?.btnPrimary,
                    boxShadow: 0,
                  },
                  "&:disabled": {
                    backgroundColor: theme?.palette?.secondary?.btnPrimary,
                    color: "white",
                    boxShadow: 0,
                  },
                  boxShadow: 0,
                  fontSize: "12px",
                  backgroundColor: theme?.palette?.secondary?.btnPrimary,
                  fontWeight: "600",
                  fontFamily: theme?.typography?.fontFamily,
                }}
              >
                {activeStep === steps.length - 1 && isSubmitting ? (
                  <>
                    <LoaderIcon color="#f7f7f7" /> Processing...
                  </>
                ) : activeStep === steps.length - 1 ? (
                  "Finish"
                ) : (
                  "Next"
                )}
              </Button>
            </Box>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default PersonMatterForm;
