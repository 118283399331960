import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box,  Grid, Typography } from "@mui/material";
import UpdateGeneratePleadingForm from "../../Components/Forms/GeneratePleadingForm/UpdategeneratePleadingForm.js";
import { theme } from "../../constant/Theme.js";
import { PaperComponent } from "../../Components/Card/SettingCard.js";
const EditGeneratePleading = () => {
 return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ color: theme?.palette?.primary?.main, fontFamily: theme?.typography?.fontFamily, ...theme?.typography?.title }}>
              Pleadings /
              <Box component="span" sx={{ color: theme?.palette?.primary?.subPrimary, fontFamily: theme?.typography?.fontFamily, ...theme?.typography?.title }}>
                {" "}
                Edit Generate New Pleading
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: "18px" }}>
            <PaperComponent>
              <Box sx={{ width: "100%" }}>
                <UpdateGeneratePleadingForm />
              </Box>
            </PaperComponent>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default EditGeneratePleading;
