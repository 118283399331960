import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Paper,
  FormControlLabel,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade.js";
import { inputDocumentPleadingStyles } from "../../../pages/DocumentPleading/style.js";
import { theme } from "../../../constant/Theme.js";
import { NewAddHeading } from "../../Card/SettingCard.js";
import FeedIcons from "../../Icons/FeedIcon.js";
import LabeledTextField from "../../../constant/ConstantInputField.js";
import { ButtonUICancel } from "../../Button/index.js";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const GenerateBillingForm = () => {
  const userData = useSelector((state) => state?.reducer?.user?.userData);
  const { user } = useSelector((state) => state?.reducer);
  const { generateDocument, matter } = useMultiFileUpload();
  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };
  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };

  const [emailError, setEmailError] = useState(null);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allPerson, setAllPerson] = useState([]);
  const [allDeposition, setAllDeposition] = useState([]);
  const formik = useFormik({
    initialValues: {
      title: "",
      case: matter?._id,
      type: null,
    },
    validationSchema: Yup.object({
      title: Yup.string(),
      case: Yup.string().required("Required"),
      type: Yup.object().required("Required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      try {
        setIsSubmitting(true);
        const token = userData?.token;

        const pleadingDataSaving = {
          caseId: values.case,
          title: values.title,
          depositionId: values.type._id,
          witnessId: values.type.witness[0]._id,
        };
        const response = await axios.post(
          process.env.REACT_APP_BACKEND_URL + "pleadingGenerate",
          pleadingDataSaving,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.status == 200) {
          const formData = new FormData();

          const data = {
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            caseName: values.caseName,
            witnessName:
              values.type.witness[0].firstName +
              " " +
              values.type.witness[0].lastName,
            identifyIssue: "", // Assuming this will be filled in later
            uploadDepostion: values.type.fileUrl,
            organizationId: user?.organizationData?._id,
            userId: userData.userId,
            path: `organization/${user?.organizationData?._id}/${userData.userId}/statementOfMaterialFact/${values.title}`,
            ModuleName: "statementOfMaterialFact",
          };

          const data1 = {
            file: values.type.fileUrl,
            name: values.title,
            issue: "",
            organization_id: user?.organizationData?._id,
            user_id: userData.userId,
            api_key:
              process.env.CHATGTP_OPEN_API_KEY ||
              "sk-rVLvFpg5-Fdc6Q3p2oSzHsOVfixz2XHa0Vm-j_77oqT3BlbkFJG--HBmDoS18CUIXsz5dbEhim-O_4lFMTewxllN_YQA",
          };

          Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
          });

          // Now you can pass `formData` wherever it needs to be sent
          generateDocument({
            id: response?.data?.savedPleadings?._id,
            title: values?.title,
            formData: data1,
          });

          // showSuccessToastMessage("Document Pleading  has been added successfully.");
          resetForm();
          setIsSubmitting(true);
          setEmailError(null);
          navigate("/Pleading");
        }
        // Handle successful response
      } catch (error) {
        setEmailError(error?.response?.data?.emailError + ".");
        setIsSubmitting(false);
      }
    },
  });

  const [allCase, setAllCase] = useState([]);
  const getAllCase = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "caseDropdown",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status == 200) {
        setAllCase(response?.data?.data);
      }
    } catch (error) {}
  };
  // persons api

  const getAllDepositon = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          `pleadingGenerate/depositionDropdown/${formik?.values.case}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status == 200) {
        setAllDeposition(response?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getAllCase();
    getAllDepositon();
  }, [formik?.values?.case]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleUpload = (event) => {
    setSelectedFiles([...selectedFiles]);
  };
  const handleFileUpload = (event) => {
    formik.setFieldValue("generateFile", event.currentTarget.files[0]);
  };
  const handleRemoveFile = () => {
    formik.setFieldValue("generateFile", null);
  };
  return (
    <Box
      sx={{
        // minHeight: "100vh",
        maxHeight: { xs: 2000, sm: 2000 },
      }}
    >
      <CssBaseline />
      <NewAddHeading
        title="Basic Details"
        subTitle="Document  Information"
        icon={<FeedIcons color="#387CFF" />}
      />
      <Box
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
        sx={{ position: "relative", p: 2 }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LabeledTextField
              formik={formik}
              name="title"
              label="Title"
              placeholder="Title"
              required={true}
              inputStyles={inputDocumentPleadingStyles?.title(formik)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              sx={{
                color:
                  formik.touched.case && Boolean(formik.errors?.case)
                    ? theme?.palette?.secondary?.dangerMain
                    : theme?.palette?.primary?.main,
                marginBottom: "4px", // Spacing between label and input
                fontFamily: theme?.typography?.fontFamily,
                ...theme?.typography?.inputHeading,
                display: "flex",
                gap: "10px",
              }}
            >
              Matter{" "}
              <Box
                variant="span"
                sx={{
                  color: theme?.palette?.error?.main, // Set the asterisk color to red
                }}
              >
                *
              </Box>
            </Typography>
            <FormControl
              required
              variant="outlined"
              size="small"
              fullWidth
              error={formik.touched.case && Boolean(formik.errors.case)}
              sx={inputDocumentPleadingStyles?.cases(formik)}
            >
              <Autocomplete
                id="case"
                options={allCase}
                disableClearable={true}
                getOptionLabel={(option) => option.title}
                autoComplete
                size="small"
                includeInputInList
                value={
                  allCase.find((option) => option._id === formik.values.case) ||
                  null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue("case", newValue?._id || "");
                  formik.setFieldValue("caseName", newValue?.title || "");
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Matter"
                    variant="outlined"
                    fullWidth
                    error={formik.touched.case && Boolean(formik.errors.case)}
                    helperText={
                      formik.touched.case && formik.errors.case
                        ? formik.errors.case
                        : ""
                    }
                    InputLabelProps={{
                      sx: {
                        color: theme?.palette?.primary?.main,
                        ...theme?.typography?.inputPlaceHolder,
                        "&.Mui-focused": {
                          color: theme?.palette?.primary?.main,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        color: theme?.palette?.primary?.main,
                        "& fieldset": {
                          borderColor:
                            formik.touched.case && Boolean(formik.errors.case)
                              ? theme?.palette?.secondary?.dangerMain
                              : theme?.palette?.secondary?.inputColor,
                        },
                        "&:hover fieldset": {
                          borderColor:
                            formik.touched.case && Boolean(formik.errors.case)
                              ? theme?.palette?.secondary?.dangerMain
                              : theme?.palette?.secondary?.main,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor:
                            formik.touched.case && Boolean(formik.errors.case)
                              ? theme?.palette?.secondary?.dangerMain
                              : theme?.palette?.primary?.main,
                        },
                        ...theme?.typography?.inputPlaceHolder,
                      },
                      "& .MuiSvgIcon-root": {
                        color: theme?.palette?.primary?.placeholderColor,
                      },
                    }}
                  />
                )}
                PaperComponent={(props) => (
                  <Paper
                    {...props}
                    {...props}
                    sx={{
                      backgroundColor: "#FFFFFF",
                      color: theme?.palette?.primary?.placeholderColor,
                    }}
                  />
                )}
                ListboxProps={{
                  sx: {
                    maxHeight: 200,
                    overflowY: "auto",
                    "& .MuiAutocomplete-option": {
                      "&:hover": {
                        backgroundColor: "#686DFC26",
                        color: "#686DFC",
                      },
                    },
                    "& .MuiAutocomplete-noOptions": {
                      color: "#FF7799", // Customize the color of the noOptionsText
                    },
                  },
                }}
                noOptionsText="No matter found"
              />
              {allCase.length <= 0 && (
                <FormHelperText
                  sx={{ color: theme?.palette?.secondary?.dangerMain }}
                >
                  No matter is added.
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              sx={{
                color:
                  formik.touched.type && Boolean(formik.errors?.type)
                    ? theme?.palette?.secondary?.dangerMain
                    : theme?.palette?.primary?.main,
                marginBottom: "4px", // Spacing between label and input
                fontFamily: theme?.typography?.fontFamily,
                ...theme?.typography?.inputHeading,
                display: "flex",
                gap: "10px",
              }}
            >
              Healthcare Provider
              <Box
                variant="span"
                sx={{
                  color: theme?.palette?.error?.main, // Set the asterisk color to red
                }}
              >
                *
              </Box>
            </Typography>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              error={formik.touched?.type && Boolean(formik.errors?.type)}
              sx={{
                ...inputDocumentPleadingStyles?.deposition(formik),
                backgroundColor: formik.values?.case ? "#FFFFFF" : "#F9FAFB", // White if selected, Red if not
              }}
            >
              <Select
                labelId="type-label"
                id="type"
                autoComplete="off"
                displayEmpty
                name="type"
                placeholder="Select  Healthcare Provider"
                value={formik.values?.type || ""}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                disabled={!formik.values?.case} // Disable if no case selected
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#FFFFFF",
                      color: theme?.palette?.primary?.placeholderColor,
                      maxHeight: 200, // Adjust as needed
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor: "#686DFC26", // Hover background color
                          color: "#686DFC", // Hover text color
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography
                    sx={{
                      fontStyle: "normal",
                      color: theme?.palette?.primary?.dropDownColor,
                    }}
                  >
                    Select Healthcare Provider
                  </Typography>
                </MenuItem>
                {allDeposition?.map((row, index) => (
                  <MenuItem key={index} value={row}>
                    {row?.depositionFile}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.type && formik.errors.type && (
                <FormHelperText>{formik.errors.type}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              sx={{
                color:
                  formik.touched.title && Boolean(formik.errors?.title)
                    ? theme?.palette?.secondary?.dangerMain
                    : theme?.palette?.primary?.main,
                marginBottom: "4px", // Spacing between label and input
                fontFamily: theme?.typography?.fontFamily,
                ...theme?.typography?.inputHeading,
                display: "flex",
                gap: "10px",
              }}
            >
              Processing Type
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{
                  display: "flex",
                  justifyContent: "space-between", // Adjust spacing between options
                  alignItems: "center", // Ensures proper vertical alignment
                }}
              >
                <FormControlLabel
                  value="overnight"
                  control={<Radio />}
                  label="Overnight Processing - Reduced Cost"
                />
                <FormControlLabel
                  value="immediate"
                  control={<Radio />}
                  label="Immediate Processing - Increased Cost"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}
        >
          '{" "}
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row-reverse" },
              gap: 1,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              sx={inputDocumentPleadingStyles?.submit}
            >
              Generate
            </Button>

            <ButtonUICancel
              text={"Cancel"}
              onClick={() => navigate("/billing")}
              variant={"contined"}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default GenerateBillingForm;
