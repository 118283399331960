import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { theme } from "../../constant/Theme";
const defaultTheme = createTheme();

const ResetPassword = () => {
  const [open, setOpen] = React.useState(false);
  const [searchParams] = useSearchParams();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state?.reducer?.user);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };
  const showErrorToastMessage = (msg) => {
    return toast.error(msg, {
      position: "bottom-right",
    });
  };
  const formik = useFormik({
    initialValues: {
      newpassword: "",
      confirmpassword: "",
    },
    validationSchema: Yup.object({
      newpassword: Yup.string().min(5).required("Required"),
      confirmpassword: Yup.string()
        .oneOf([Yup.ref("newpassword"), null], "Passwords must match")
        .required("Please confirm your password"),
    }),

    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        const token = searchParams.get("token");
        if (!token) {
          showErrorToastMessage("Error! token not found");
          navigate("/forget-password");
        }
        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });
        formData.append("token", token);

        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "resetpassword", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response?.data) {
          localStorage.removeItem("forget_token");
          showSuccessToastMessage("Password reset successfully.");
          navigate("/login");
        }
        // Handle successful response
      } catch (error) {
        localStorage.removeItem("forget_token");
        localStorage.setItem("tokenExpire", true);
        navigate("/forget-password");
      }
    },
  });
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowConifrmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          minHeight: "100vh",
          maxHeight: { xs: 1000, sm: 1150 },
          backgroundColor: theme?.palette?.primary?.loginBackground,
        }}
      >
        {/* <PopupModal text={{ title: "Error!", content: "Sorry! token expires try again" }} cross={true} handleClose={handleClose} open={open} /> */}
        <Container component="main" maxWidth="xs" sx={{ paddingY: 4 }}>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              boxShadow: "0px 0px 20px 2px rgba(0, 0, 0, 0.05)",
              padding: "30px",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: theme?.palette?.primary?.bodyColor,
              color: theme?.palette?.primary?.textColor,
              gap: "7px",
            }}
          >
            <Link to={"/"}>
              <Avatar alt="Logo" sx={{ m: 2, width: 65, height: 65, cursor: "pointer" }} src="magic_pleadings.png" />
            </Link>
            <Typography component="h4" variant="h4" sx={{ fontFamily: theme?.palette?.typography?.title }}>
              Reset Password
            </Typography>
            <Typography
              component="body2"
              variant="body2"
              sx={{
                fontFamily: theme?.palette?.typography?.bodyText,
                textAlign: "center",
              }}
            >
              for the email {user?.userData?.email}
            </Typography>

            <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={formik.touched.newpassword && formik.errors?.newpassword}
                    required
                    fullWidth
                    name="newpassword"
                    label="New Password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    variant="filled"
                    autoComplete="new-password"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.newpassword}
                    helperText={formik.touched?.newpassword && formik.errors?.newpassword}
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        backgroundColor: "#F9FAFB",
                        outline: "1px solid #E6E7F2",
                        borderRadius: "5px",
                        color: theme?.palette?.primary?.placeholderColor,

                        transition: "background-color 0.3s ease", // Smooth transition for hover
                        "&:hover": {
                          backgroundColor: "#F9FAFB", // Background color on hover
                        },
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                            {showPassword ? <Visibility sx={{ color: "#505058", width: "20px" }} /> : <VisibilityOff sx={{ color: "#505058", width: "20px" }} />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={formik.touched.confirmpassword && formik.errors?.confirmpassword}
                    required
                    fullWidth
                    name="confirmpassword"
                    label="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    id="password"
                    variant="filled"
                    autoComplete="new-password"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.confirmpassword}
                    helperText={formik.touched?.confirmpassword && formik.errors?.confirmpassword}
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        backgroundColor: "#F9FAFB",
                        outline: "1px solid #E6E7F2",
                        borderRadius: "5px",
                        transition: "background-color 0.3s ease", // Smooth transition for hover
                        "&:hover": {
                          backgroundColor: "#F9FAFB", // Background color on hover
                        },
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={handleClickShowConifrmPassword} onMouseDown={handleMouseDownConfirmPassword} edge="end">
                            {showConfirmPassword ? <Visibility sx={{ color: "#505058", width: "20px" }} /> : <VisibilityOff sx={{ color: "#505058", width: "20px" }} />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  // fontFamily: "poppins",
                  mt: 3,
                  mb: 2,

                  // boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                  "&:hover, &:focus": {
                    // boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                    backgroundColor: theme?.palette?.primary?.btnPrimary,
                    // color: theme?.palette?.primary?.btnPrimary,
                  },
                  backgroundColor: theme?.palette?.primary?.btnPrimary,
                  fontWeight: "500",
                }}
              >
                Set new password{" "}
              </Button>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Link
                  to={"/login"}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                  variant="body2"
                >
                  <ArrowBackIosIcon
                    sx={{
                      width: "15px",
                      height: "15px",
                      color: theme?.palette?.primary?.btnPrimary,
                    }}
                  />
                  <Grid
                    item
                    sx={{
                      fontSize: { xs: "16px", sm: "14px" },
                      color: theme?.palette?.primary?.btnPrimary,
                      // fontFamily: "poppins",
                    }}
                  >
                    Back to login
                  </Grid>
                </Link>
              </Grid>
            </Box>
          </Box>
        </Container>
        <Box
          sx={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <Avatar
            alt="Lady Justice"
            sx={{
              // margin: "10px",
              width: 365,
              height: 365,
              cursor: "pointer",
              // background: "black",
              display: { xs: "none", md: "block" },
            }}
            src="lady justice.png"
          />
          <Box
            sx={{
              display: "flex",
              // width: "60%",
              flexDirection: { xs: "column", sm: "row" },
              textAlign: { xs: "center", sm: "" },
              width: { xs: "100%", md: "60%" },
              marginX: { xs: "10px", md: "0px" },
              justifyContent: { xs: "space-around", md: "space-between" },
              alignItems: { xs: "center", md: "end" },
              // justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <Typography component="body2" variant="body2" sx={{ fontFamily: theme?.palette?.typography?.title }}>
              Copyright © 2024 | DocuLex. All rights reserved.
            </Typography>
            <Typography component="body2" variant="body2" sx={{ fontFamily: theme?.palette?.typography?.title }}>
              Privacy Policy | Terms of Condition
            </Typography>
            {/* <Box>
              <Typography
                component="body2"
                variant="body2"
                sx={{ fontFamily: theme?.palette?.typography?.title }}
              >
                Privacy Policy
              </Typography>
              <Typography
                component="body2"
                variant="body2"
                sx={{ fontFamily: theme?.palette?.typography?.title }}
              >
                &nbsp;|&nbsp;
              </Typography>
              <Typography
                component="body1"
                variant="body1"
                sx={{ fontFamily: theme?.palette?.typography?.title }}
              >
                Terms of Condition
              </Typography>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ResetPassword;
