import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import LoadingIcon from "../Icons/LoadingIcon";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useMultiFileUpload } from "../../context/useMultiFileUploade";
const FileUploadToater = () => {
  const { isGenerated, isView, setIsView, setIsGenerated } = useMultiFileUpload();
  return (
    <>
      {isGenerated && (
        <Box sx={{ display: "flex", justifyContent: "end", flexDirection: "column", flexWrap: "wrap", alignContent: "end" }}>
          <Box
            sx={{
              // backgroundColor: "#4A4C64",
              backgroundImage: " #696CFF",
              color: "#C8C9DB",
              display: "flex",
              width: "19rem",
              justifyContent: "space-between",
              p: "7px",
              borderRadius: "4px",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}>
              {!isView ? <LoadingIcon /> : <TaskAltIcon sx={{ color: "#23a123" }} />}

              <Box sx={{ display: "flex", gap: isGenerated ? 3 : 3, alignItems: "center", justifyContent: "space-between" }}>
                <Typography sx={{ color: "#FFFFFF" }}>Please wait, the pleading is being generated.</Typography>
                {isView && <Button sx={{ color: "#ffff", border: "1px solid #ffffff", fontSize: "10px" }}>View</Button>}
                {isView && (
                  <Button
                    onClick={() => {
                      setIsView(false);
                      setIsGenerated(false);
                    }}
                    sx={{ color: "#ffff", border: "1px solid #ffffff", fontSize: "10px" }}
                  >
                    x
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default FileUploadToater;
