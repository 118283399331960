import React, { useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IssuesIcon from "../Components/Icons/IssuesIcon";
import ClientsIcon from "../Components/Icons/ClientsIcon";
import CasesIcon from "../Components/Icons/CasesIcon";
import { PleadingIcon } from "../Components/Icons/PleadingIcon";
import CorespondeceIcon from "../Components/Icons/CorespondeceIcon";
import DispositionsIcon from "../Components/Icons/DispositionsIcon";
import ExibitsIcon from "../Components/Icons/ExibitsIcon";
import MedicalIcon from "../Components/Icons/MedicalIcon";
import PersonsIcons from "../Components/Icons/PersonsIcons";
import DiscoverIcon from "../Components/Icons/DiscoverIcon";
import { useNavigate, useLocation, Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Typography } from "@mui/material";
import CourtIcon from "../Components/Icons/CourtIcon";
import { useMultiFileUpload } from "../context/useMultiFileUploade";
import StudentIcon from "../Components/Icons/StudentIcon";
import { theme } from "./Theme";
import Database from "../Components/Icons/Database";
import DashboardIcon from "../Components/Icons/Dashboard";
import DocumentGeneration from "../Components/Icons/Documentgeneration";
import AlChatbot from "../Components/Icons/AlChatbot";
import { useSelector } from "react-redux";
import DocumentIcon from "../Components/Icons/DocumentIcon";
import { WhiteBgButton } from "../Components/Button";

const NavItem = ({ path, icon: Icon, text, isDrawOpen, subNav }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // const isActive = location.pathname.includes(path);
  const isActive = location.pathname === path;

  console.log(isActive, "isActive");
  return (
    <ListItemButton
      onClick={() => navigate(path)}
      sx={{
        height: "40px",
        // borderRadius: "2px",
        backgroundColor: isActive ? theme?.palette?.primary?.navBg : "",
        "&:hover": {
          backgroundColor: theme?.palette?.primary?.navBg, // Hover background color
        },
      }}
    >
      <ListItemText
        primaryTypographyProps={{
          sx: {
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: theme?.typography?.fontFamily,
            // backgroundColor: isActive ? theme?.palette?.primary?.navBg : "",
          },
        }}
        primary={text}
        sx={{
          // backgroundColor: isActive ? theme?.palette?.primary?.navBg : "",
          paddingLeft: "24px",
          borderRadius: "4px",
          color: isActive ? theme?.palette?.primary?.btnPrimary : "",
          // borderLeft: isActive ? "3px solid #387CFF" : "",
          fontWeight: "400",
        }}
      />
    </ListItemButton>
  );
};

export const MainListItems = () => {
  const navigate = useNavigate();
  const { openDropdown, setOpenDropdown } = useMultiFileUpload();
  const { user } = useSelector((state) => state.reducer);
  // console.log("menuist", user.subscriptionDetails.storage);

  const location = useLocation();
  const { matter } = useMultiFileUpload();
  // Handlers for dropdowns
  const handleDropdown = (dropdown) => {
    setOpenDropdown((prevState) => (prevState === dropdown ? null : dropdown)); // Toggle dropdown
  };
  const isDrawOpen = true;

  return (
    <>
      {user.subscriptionDetails && user.subscriptionDetails.storage > 0 ? (
        <>
          <Box sx={{ margin: "10px 0px 10px 0px" }}>
            <Link to="/dashboard" style={{ textDecoration: "none" }}>
              <Box component="div" sx={{ margin: "10px 0px 10px 0px" }}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: isDrawOpen ? "space-between" : "center",
                    color: theme?.palette?.primary?.main,
                    margin: `0 10px 0 10px`,
                    cursor: "pointer",
                  }}
                >
                  {isDrawOpen && (
                    <>
                      <Box
                        sx={{ display: "flex", gap: "7px" }}
                        onClick={() => handleDropdown("dashboard")}
                      >
                        <DashboardIcon
                          sx={{
                            color:
                              openDropdown === "dashboard"
                                ? "#8D43FE"
                                : theme?.palette?.primary?.main,
                          }}
                        />
                        <Typography
                          sx={{
                            color:
                              openDropdown === "dashboard"
                                ? "#8D43FE"
                                : theme?.palette?.primary?.main,
                            fontSize: "17px",
                            fontWeight: 500,
                            fontFamily: theme?.typography?.fontFamily,
                            textDecoration: "none", // Underline when active
                            cursor: "pointer", // Show pointer to indicate it's clickable
                            textTransform: "capitalize",
                          }}
                        >
                          dashboard
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>{" "}
            </Link>
            <Box
              onClick={() => handleDropdown("database")} // Open "Database Entry" dropdown
              sx={{
                display: "flex",
                alignItems: "center",
                // justifyContent: "space-between",
                color: theme?.palette?.primary?.main,
                margin: `15px   10px  15px 12px`,
                cursor: "pointer",
              }}
            >
              {isDrawOpen && (
                <>
                  <Box sx={{ marginRight: "13px" }}>
                    <Database
                      sx={{
                        color:
                          openDropdown === "database"
                            ? "#8D43FE"
                            : theme?.palette?.primary?.main,
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      color:
                        openDropdown === "database"
                          ? "#8D43FE"
                          : theme?.palette?.primary?.main,
                      fontSize: "17px",
                      fontWeight: 500,
                      fontFamily: theme?.typography?.fontFamily,
                      textTransform: "capitalize",
                    }}
                  >
                    Database Entry
                  </Typography>
                </>
              )}
              {openDropdown === "database" ? (
                <KeyboardArrowDownIcon
                  sx={{
                    color: isDrawOpen
                      ? "#8D43FE"
                      : theme?.palette?.primary?.main,
                    marginLeft: "38px",
                  }}
                />
              ) : (
                <ChevronRightIcon
                  sx={{
                    color: isDrawOpen
                      ? theme?.palette?.primary?.main
                      : "#8D43FE",
                    marginLeft: "38px",
                  }}
                />
              )}
            </Box>
          </Box>
          {openDropdown === "database" && (
            <>
              <NavItem
                path="/Jurisprudence"
                icon={IssuesIcon}
                text="Jurisprudence"
                isDrawOpen={isDrawOpen}
              />
              <NavItem
                path="/clients"
                icon={ClientsIcon}
                text="Clients"
                isDrawOpen={isDrawOpen}
              />
              <NavItem
                path="/courts"
                icon={CourtIcon}
                text="Courts"
                isDrawOpen={isDrawOpen}
              />
              <NavItem
                path="/matters"
                icon={CasesIcon}
                text="Matters"
                isDrawOpen={isDrawOpen}
              />
              <NavItem
                path="/persons"
                icon={PersonsIcons}
                text="Persons"
                isDrawOpen={isDrawOpen}
              />
              <NavItem
                path="/medical"
                icon={MedicalIcon}
                text="Medical Records"
                isDrawOpen={isDrawOpen}
              />
              <NavItem
                path="/exhibits"
                icon={ExibitsIcon}
                text="Exhibits"
                isDrawOpen={isDrawOpen}
              />
              <NavItem
                path="/discovery"
                icon={DiscoverIcon}
                text="Discovery"
                isDrawOpen={isDrawOpen}
              />
              <NavItem
                path="/depositions"
                icon={DispositionsIcon}
                text="Depositions"
                isDrawOpen={isDrawOpen}
              />
              <NavItem
                path="/correspondence"
                icon={CorespondeceIcon}
                text="Correspondence"
                isDrawOpen={isDrawOpen}
              />
              <NavItem
                path="/pleadings"
                icon={PleadingIcon}
                text="Pleadings"
                isDrawOpen={isDrawOpen}
              />
              <NavItem path="/settlements" icon={PleadingIcon} text="Settlements" isDrawOpen={isDrawOpen} />
            </>
          )}
          <Box component="div" sx={{ margin: "10px 0px 10px 0px" }}>
            <Box
              onClick={() => handleDropdown("document")} // Open "Document Generation" dropdown
              component="div"
              sx={{
                display: "flex",
                // justifyContent: "space-between",
                color: "#C8C9DB",
                alignItems: "center",
                margin: `15px 10px 15px  10px`,
                cursor: "pointer",
              }}
            >
              {isDrawOpen && (
                <>
                  <Box sx={{ marginRight: "7px" }}>
                    <DocumentGeneration
                      sx={{
                        color:
                          openDropdown === "document"
                            ? "#8D43FE"
                            : theme?.palette?.primary?.main,
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      color:
                        openDropdown === "document"
                          ? "#8D43FE"
                          : theme?.palette?.primary?.main,
                      fontSize: "17px",
                      fontWeight: 500,
                      fontFamily: theme?.typography?.fontFamily,
                      textTransform: "capitalize",
                    }}
                  >
                    Document Generation
                  </Typography>
                </>
              )}
              {openDropdown === "document" ? (
                <KeyboardArrowDownIcon
                  sx={{
                    color: isDrawOpen
                      ? "#8D43FE"
                      : theme?.palette?.primary?.main,
                  }}
                />
              ) : (
                <ChevronRightIcon
                  sx={{
                    color: isDrawOpen
                      ? theme?.palette?.primary?.main
                      : "#8D43FE",
                  }}
                />
              )}
            </Box>
          </Box>
          {openDropdown === "document" && (
            <>
              <NavItem
                path="/Pleading"
                icon={PleadingIcon}
                text="Pleadings"
                isDrawOpen={isDrawOpen}
                subNav={true}
              />

              {location.pathname.includes("Pleading") && (
                <Link to="/Pleading" style={{ textDecoration: "none" }}>
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                      pl: "26px",
                      pr: "16px",
                      py: "10px",
                    }}
                  >
                    {/* <CircleIcon /> */}
                    <Typography
                      sx={{
                        color:
                          openDropdown === "document"
                            ? "#387CFF"
                            : theme?.palette?.primary?.main,
                        textDecoration: "none",
                      }}
                    >
                      Statement of Material Facts
                    </Typography>
                  </Box>
                </Link>
              )}

              <NavItem
                path="/medicals"
                icon={MedicalIcon}
                text="Medical"
                isDrawOpen={isDrawOpen}
              />

              {location.pathname.includes("medicals") && (
                <Link to="/medicals" style={{ textDecoration: "none" }}>
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                      pl: "40px",
                      pr: "16px",
                      py: "7px",
                    }}
                  >
                    {/* <CircleIcon /> */}
                    <Typography
                      sx={{
                        color:
                          openDropdown === "document"
                            ? "#686DFC"
                            : theme?.palette?.primary?.main,
                        textDecoration: "none",
                      }}
                    >
                      Patient Visit Summary
                    </Typography>
                  </Box>
                </Link>
              )}
              {location?.pathname?.includes("medicals") && (
                <Link to="/billing" style={{ textDecoration: "none" }}>
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                      pl: "40px",
                      pr: "16px",
                      py: "7px",
                    }}
                  >
                    {/* <CircleIcon /> */}
                    <Typography
                      sx={{
                        color:
                          openDropdown === "document"
                            ? "#686DFC"
                            : theme?.palette?.primary?.main,
                        textDecoration: "none",
                      }}
                    >
                      Billing Codes Report
                    </Typography>
                  </Box>
                </Link>
              )}
              <NavItem
                path="/commingSoon"
                icon={StudentIcon}
                text="Student"
                isDrawOpen={isDrawOpen}
              />
            </>
          )}
          <Link to="/AIChatbot" style={{ textDecoration: "none" }}>
            {" "}
            <Box component="div" sx={{ margin: "10px 0px 10px 0px" }}>
              <Box
                component="div"
                sx={{
                  display: "flex",
                  justifyContent: isDrawOpen ? "space-between" : "center",
                  color: theme?.palette?.primary?.main,
                  margin: `15px 10px 15px 12px`,
                  cursor: "pointer",
                }}
              >
                {isDrawOpen && (
                  <>
                    <Box
                      sx={{ display: "flex", gap: "10px" }}
                      onClick={() => handleDropdown("AIChatbot")}
                    >
                      <AlChatbot
                        sx={{
                          color:
                            openDropdown === "AIChatbot"
                              ? "#8D43FE"
                              : theme?.palette?.primary?.main,
                        }}
                      />

                      <Typography
                        sx={{
                          color:
                            openDropdown === "AIChatbot"
                              ? "#8D43FE"
                              : theme?.palette?.primary?.main,
                          fontSize: "17px", // Highlight when active
                          textDecoration: "none", // Underline when active
                          cursor: "pointer", // Show pointer to indicate it's clickable
                          fontWeight: 500,
                          fontFamily: theme?.typography?.fontFamily,
                        }}
                      >
                        AI Chatbot{" "}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Box>{" "}
          </Link>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <DocumentIcon />
            <Typography
              variant="body1"
              sx={{
                color: theme?.palette?.primary?.main,
                marginBottom: "4px", // Spacing between label and input
                fontFamily: theme?.typography?.fontFamily,
                ...theme?.typography?.inputPlaceHolder,
                display: "flex",
                gap: "10px",
                wordBreak: "break-word", // Ensures long words break naturally
                whiteSpace: "normal", // Allows text wrapping
                textAlign: "center", // Aligns text to the center
                justifyContent: "center", // Centers content if using flexbox
                marginTop: "20px",
              }}
            >
              To get started, please buy at least one Attorney Seat.
            </Typography>
            <WhiteBgButton
              title="Dashboard"
              color={"#3F42FF"}
              onClick={() => {
                navigate("/dashboard");
              }}
            />
          </Box>
        </>
      )}
    </>
  );
};
