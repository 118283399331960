import React from "react";

export const PleadingIcon = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 28">
      <path
        fill={color}
        d="M9.025 6.42H7.14v5.507h1.884V6.419zM6.542 4.87H4.656v7.057H6.54l.002-7.057zM11.512 7.983H9.627v3.944h1.885V7.983zM13.996 9.512h-1.885v2.415h1.885V9.512zM18.253 14.28a17.238 17.238 0 00-2.708 1.832L13.72 13.42a16.286 16.286 0 002.708-1.836l1.824 2.696zM15.037 17.332l-.536.36a.304.304 0 01-.424-.08l-2.292-3.38a.307.307 0 01.084-.424l.536-.364a.306.306 0 01.42.08l.18.264 1.932 2.856.18.264c.096.14.06.328-.08.424zM20.11 13.896l-.535.36a.304.304 0 01-.424-.08l-.18-.264-1.933-2.856-.18-.264a.3.3 0 01.084-.42l.532-.364a.31.31 0 01.424.08l2.293 3.384a.307.307 0 01-.08.424z"
      ></path>
      <path
        fill={color}
        d="M13.571 19.729H5.075a.3.3 0 010-.6h8.496a.3.3 0 110 .6zm2.925-10.021l.16-.108V3.04c0-.572-.468-1.04-1.04-1.04H3.04C2.468 2 2 2.468 2 3.04v17.92c0 .572.468 1.04 1.04 1.04h12.576c.572 0 1.04-.468 1.04-1.04v-2.984l-.688-1.172c-.008.012-.02.02-.028.028.036.148.04.3.012.452-.056.292-.22.54-.464.708l-.544.364a1.067 1.067 0 01-.616.188 1.1 1.1 0 01-.912-.48l-.5-.736h-7.84a.3.3 0 110-.6h7.432l-1.22-1.8H5.076a.3.3 0 110-.6h5.896a1.092 1.092 0 01.456-1.188l.528-.356a1.09 1.09 0 01.616-.192c.228 0 .448.072.632.196.156-.084.308-.172.46-.26H4.356a.3.3 0 01-.3-.3V4.572c0-.168.132-.3.3-.3H6.84c.168 0 .3.132.3.3V5.82h2.184a.3.3 0 01.3.3v1.264h2.188a.3.3 0 01.3.3v1.228h2.184c.164 0 .3.136.3.3v2.74c.5-.332.98-.692 1.44-1.084a1.081 1.081 0 01.46-1.16zM21.053 21.38a1.24 1.24 0 01-1.768-.408l-2.228-3.912.316-.212-.508-.748a20.939 20.939 0 01.868-.588l.02-.012.508.748.26-.176 2.804 3.52c.448.56.32 1.384-.272 1.788z"
      ></path>
    </svg>
  );
};
