import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "../Icons/Warning";
import useStorageValidation from "../../hooks/useStorageValidation";
import { useSelector, useDispatch } from "react-redux";
import slices from "../../store/user/userSlice";
import { useLocation, useNavigate } from "react-router-dom";

const RibbonNotification = () => {
  const { isStorageUsageAboveThreshold } = useStorageValidation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.reducer?.user);
  const navigate = useNavigate();

  // State to control the visibility of the notification
  const [showNotification, setShowNotification] = useState(true);

  const handleClose = () => {
    const updatedUserData = {
      ...user,
      storageNotification: false,
    };
    dispatch(slices.setUser(updatedUserData));
    setShowNotification(false); // Hide the notification when the user clicks close
  };

  const location = useLocation();

  // Use useEffect to hide the notification after 5 seconds
  // useEffect(() => {
  //   if (showNotification) {
  //     const timer = setTimeout(() => {
  //       setShowNotification(false); // Hide the notification after 5 seconds
  //     }, 5000); // 5000 ms = 5 seconds

  //     return () => clearTimeout(timer); // Clean up the timer on component unmount
  //   }
  // }, [showNotification]);

  return (
    <>
      {location.pathname !== "/queries" && isStorageUsageAboveThreshold() && user?.storageNotification && showNotification && (
   <Box sx={{ width: "100%" }}>
   <Box>
     <Box
       sx={{
         background: "#f3ca8c",
         borderRadius: "4px",
         display: "flex",
         justifyContent: "space-between",
         alignItems: "center",
         p: 2,
       }}
     >
       <Box
         sx={{
           display: "flex",
           flex: 1,
           alignItems: "center",
           justifyContent: "space-between",
         }}
       >
         <Box sx={{ display: "flex", alignItems: "center" }}>
           <WarningIcon />
           <Typography
             sx={{
               color: "black",
               padding: "10px",
               textAlign: "center",
               fontFamily: "Public Sans",
               fontSize: "15px",
             }}
           >
             Storage is running out! Upgrade now to avoid disruptions and keep things running smoothly.
           </Typography>
         </Box>
         <Button
           onClick={() => {
             navigate("/");
             dispatch(slices.setActiveTab(2));
           }}
           variant="contained"
           sx={{
             backgroundColor: "#686DFC",
             fontWeight: "600",
             fontFamily: "Poppins",
             color: "white",
             textTransform: "uppercase",
           }}
         >
           Upgrade
         </Button>
       </Box>
       <Box sx={{ alignSelf: "flex-start" }}>
         <Typography
           onClick={handleClose}
           sx={{
             padding: "10px",
             cursor: "pointer",
             display: "flex",
             justifyContent: "center",
             alignItems: "center",
           }}
         >
           <CloseIcon />
         </Typography>
       </Box>
     </Box>
   </Box>
 </Box>
 
      )}
    </>
  );
};

export default RibbonNotification;
