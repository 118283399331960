import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box, Divider, Grid, Typography } from "@mui/material";
import GeneratePleadingForm from "../../Components/Forms/GeneratePleadingForm";
import { theme } from "../../constant/Theme";
import { PaperComponent } from "../../Components/Card/SettingCard";

const AddNewDocumentPleading = () => {
  return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ color: theme?.palette?.primary?.main, fontFamily: theme?.typography?.fontFamily, ...theme?.typography?.title }}>
              Pleadings /
              <Box component="span" sx={{ color: theme?.palette?.primary?.subPrimary, fontFamily: theme?.typography?.fontFamily, ...theme?.typography?.title }}>
                {" "}
                Generate New Pleading
              </Box>
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ paddingTop: "18px" }}>
            <PaperComponent>
              <Box sx={{ width: "100%" }}>
                <GeneratePleadingForm />
              </Box>
            </PaperComponent>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default AddNewDocumentPleading;
