import React from "react";

const CompanyIcon = ({ color }) => {
  return (
    <svg width="16" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 18.75V5H13.75V1.25H2.5V18.75H0V20H8.75V16.25H11.25V20H20V18.75H17.5ZM7.5 13.75H5V11.25H7.5V13.75ZM7.5 10H5V7.5H7.5V10ZM7.5 6.25H5V3.75H7.5V6.25ZM11.25 13.75H8.75V11.25H11.25V13.75ZM11.25 10H8.75V7.5H11.25V10ZM11.25 6.25H8.75V3.75H11.25V6.25ZM16.25 13.75H13.75V11.25H16.25V13.75ZM16.25 10H13.75V7.5H16.25V10Z"
        fill={color}
      />
    </svg>
  );
};

export default CompanyIcon;
