import React from "react";

const Database = ({ sx, ...props }) => {
    const { color = "#2B2C40", ...restStyles } = sx || {};

  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color, ...restStyles }} {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 7.704C15.546 8.064 15.05 8.368 14.551 8.617C12.71 9.538 10.352 10 8 10C5.648 10 3.29 9.538 1.449 8.617C0.936155 8.36283 0.450633 8.05691 0 7.704V10C0 11.057 0.838 12.076 2.343 12.828C3.843 13.578 5.878 14 8 14C10.122 14 12.157 13.579 13.657 12.828C15.162 12.076 16 11.058 16 10V7.704ZM16 13.704C15.5494 14.0569 15.0638 14.3628 14.551 14.617C12.71 15.538 10.352 16 8 16C5.648 16 3.29 15.538 1.449 14.617C0.949 14.367 0.454 14.063 0 13.704V16C0 18.21 3.582 20 8 20C12.418 20 16 18.21 16 16V13.704ZM16 4C16 1.79 12.418 0 8 0C3.582 0 0 1.79 0 4C0 5.058 0.838 6.076 2.343 6.828C3.843 7.578 5.878 8 8 8C10.122 8 12.157 7.579 13.657 6.828C15.162 6.076 16 5.058 16 4Z"
        fill={color}
      />
    </svg>
  );
};

export default Database;
