import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import notificationService from "../services/notification.service";
import slices, { setUser } from "../store/user/userSlice";
import { convertToMB } from "../services/convertToMB";

const useStorageValidation = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.reducer?.user);
  const validateAndUpload = useCallback(
    async (newFileSize) => {
      const currentStorage = parseFloat(user?.storageUsed) || 0;

      // If no new file size is provided, assume validation passed
      if (!newFileSize) {
        return true;
      }
      const newFileSizeInMB = parseFloat(
        (newFileSize / (1024 * 1024)).toFixed(2)
      );
      console.log(user);

      const planStorageInMB = user?.subscriptionDetails?.storage;

      if (currentStorage + newFileSizeInMB > planStorageInMB) {
        notificationService.error("Storage limit  has been exceeded");
        return false;
      } else {
        const updatedUserData = {
          ...user,
          storageUsed: currentStorage + newFileSizeInMB,
        };

        dispatch(slices.setUser(updatedUserData));

        return true;
      }
    },
    [dispatch] // only re-create the callback if dispatch changes
  );
  const isStorageUsageAboveThreshold = useCallback(() => {
    const currentStorage = user?.storageUsed || 0;
    const planStorageInMB = user?.subscriptionDetails?.storage;

    // Calculate the current storage usage as a percentage
    const usagePercentage = (currentStorage / planStorageInMB) * 100;

    // Return true if storage usage is greater than 80%
    return usagePercentage > 80;
  }, [user]);

  return { validateAndUpload, isStorageUsageAboveThreshold };
};

export default useStorageValidation;
