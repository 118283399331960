import React from "react";

const QueriesunableIcon = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25" r="24.5" fill="#D5BBFD" stroke="#D5BBFD" />
      <path
        d="M18.7361 8H34.3656C34.4614 8 34.5391 8.07766 34.5391 8.17345V20.8266C34.5391 20.9223 34.4614 21 34.3656 21H14.7125C14.6167 21 14.5391 20.9223 14.5391 20.8266V12.0288M18.7361 8L16.6376 10.0144L14.5391 12.0288M18.7361 8V11.8553C18.7361 11.9511 18.6584 12.0288 18.5626 12.0288H14.5391M24.8174 12.4441H31.5412M31.5412 15.6422H17.6226M31.5412 18.7572H17.6226"
        stroke="white"
        stroke-width="0.68"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.863 36.2771C26.8379 36.3024 26.8238 36.3366 26.8238 36.3723C26.8238 36.3366 26.8379 36.3024 26.863 36.2771ZM22.665 36.3737C22.665 36.3372 22.6502 36.3022 22.6241 36.2768C22.6502 36.3022 22.665 36.3372 22.665 36.3737Z"
        fill="white"
      />
      <path d="M11 24.9559H38.083V22.978V21.1352C38.083 21.0606 38.0224 21 37.9477 21H11.1352C11.0606 21 11 21.0606 11 21.1352V24.9559Z" fill="white" />
      <path
        d="M11 24.9559H38.083M11 24.9559L22.6241 36.2768M11 24.9559V21.1352M38.083 24.9559V22.978V21.1352M38.083 24.9559L26.863 36.2771M11.1352 21H37.9477M11.1352 21C11.0606 21 11 21.0606 11 21.1352M11.1352 21V21C11.0606 21 11 21.0606 11 21.1352V21.1352M37.9477 21C38.0224 21 38.083 21.0606 38.083 21.1352M37.9477 21V21C38.0224 21 38.083 21.0606 38.083 21.1352V21.1352M26.863 36.2771C26.8379 36.3024 26.8238 36.3366 26.8238 36.3723M26.863 36.2771V36.2771C26.8379 36.3024 26.8238 36.3366 26.8238 36.3723V36.3723M26.8238 36.3723V42.6732M26.8238 42.6732C26.8238 42.7479 26.7632 42.8084 26.6885 42.8084M26.8238 42.6732V42.6732C26.8238 42.7479 26.7632 42.8084 26.6885 42.8084V42.8084M26.6885 42.8084H22.8002M22.8002 42.8084C22.7255 42.8084 22.665 42.7479 22.665 42.6732M22.8002 42.8084V42.8084C22.7255 42.8084 22.665 42.7479 22.665 42.6732V42.6732M22.665 42.6732V36.3737M22.665 36.3737C22.665 36.3372 22.6502 36.3022 22.6241 36.2768M22.665 36.3737V36.3737C22.665 36.3372 22.6502 36.3022 22.6241 36.2768V36.2768"
        stroke="white"
        stroke-width="0.67623"
      />
    </svg>
  );
};

export default QueriesunableIcon;
