import React from "react";

const AlChatbot = ({ sx, ...props }) => {
  const { color = "#2B2C40", ...restStyles } = sx || {};

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color, ...restStyles }} {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.7861 9.48195H21.5523C22.3506 9.48195 23.0002 10.1312 22.9971 10.9295L22.9982 14.1309C22.9982 14.9292 22.349 15.5784 21.5507 15.5784H20.859C20.6571 17.9504 18.664 19.8193 16.2411 19.8193H13.72L9.58449 22.9203C9.51615 22.9731 9.43124 23 9.34634 23C9.28525 23 9.22417 22.9865 9.16825 22.9575C9.03365 22.8913 8.94875 22.7536 8.94875 22.6024L8.94979 19.8172H7.75701C5.33414 19.8172 3.34102 17.9483 3.13912 15.5762H2.44747C1.64919 15.5762 1 14.927 1 14.1287V10.9294C1 10.1311 1.64919 9.48195 2.44747 9.48195H3.21366C3.64518 7.36663 5.51937 5.7711 7.75892 5.7711H11.6023V4.93658C10.6953 4.75019 10.0119 3.94777 10.0119 2.98796C10.0119 1.89045 10.9024 1 11.9999 1C13.0974 1 13.9879 1.89045 13.9879 2.98796C13.9879 3.94777 13.3045 4.75124 12.3975 4.93658V5.7711H16.2409C18.4803 5.7711 20.3544 7.36663 20.7861 9.48195ZM13.5902 19.0241H16.2408C18.3613 19.0241 20.084 17.3012 20.084 15.1807V10.4096C20.084 8.28912 18.3611 6.56622 16.2406 6.56622H7.7587C5.63821 6.56622 3.91532 8.28912 3.91532 10.4096V15.1807C3.91532 17.3012 5.63821 19.0241 7.7587 19.0241H9.34906C9.56857 19.0241 9.74666 19.2022 9.74666 19.4217V21.8072L13.352 19.1039C13.4204 19.0531 13.5053 19.0241 13.5902 19.0241ZM8.29411 7.89159H15.7055C17.3879 7.89159 18.759 9.26256 18.7621 10.9451L18.7611 14.6477C18.7611 16.3304 17.3912 17.7012 15.7076 17.7012H8.29411C6.61147 17.7012 5.2406 16.3313 5.2406 14.6477V10.9451C5.2406 9.26245 6.61055 7.89159 8.29411 7.89159ZM15.7055 16.9035C16.951 16.9035 17.9637 15.8909 17.9637 14.6453H17.9668L17.9658 10.9448C17.9658 9.69925 16.9532 8.68664 15.7076 8.68664H8.29411C7.04853 8.68664 6.03592 9.69925 6.03592 10.9448V14.6453C6.03592 15.8909 7.04853 16.9035 8.29411 16.9035H15.7055Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9637 14.6453C17.9637 15.8909 16.951 16.9035 15.7055 16.9035H8.29411C7.04853 16.9035 6.03592 15.8909 6.03592 14.6453V10.9448C6.03592 9.69925 7.04853 8.68664 8.29411 8.68664H15.7076C16.9532 8.68664 17.9658 9.69925 17.9658 10.9448L17.9668 14.6453H17.9637ZM7.89135 11.4699C7.89135 12.2734 8.54573 12.9277 9.34918 12.9277C10.1526 12.9277 10.807 12.2734 10.807 11.4699C10.807 10.6665 10.1526 10.0121 9.34918 10.0121C8.54573 10.0121 7.89135 10.6665 7.89135 11.4699ZM14.6504 12.9277C15.4539 12.9277 16.1082 12.2734 16.1082 11.4699C16.1082 10.6665 15.4539 10.0121 14.6504 10.0121C13.8469 10.0121 13.1926 10.6665 13.1926 11.4699C13.1926 12.2734 13.8469 12.9277 14.6504 12.9277ZM13.06 16.1084C13.8635 16.1084 14.5179 15.454 14.5179 14.6505C14.5179 14.431 14.3398 14.2529 14.1203 14.2529C13.9008 14.2529 13.7227 14.431 13.7227 14.6505C13.7227 15.016 13.4255 15.3132 13.06 15.3132H10.9395C10.5741 15.3132 10.2769 15.016 10.2769 14.6505C10.2769 14.431 10.0988 14.2529 9.8793 14.2529C9.6598 14.2529 9.48171 14.431 9.48171 14.6505C9.48171 15.454 10.1361 16.1084 10.9395 16.1084H13.06Z"
        fill={color}
      />
    </svg>
  );
};

export default AlChatbot;
