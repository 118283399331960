import MainLayout from "../../layouts/MainLayout";
import { Box, Button, CssBaseline, Grid, Paper, Typography } from "@mui/material";
import TableComponent from "../../Components/Table";
import { useNavigate } from "react-router-dom";
import PopupModel from "../../Components/Modal";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useAxios } from "../../services/http.service";
import { useQuery } from "react-query";
import PleadingModel from "../../Components/Modal/PleadingModel";
import { useMultiFileUpload } from "../../context/useMultiFileUploade";
import { theme } from "../../constant/Theme";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { PaperComponent } from "../../Components/Card/SettingCard";
import { AddBtnUI } from "../../Components/Button";
import SettlementModel from "../../Components/Modal/SettlementModel";

const SettlementsPage = () => {
  dayjs.extend(utc);
  const navigate = useNavigate();
  const [openSettlement, setOpenSettlement] = useState(false);
  const handleOpen = () => setOpenSettlement(true);
  const handleClose = () => setOpenSettlement(false);

  const HeadingTable = ["Matter", "Matter Type", "Settling Party", "Amount", "Month Treated", "Disputed Liability", "Actions"];
  const ValuesKeysTable = ["case", "type", "settlingPartyName", "amount", "monthTreated", "disputedLiabilityName"];

  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [filterSearch, setFilterSearch] = useState({});
  const { token } = useSelector((state) => state?.reducer?.user?.userData);
  const { matter } = useMultiFileUpload();
  const [limit, setLimit] = useState(10);
  const { get, setBearerToken } = useAxios();

  const fetchData = async () => {
    let endpoint = `/settlements?page=${currentPage}&limit=${limit}`;

    // Add searchText if available
    if (searchText) {
      endpoint += `&search=${searchText}`;
    }

    // Add global filter if available
    if (matter) {
      endpoint += `&global=${encodeURIComponent(JSON.stringify(matter))}`;
    }

    // Add filterSearch if available
    if (filterSearch && Object.keys(filterSearch).length > 0) {
      endpoint += `&filter=${encodeURIComponent(JSON.stringify(filterSearch))}`;
    }

    setBearerToken(token); // Assuming you have token authentication
    const response = await get(endpoint);
    return response?.data;
  };

  const { data, isLoading } = useQuery(["settlementsData", matter, currentPage, searchText, filterSearch, limit], fetchData, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  let finalData = data;
// console.log(finalData, "data before menuplated ")
if (finalData?.data) {
  const manipulatedData = data?.data?.map((doc) => ({
    _id: doc?._id,
    caseId: doc?.case?._id, // Set case ID
    case: doc?.case?.title, // Set case title
    type: doc?.case?.caseType, // Set case type
  
    type: doc?.type, // Set type (e.g., "Civil Rights", "Consumer Protection")
    settlingParty: doc?.settlingPartyDetails ? doc?.settlingPartyDetails[0]?._id : "",

    settlingPartyName : doc?.settlingPartyDetails

    ? doc?.settlingPartyDetails
    [0].type === "company"
      ? doc?.settlingPartyDetails
      [0]?.companyName
      : doc?.settlingPartyDetails
      [0]?.lastName + " " + doc?.settlingPartyDetails
      [0]?.firstName
    : "",
    amount: "$"+doc?.amount, // Set amount
    totalMedicalBills: doc?.totalMedicalBills, // Set amount
    monthTreated: doc?.monthTreated, // Set months treated
    disputedLiabilityName: doc?.disputedLiability ? "Yes" : "No", // Disputed liability (Yes/No)
    disputedLiability: doc?.disputedLiability , // Disputed liability (Yes/No)
    treatmentsTag: doc?.treatmentsTag?.join(", "), // Join treatments tags
    diagnoseTag: doc?.diagnoseTag?.join(", "), // Join diagnose tags
  }));



  // console.log(manipulatedData,"manuplaated data")
  finalData = {
    ...finalData,
    data: manipulatedData,
  };
}

console.log(finalData,"final data")

  return (
    <MainLayout>
      <PopupModel
        open={openSettlement}
        styleProp={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // width: { xs: "90%", sm: "auto" },
          width: { xs: "90%", sm: "500px" }, // Set a fixed width
          maxWidth: "100%", // Ensure it does not exceed screen size
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          height: "auto",
          backgroundColor: "#FFFFFF",
          boxShadow: 0,
          border: "1px solid #FFFFFF",
          borderRadius: "4px",
        }}
        handleClose={handleClose}
        cards={<SettlementModel closeModal={handleClose} filter={filterSearch} setFilter={setFilterSearch} />}
      />
      <Grid item xs={12}>
        <PaperComponent>
          <Box
            sx={{
              minHeight: "100vh",
              maxHeight: { xs: 2000, sm: 2000 },
              position: "relative",
            }}
          >
            <CssBaseline />
            {/* here is heading  */}
            <Grid container spacing={2} style={{ padding: "14px" }}>
              <Grid
                item
                xs={8}
                sm={8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Typography variant="h5" typography={theme?.typography?.fontFamily} color={theme?.palette?.primary?.main} sx={{ ...theme?.typography?.title }}>
                  Settlements
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <AddBtnUI title="Add new SETTLEMENTS " onClick={() => navigate("/settlements/addSettlement")} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {/* table section */}
              <Grid item xs={12} sm={12}>
                <TableComponent
                  filterSearch={filterSearch}
                  setFilterSearch={setFilterSearch}
                  filter={true}
                  handleOpen={handleOpen}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  limit={limit}
                  setLimit={setLimit}
                  data={finalData}
                  headingTable={HeadingTable}
                  valuesKeys={ValuesKeysTable}
                  viewPageUrl={"/settlements/viewsettlements"}
                  deleteUrl={"/deletesettlements"}
                  editPageUrl={"/settlements/UpdateSettlement"}
                  isDelete={true}
                />
              </Grid>
            </Grid>
          </Box>
        </PaperComponent>
      </Grid>
    </MainLayout>
  );
};

export default SettlementsPage;
