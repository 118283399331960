import { useFormik } from "formik";
import { theme } from "../../constant/Theme";

// inputIssueStyles.js
export const inputPersonStyles = {
  lastName: (formik) => ({
    ...theme?.typography?.inputPlaceHolder,
    color: theme?.palette?.primary?.main,
    width: "100%",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      color: theme?.palette?.primary?.main, // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.lastName && Boolean(formik.errors.lastName) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.lastName && Boolean(formik.errors.lastName) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Yellow border color on focus
      },
    },
  }),

  firstName: (formik) => ({
    width: "100%",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      color: theme?.palette?.primary?.main, // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.firstName && Boolean(formik.errors.firstName) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.firstName && Boolean(formik.errors.firstName) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor, // Yellow border color on focus
      },
    },
  }),
  matterName: (formik) => ({
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      color: theme?.palette?.primary?.main, // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.caseId && Boolean(formik.errors.caseId) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.caseId && Boolean(formik.errors.caseId) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor, // Yellow border color on focus
      },
      "&.Mui-disable .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.primary?.main, // Yellow border color on focus
      },
    },
    "& .MuiSvgIcon-root": {
      color: theme?.palette?.primary?.placeholderColor,
    },
  }),
  email: (formik) => ({
    width: "100%",
    borderRadius: "5px",
    color: theme?.palette?.primary?.main,
    "& .MuiOutlinedInput-root": {
      // , // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.email && Boolean(formik.errors.email) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.email && Boolean(formik.errors.email) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor, // Yellow border color on focus
      },
    },
  }),
  role: (formik) => ({
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      color: theme?.palette?.primary?.main,
      color: theme?.palette?.primary?.main, // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.role && Boolean(formik.errors.role) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.role && Boolean(formik.errors.role) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor, // Yellow border color on focus
      },
    },
    "& .MuiSvgIcon-root": {
      color: theme?.palette?.primary?.placeholderColor,
    },
  }),
  insuranceType: (formik) => ({
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      color: theme?.palette?.primary?.main, // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.insuranceType && Boolean(formik.errors.insuranceType) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.insuranceType && Boolean(formik.errors.insuranceType) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Yellow border color on focus
      },
      ...theme?.typography?.inputPlaceHolder,
    },
    "& .MuiSvgIcon-root": {
      color: theme?.palette?.primary?.placeholderColor,
    },
  }),
  inputText: {
    width: "100%",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
      },

      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor, // Yellow border color on focus
      },
    },
  },
  listbox: {
    maxHeight: 200,
    overflowY: "auto",
    "& .MuiAutocomplete-option": {
      "&:hover": {
        backgroundColor: "#686DFC26",
        color: "#686DFC",
      },
    },
    "& .MuiAutocomplete-noOptions": {
      color: "red", // Customize the color of the noOptionsText
      textAlign: "center", // Center align the text if needed
    },
  },
  phone: (formik) => ({
    width: "100%",
    borderRadius: "5px",
    color: theme?.palette?.primary?.main,
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.phone && Boolean(formik.errors.phone) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.phone && Boolean(formik.errors.phone) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor, // Yellow border color on focus
      },
    },
  }),
  address: (formik) => ({
    width: "100%",
    borderRadius: "5px",
    color: theme?.palette?.primary?.main,
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.address && Boolean(formik.errors.address) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.address && Boolean(formik.errors.address) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor, // Yellow border color on focus
      },
    },
  }),
  city: (formik) => ({
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      color: theme?.palette?.primary?.main, // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.city && Boolean(formik.errors.city) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.city && Boolean(formik.errors.city) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor, // Yellow border color on focus
      },
    },
  }),
  state: (formik) => ({
    width: "100%",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      color: theme?.palette?.primary?.main, // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.state && Boolean(formik.errors.state) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.state && Boolean(formik.errors.state) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor, // Yellow border color on focus
      },

      "& .MuiSvgIcon-root": {
        color: theme?.palette?.primary?.placeholderColor,
      },
    },
  }),
  zipCode: (formik) => ({
    width: "100%",
    borderRadius: "5px",
    color: theme?.palette?.primary?.main,
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.zipcode && Boolean(formik.errors.zipcode) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.zipcode && Boolean(formik.errors.zipcode) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor, // Yellow border color on focus
      },
    },
  }),
  note: {
    width: "100%",
    mb: "20px",
    ...theme?.typography?.inputPlaceHolder,
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor, // Yellow border color on focus
      },
      "& .MuiInputBase-input": {
        "&::-webkit-scrollbar": {
          display: "none",
        },
        msOverflowStyle: "none", // IE and Edge
        scrollbarWidth: "none", // Firefox
      },
    },
  },
  viewPaper: {
    width: "100%",
    backgroundColor: theme?.palette?.primary?.bodyColor,
    color: theme?.palette?.secondary?.main,
    padding: "20px",
    borderRadius: "8px",
  },
  viewHeading: {
    fontSize: "16px",
    lineHeight: "24px",
    color: theme?.palette?.primary?.main,
    fontFamily: theme?.typography?.fontFamily,
    fontWeight: "500",
  },
  viewSubHeading: {
    fontSize: "14px",
    lineHeight: "19.8px",
    color: theme?.palette?.primary?.placeholderColor,
    fontFamily: theme?.typography?.fontFamily,
  },
  backBtn: {
    // padding: { xs: "10px 25px", xl: "20px 50px" },
    color: theme?.palette?.secondary?.btnPrimary,
    fontSize: { xs: "10px", xl: "15px" },
    borderColor: "white",
    height:'50px',
    width:'160px',
    border: "2px solid",
    borderRadius: "4px",
    background: "transparent",
    "&:hover": {
      border: "2px solid",
      borderColor: theme?.palette?.secondary?.btnPrimary,
      color: theme?.palette?.secondary?.btnPrimary,
      background: "transparent",
    },
  },
};
