import { Box, Skeleton, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import SkeletonComponent from "../skeleton";
import { theme } from "../../constant/Theme";

const SearchInput = ({ setSearchText, searchText }) => {
  // const [loading, setLoading] = useState(false);
  const debouncedSearchText = useDebounce({ value: searchText });

  // useEffect(() => {
  //   if (debouncedSearchText) {
  //     setLoading(true);
  //     // Simulate a data fetch
  //     setTimeout(() => {
  //       setLoading(false);
  //       // Fetch data based on debouncedSearchText here
  //     }, 1000); // Simulate fetch delay
  //   }
  // }, [debouncedSearchText]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center",
      }}
    >
      <TextField
        sx={{
          color: theme?.palette?.primary?.placeholderColor,
          borderColor: theme?.palette?.secondary?.inputColor,

          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme?.palette?.secondary?.inputColor,
          },
          "&:hover   .MuiOutlinedInput-notchedOutline": {
            borderColor: theme?.palette?.secondary?.inputColor,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme?.palette?.secondary?.inputColor, // Focus border color
          },

          "& .MuiInputLabel-outlined": {
            borderColor: theme?.palette?.secondary?.inputColor, // Focus border color
          },
        }}
        label=""
        variant="outlined"
        value={searchText}
        onChange={(event) => setSearchText(event.target.value)}
        InputLabelProps={{
          style: {
            color: theme?.palette?.primary?.placeholderColor,
            fontFamily: theme?.typography?.fontFamily,
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
          },
        }}
        InputProps={{
          style: { color: theme?.palette?.primary?.placeholderColor, height: "40px" },
        }}
      />

      <Typography variant="p" sx={{ display: { xs: "none", sm: "block" }, color: theme?.palette?.primary?.main ,fontFamily: theme?.typography?.fontFamily,}}>
        Search:&nbsp;&nbsp;
      </Typography>

      {/* {debouncedSearchText && <SkeletonComponent />} */}
    </Box>
  );
};

export default SearchInput;
