import { useFormik } from "formik";
import { theme } from "../../constant/Theme";

// Define a function that returns the styles based on formik props
export const getInputCourtStyle = {
  courtName: (formik) => ({
    width: "100%",
    fontFamily: theme?.typography?.fontFamily,
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      color: "#A6A7C1", // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.name && Boolean(formik.errors.name) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.name && Boolean(formik.errors.name) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Yellow border color on focus
      },
    },
  }),
  inputText: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      color: theme?.palette?.primary?.placeholderColor, // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor, // Yellow border color on focus
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Yellow border color on focus
      },
      ...theme?.typography?.inputPlaceHolder,
    },
    "& .MuiSvgIcon-root": {
      color: theme?.palette?.primary?.placeholderColor,
    },
  },
  viewPaper: {
    width: "100%",
    backgroundColor: theme?.palette?.background?.paper,
    color: theme?.palette?.secondary?.main,
    padding: "20px",
  },
  viewHeading: {
    fontSize: "16px",
    lineHeight: "24px",
    color: theme?.palette?.primary?.main,
    fontFamily: theme?.typography?.fontFamily,
    fontWeight: "500",
  },
  viewSubHeading: {
    fontSize: "14px",
    lineHeight: "19.8px",
    color: theme?.palette?.primary?.placeholderColor,
    fontFamily: theme?.typography?.fontFamily,
  },
  backBtn: {
    // padding: { xs: "10px 25px", xl: "20px 50px" },
    color: theme?.palette?.secondary?.btnPrimary,
    fontSize: { xs: "10px", xl: "15px" },
    borderColor: "white",
    border: "2px solid",
    height:'50px',
    width:'160px',
    borderRadius: "4px",
    background: "transparent",
    "&:hover": {
      border: "2px solid",
      borderColor: theme?.palette?.secondary?.btnPrimary,
      color: theme?.palette?.secondary?.btnPrimary,
      background: "transparent",
    },
  },
  submit: {
    width: { xs: "100%", sm: "150px" },
    height: "45px",
    bottom: "0px",
    mt: { xs: 1, sm: 1.5 },
    mb: { xs: 0, sm: 2 },
    color: theme?.palette?.secondary?.btnPrimary,
    backgroundColor: theme?.palette?.secondary?.btnPrimary,
    fontSize: "12px",
    borderColor: theme?.palette?.secondary?.btnPrimary,

    boxShadow: 0,
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: theme?.palette?.secondary?.btnPrimary,

      borderColor: theme?.palette?.secondary?.btnPrimary,
      color: "#FFFFFF",
      boxShadow: 0,
    },
    color: "#FFFFFF",
    fontFamily: theme?.typography?.fontFamily,
    ...theme?.typography?.smallText,
  },
  cancel: {
    width: { xs: "100%", sm: "120px" },
    bottom: "0px",
    mt: { xs: 1, sm: 1.5 },
    mb: { xs: 0, sm: 2 },
    color: "#686DFC",
    fontSize: "12px",
    borderColor: "white",
    border: "2px solid",
    backgroundColor: "transparent",
    "&:hover": {
      border: "2px solid",
      borderColor: "rgba(104, 109, 252, 0.7)",
      color: "rgba(104, 109, 252, 0.7)",
      backgroundColor: "#686DFC",
      color: "white",
    },
    "&.Mui-disabled": {
      border: "2px solid",
      borderColor: "rgba(104, 109, 252, 0.7)",
      color: "rgba(104, 109, 252, 0.7)",
    },
    fontFamily: theme?.typography?.fontFamily,
  },
};
