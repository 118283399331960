import React from "react";
import { Box, CssBaseline, Grid, Typography } from "@mui/material";

import { theme } from "../../constant/Theme";
import { PaperComponent } from "../../Components/Card/SettingCard";
import { ButtonUIPrevious } from "../../Components/Button";
import { useNavigate } from "react-router-dom";
import CommingSoonIcon from "../../Components/Icons/CommingSoon";
const CommingSoon = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* <MainLayout> */}
      <Grid item xs={12}>
        <PaperComponent>
          <Box
            sx={{
              minHeight: "100vh",
              maxHeight: { xs: 2000, sm: 2000 },
              position: "relative",
            }}
          >
            <CssBaseline />
            {/* here is heading  */}
            <Grid container spacing={2} style={{ marginTop: "40px" }}>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h5" typography={theme?.typography?.fontFamily} color={theme?.palette?.primary?.btnPrimary} sx={{ ...theme?.typography?.title }}>
                  We are Launching Soon{" "}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="p" typography={theme?.typography?.fontFamily} color={theme?.palette?.primary?.placeholderColor} sx={{ ...theme?.typography?.placeHolder }}>
                  Our Website is opening soon. Please check it back later{" "}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ButtonUIPrevious text="Back to home" onClick={() => navigate("/")} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CommingSoonIcon />
              </Grid>
            </Grid>
          </Box>
        </PaperComponent>
      </Grid>
      {/* </MainLayout> */}
    </>
  );
};

export default CommingSoon;
