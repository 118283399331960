import axios from "axios";
let accessToken=""
// Chatbot service functions
export const postChatbot = async (url, data, config = {}) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_CHATBOT_URL}${url}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error in postChatbot:", error);
    throw error;
  }
};

export const getChatbot = async (url, config = {}) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_CHATBOT_URL}${url}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error in getChatbot:", error);
    throw error;
  }
};

// Other service functions
export const postOtherService = async (url, data, config = {}) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_OTHER_SERVICE_URL}${url}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error in postOtherService:", error);
    throw error;
  }
};

export const getOtherService = async (url, config = {}) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_OTHER_SERVICE_URL}${url}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error in getOtherService:", error);
    throw error;
  }
};


// Example function to fetch a token and use it in a request
export const fetchTokenAndPostChatbot = async (url, data, config = {}) => {
  console.log("data in token", url, data, config)
    try {
      // Fetch token
      const tokenResponse = await axios.post(`${process.env.REACT_APP_BACKEND_CHATBOT_URL}/depositions/token`);
  
      // Debugging the full response object
      console.log('Full token response:', tokenResponse);
  
      // Check if the data and access_token fields are present
      if (tokenResponse && tokenResponse.data && tokenResponse.data.access_token) {
         accessToken = tokenResponse.data.access_token;
        console.log('Access Token:', accessToken);
  
        // Include token in the header
        const authConfig = {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
          },
        };
  
        // Perform the post request with the token
        return await axios.post(`${process.env.REACT_APP_BACKEND_CHATBOT_URL}${url}`, data, authConfig);
      } else {
        console.error('Error: Access token not found in response');
        throw new Error('Access token not found in response');
      }
    } catch (error) {
      console.error('Error in fetchTokenAndPostChatbot:', error);
      throw error;
    }
  };

//  function to fetch a deposition and use it in a request


export const fetchDepositionData = async (url, data, config = {}) => {
  console.log("Fetching deposition data:", { url, data, config });

  try {
    const authConfig = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json", // Ensure content type is set to JSON

      },
    };
    console.log("Auth Config:", authConfig);

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_CHATBOT_URL}/depositions/facts`,
      data,
      authConfig
    );

    console.log("Full deposition response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error in fetch deposition:", error.message, error.response);
    if (error.response) {
      console.error("Response Data:", error.response.data);
      console.error("Status Code:", error.response.status);
    }
    throw error;
  }
};









  export const fetchTokenAndDeleteEmbedding = async (url, data, config = {}) => {
    try {
      // Fetch token
      const tokenResponse = await axios.post(`${process.env.REACT_APP_BACKEND_CHATBOT_URL}/depositions/token`);
  
      // Debugging the full response object
      console.log('Full token response:', tokenResponse);
  
      // Check if the data and access_token fields are present
      if (tokenResponse && tokenResponse.data && tokenResponse.data.access_token) {
        const accessToken = tokenResponse.data.access_token;
        console.log('Access Token:', accessToken);
  
        // Include token in the header
        const authConfig = {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
          },
        };
  
        // Perform the post request with the token
        return await axios.post(`${process.env.REACT_APP_BACKEND_CHATBOT_URL}${url}`, data, authConfig);
      } else {
        console.error('Error: Access token not found in response');
        throw new Error('Access token not found in response');
      }
    } catch (error) {
      console.error('Error in fetchTokenAndPostChatbot:', error);
      throw error;
    }
  };