import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Tabs, Tooltip, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import GetAppIcon from "@mui/icons-material/GetApp";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import useFileActions from "../../hooks/useFileActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useStaticFormattedDate from "../../hooks/useStaticDate";
import { theme } from "../../constant/Theme";
import { inputIssueStyles } from "./style";
import LoaderIcon from "../../Components/Icons/LoaderIcon";
import { PaperComponent } from "../../Components/Card/SettingCard";
import { BackBtn } from "../../Components/Button";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ViewDetailJurisprudence = () => {
  const location = useLocation();
  const navigator = useNavigate();
  const { viewRecord } = location.state || {};
  let CreatedDate = "";
  if (viewRecord?.createdAt) {
    const year = new Date(viewRecord?.createdAt).getFullYear();
    const month = new Date(viewRecord?.createdAt).toLocaleString("en-US", {
      month: "long",
    });
    const day = new Date(viewRecord?.createdAt).getDate();
    CreatedDate = `${day ?? ""} ${month ?? ""} ${year ?? ""}`;
  }
  const getFileName = (filePath) => {
    if (typeof filePath !== "string" || !filePath.includes("_")) {
      return filePath; // Return the original filePath if it's not a string or doesn't contain an underscore
    }
    const parts = filePath.split("_");
    return parts.length > 1 ? parts.slice(1).join("_") : filePath;
  };

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { handleFileAction, downloadLoader } = useFileActions();
  const { date } = useStaticFormattedDate(viewRecord?.createdAt);
  const [value, setValue] = React.useState(0);

  const handleChanges = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PaperComponent>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: theme?.palette?.background?.paper,
                  color: theme?.palette?.secondary?.main,
                  padding: "20px",
                  borderRadius: "8px",
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      component="div"
                      color={theme?.palette?.primary?.secondaryheading}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color: theme?.palette?.primary?.main,
                      }}
                    >
                      {/* , fontWeight: '600', lineHeight: '33.6px', fontSize: '28px' */}
                      <Typography
                        sx={{
                          // fontWeight: "600",
                          lineHeight: "33.6px",
                          fontSize: "28px !important",
                          fontFamily: theme?.typography?.fontFamily,
                        }}
                      >
                        {" "}
                        Jurisprudence Details
                      </Typography>
                      <BackBtn title={"back"} link={"/Jurisprudence"} />
                    </Box>
                  </Grid>
                </Grid>

                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  ></Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                  <Grid item xs={12} sm={12}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputIssueStyles?.viewHeading}>Jurisprudence Name</Typography>
                      <Typography sx={inputIssueStyles?.viewSummaryHeading}>{viewRecord?.name}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputIssueStyles?.viewHeading}>Summary of Law</Typography>
                      <Typography sx={inputIssueStyles?.viewSummaryHeading}>{viewRecord?.summaryOfLaw}</Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Grid>
                  <Accordion
                    // expanded={expanded === val.source + i}
                    // onChange={handleExpansion(val.source + i)}

                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                    sx={[
                      {
                        backgroundColor: theme?.palette?.primary?.sidebar,
                        boxShadow: "none", // Remove the box shadow
                        // borderBottom: "1px solid #4A4C64",
                        borderRadius: "4px",
                        border: "1px solid #E6E7F2",
                        "&:before": {
                          display: "none",
                        },
                      },
                    ]}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id={`panelheader`}
                      sx={{
                        backgroundColor: theme?.palette?.primary?.sidebar,

                        "& .MuiSvgIcon-root": {
                          color: theme?.palette?.primary?.placeholderColor,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between", // Ensures content is spaced out
                          width: "100%", // Occupies full width
                        }}
                      >
                        <Typography color={theme?.palette?.primary?.main} sx={{ fontFamily: theme?.typography?.fontFamily }}>
                          Case 1
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box>
                            <Typography
                              onClick={() => handleFileAction(`issues/${viewRecord._id}?file=1`, "view")}
                              target="_blank"
                              sx={{
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                                cursor: "pointer",
                                color: "#686DFC",
                              }}
                            >
                              <RemoveRedEyeIcon sx={{ width: "20px", color: "#2B2C40" }} />
                            </Typography>
                          </Box>
                          {`${viewRecord?._id}file=1` === downloadLoader ? (
                            <Box sx={{ marginLeft: "11px" }}>
                              {" "}
                              <LoaderIcon color={theme?.palette?.primary?.main} width={20} height={24} />
                            </Box>
                          ) : (
                            <Typography
                              sx={{ cursor: "pointer", marginLeft: "11px" }}
                              onClick={() => handleFileAction(`issues/${viewRecord?._id}?file=1`, "download", `${viewRecord?._id}file=1`, `${viewRecord?.name}-file1`)}
                            >
                              <GetAppIcon sx={{ width: "20px", color: "#2B2C40" }} />
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </AccordionSummary>

                    <AccordionDetails
                      sx={{
                        background: expanded ? "#FFFFF" : "#FFFFFF",
                        color: "#F2F3FB",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          height: "100vh", // Full height
                          bgcolor: "background.default",
                        }}
                      >
                        {/* Sidebar */}
                        <Box
                          sx={{
                            width: "250px", // Fixed sidebar width
                            minWidth: "250px", // Ensures it doesn't shrink
                            // bgcolor: "background.paper",
                            borderRight: 1,
                            borderColor: "divider",
                          }}
                        >
                          <Tabs
                            orientation="vertical"
                            value={value}
                            onChange={handleChanges}
                            aria-label="Sidebar Tabs"
                            sx={{
                              "& .MuiTab-root": {
                                fontSize: "18px",
                                fontWeight: 400,
                                lineHeight: "24px",
                                textTransform: "none",
                                color: "#505058",
                                marginLeft: "20px",
                                marginRight: "20px",
                                borderRadius: "4px",
                                marginTop: "10px",
                              },
                              "& .Mui-selected": {
                                bgcolor: "#387CFF", // Blue background for active
                                color: "white !important", // White text for active tab
                              },
                            }}
                          >
                            <Tab label="Citation" />
                            <Tab label="Facts" />
                            <Tab label="Law" />
                            <Tab label="Ruling" />
                          </Tabs>
                        </Box>

                        {/* Content Area */}
                        <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
                          <CustomTabPanel value={value} index={0}>
                            <Typography variant="h5" sx={inputIssueStyles?.viewNewHeading}>
                              Citation
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Typography sx={inputIssueStyles?.viewSubHeading}>{viewRecord?.caseOneCitation}</Typography>
                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={1}>
                            <Typography variant="h5" sx={inputIssueStyles?.viewNewHeading}>
                              Facts
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Typography sx={inputIssueStyles?.viewSubHeading}>{viewRecord?.factOne}</Typography>
                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={2}>
                            <Typography variant="h5" sx={inputIssueStyles?.viewNewHeading}>
                              Law
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Typography sx={inputIssueStyles?.viewSubHeading}>{viewRecord?.lawOne}</Typography>
                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={3}>
                            <Typography variant="h5" sx={inputIssueStyles?.viewNewHeading}>
                              Ruling
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Typography color={theme?.palette?.primary?.main} sx={inputIssueStyles?.viewSubHeading}>
                              {viewRecord?.rullingOne}
                            </Typography>
                          </CustomTabPanel>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    // expanded={expanded === val.source + i}
                    // onChange={handleExpansion(val.source + i)}

                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                    sx={[
                      {
                        margin: "10px 0",
                        backgroundColor: theme?.palette?.primary?.sidebar,
                        boxShadow: "none", // Remove the box shadow
                        // borderBottom: "1px solid #4A4C64",
                        borderRadius: "4px",
                        border: "1px solid #E6E7F2",
                        "&:before": {
                          display: "none",
                        },
                      },
                    ]}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id={`panelheader`}
                      sx={{
                        backgroundColor: theme?.palette?.primary?.sidebar,

                        "& .MuiSvgIcon-root": {
                          color: theme?.palette?.primary?.placeholderColor,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between", // Ensures content is spaced out
                          width: "100%", // Occupies full width
                        }}
                      >
                        <Typography color={theme?.palette?.primary?.main} sx={{ fontFamily: theme?.typography?.fontFamily }}>
                          Case 2
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box>
                            <Typography
                              onClick={() => handleFileAction(`issues/${viewRecord._id}?file=2`, "view")}
                              target="_blank"
                              sx={{
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                                cursor: "pointer",
                                color: "#686DFC",
                              }}
                            >
                              <RemoveRedEyeIcon sx={{ width: "20px", color: "#2B2C40" }} />
                            </Typography>
                          </Box>
                          {`${viewRecord?._id}file=2` === downloadLoader ? (
                            <Box sx={{ marginLeft: "11px" }}>
                              {" "}
                              <LoaderIcon color={theme?.palette?.primary?.main} width={20} height={24} />
                            </Box>
                          ) : (
                            <Typography
                              sx={{ cursor: "pointer", marginLeft: "11px" }}
                              onClick={() => handleFileAction(`issues/${viewRecord?._id}?file=2`, "download", `${viewRecord?._id}file=2`, `${viewRecord?.name}-file2`)}
                            >
                              <GetAppIcon sx={{ width: "20px", color: "#2B2C40" }} />
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </AccordionSummary>

                    <AccordionDetails
                      sx={{
                        background: expanded ? "#FFFFF" : "#FFFFFF",
                        color: "#F2F3FB",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          height: "100vh", // Full height
                          bgcolor: "background.default",
                        }}
                      >
                        {/* Sidebar */}
                        <Box
                          sx={{
                            width: "250px", // Fixed sidebar width
                            minWidth: "250px", // Ensures it doesn't shrink
                            // bgcolor: "background.paper",
                            borderRight: 1,
                            borderColor: "divider",
                          }}
                        >
                          <Tabs
                            orientation="vertical"
                            value={value}
                            onChange={handleChanges}
                            aria-label="Sidebar Tabs"
                            sx={{
                              "& .MuiTab-root": {
                                fontSize: "18px",
                                fontWeight: 400,
                                lineHeight: "24px",
                                textTransform: "none",
                                color: "#505058",
                                marginLeft: "20px",
                                marginRight: "20px",
                                borderRadius: "4px",
                                marginTop: "10px",
                              },
                              "& .Mui-selected": {
                                bgcolor: "#387CFF", // Blue background for active
                                color: "white !important", // White text for active tab
                              },
                            }}
                          >
                            <Tab label="Citation" />
                            <Tab label="Facts" />
                            <Tab label="Law" />
                            <Tab label="Ruling" />
                          </Tabs>
                        </Box>

                        {/* Content Area */}
                        <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
                          <CustomTabPanel value={value} index={0}>
                            <Typography variant="h5" sx={inputIssueStyles?.viewNewHeading}>
                              Citation
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Typography sx={inputIssueStyles?.viewSubHeading}>{viewRecord?.caseTwoCitation ? viewRecord?.caseOneCitation : "N/A"}</Typography>
                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={1}>
                            <Typography variant="h5" sx={inputIssueStyles?.viewNewHeading}>
                              Facts
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Typography sx={inputIssueStyles?.viewSubHeading}>{viewRecord?.factTwo ? viewRecord?.factTwo : "N/A"}</Typography>
                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={2}>
                            <Typography variant="h5" sx={inputIssueStyles?.viewNewHeading}>
                              Law
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Typography sx={inputIssueStyles?.viewSubHeading}>{viewRecord?.lawTwo ? viewRecord?.lawTwo : "N/A"}</Typography>
                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={3}>
                            <Typography variant="h5" sx={inputIssueStyles?.viewNewHeading}>
                              Ruling
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Typography color={theme?.palette?.primary?.main} sx={inputIssueStyles?.viewSubHeading}>
                              {viewRecord?.rullingTwo ? viewRecord?.rullingTwo : "N/A"}
                            </Typography>
                          </CustomTabPanel>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    // expanded={expanded === val.source + i}
                    // onChange={handleExpansion(val.source + i)}

                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                    sx={[
                      {
                        margin: "10px 0",
                        backgroundColor: theme?.palette?.primary?.sidebar,
                        boxShadow: "none", // Remove the box shadow
                        // borderBottom: "1px solid #4A4C64",
                        borderRadius: "4px",
                        border: "1px solid #E6E7F2",
                        "&:before": {
                          display: "none",
                        },
                      },
                    ]}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id={`panelheader`}
                      sx={{
                        backgroundColor: theme?.palette?.primary?.sidebar,

                        "& .MuiSvgIcon-root": {
                          color: theme?.palette?.primary?.placeholderColor,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between", // Ensures content is spaced out
                          width: "100%", // Occupies full width
                        }}
                      >
                        <Typography color={theme?.palette?.primary?.main} sx={{ fontFamily: theme?.typography?.fontFamily }}>
                          Case 3
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box>
                            <Typography
                              onClick={() => handleFileAction(`issues/${viewRecord._id}?file=3`, "view")}
                              target="_blank"
                              sx={{
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                                cursor: "pointer",
                                color: "#686DFC",
                              }}
                            >
                              <RemoveRedEyeIcon sx={{ width: "20px", color: "#2B2C40" }} />
                            </Typography>
                          </Box>
                          {`${viewRecord?._id}file=3` === downloadLoader ? (
                            <Box sx={{ marginLeft: "11px" }}>
                              {" "}
                              <LoaderIcon color={theme?.palette?.primary?.main} width={20} height={24} />
                            </Box>
                          ) : (
                            <Typography
                              sx={{ cursor: "pointer", marginLeft: "11px" }}
                              onClick={() => handleFileAction(`issues/${viewRecord?._id}?file=3`, "download", `${viewRecord?._id}file=3`, `${viewRecord?.name}-file3`)}
                            >
                              <GetAppIcon sx={{ width: "20px", color: "#2B2C40" }} />
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </AccordionSummary>

                    <AccordionDetails
                      sx={{
                        background: expanded ? "#FFFFF" : "#FFFFFF",
                        color: "#F2F3FB",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          height: "100vh", // Full height
                          bgcolor: "background.default",
                        }}
                      >
                        {/* Sidebar */}
                        <Box
                          sx={{
                            width: "250px", // Fixed sidebar width
                            minWidth: "250px", // Ensures it doesn't shrink
                            // bgcolor: "background.paper",
                            borderRight: 1,
                            borderColor: "divider",
                          }}
                        >
                          <Tabs
                            orientation="vertical"
                            value={value}
                            onChange={handleChanges}
                            aria-label="Sidebar Tabs"
                            sx={{
                              "& .MuiTab-root": {
                                fontSize: "18px",
                                fontWeight: 400,
                                lineHeight: "24px",
                                textTransform: "none",
                                color: "#505058",
                                marginLeft: "20px",
                                marginRight: "20px",
                                borderRadius: "4px",
                                marginTop: "10px",
                              },
                              "& .Mui-selected": {
                                bgcolor: "#387CFF", // Blue background for active
                                color: "white !important", // White text for active tab
                              },
                            }}
                          >
                            <Tab label="Citation" />
                            <Tab label="Facts" />
                            <Tab label="Law" />
                            <Tab label="Ruling" />
                          </Tabs>
                        </Box>

                        {/* Content Area */}
                        <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
                          <CustomTabPanel value={value} index={0}>
                            <Typography variant="h5" sx={inputIssueStyles?.viewNewHeading}>
                              Citation
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Typography sx={inputIssueStyles?.viewSubHeading}>{viewRecord?.caseThreeCitation ? viewRecord?.caseThreeCitation : "N/A"}</Typography>
                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={1}>
                            <Typography variant="h5" sx={inputIssueStyles?.viewNewHeading}>
                              Facts
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Typography sx={inputIssueStyles?.viewSubHeading}>{viewRecord?.factThree ? viewRecord?.factThree : "N/A"}</Typography>
                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={2}>
                            <Typography variant="h5" sx={inputIssueStyles?.viewNewHeading}>
                              Law
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Typography sx={inputIssueStyles?.viewSubHeading}>{viewRecord?.lawThree ? viewRecord?.lawThree : "N/A"}</Typography>
                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={3}>
                            <Typography variant="h5" sx={inputIssueStyles?.viewNewHeading}>
                              Ruling
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Typography color={theme?.palette?.primary?.main} sx={inputIssueStyles?.viewSubHeading}>
                              {viewRecord?.rullingThree ? viewRecord?.rullingThree : "N/A"}
                            </Typography>
                          </CustomTabPanel>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Box>
            </PaperComponent>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default ViewDetailJurisprudence;
