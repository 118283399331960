import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import useStaticFormattedDate from "../../hooks/useStaticDate";
import { getInputCourtStyle } from "./style";
import { theme } from "../../constant/Theme";
import { PaperComponent } from "../../Components/Card/SettingCard";
import { BackBtn } from "../../Components/Button";

const ViewPaymentRecord = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { viewRecord } = location.state || {};

  //   let CreatedDate = "";
  //   if (viewRecord?.createdAt) {
  //     const year = new Date(viewRecord?.createdAt)?.getFullYear();
  //     const month = new Date(viewRecord?.createdAt)?.toLocaleString("en-US", {
  //       month: "long",
  //     });
  //     const day = new Date(viewRecord?.createdAt)?.getDate();
  //     CreatedDate = `${day ?? ""} ${month ?? ""} ${year ?? ""}`;
  //   }
  //   const { date } = useStaticFormattedDate(viewRecord?.createdAt);
  return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PaperComponent>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: theme?.palette?.primary?.bodyColor,
                  color: theme?.palette?.secondary?.main,
                  padding: "20px",
                  borderRadius: "8px",
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color: theme?.palette?.primary?.main,
                      }}
                    >
                      {/* , fontWeight: '600', lineHeight: '33.6px', fontSize: '28px' */}
                      <Typography
                        sx={{
                          fontWeight: "600",
                          lineHeight: "33.6px",
                          fontSize: "28px !important",
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                        Payment Details
                      </Typography>

                      <BackBtn title={"back"} link={"/"} />
                    </Box>
                  </Grid>
                </Grid>
                {/* here is defin it  */}
                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    {/* <Box>
                      <Typography
                        sx={{
                          color: "#A6A7C1 !important",
                          fontSize: "12px",
                          lineHeight: "24px",
                        }}
                      >
                        Date Created
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          lineHeight: "19.8px",
                          color: "#E6E7F2",
                        }}
                      >
                        {date}
                      </Typography>
                    </Box> */}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={getInputCourtStyle?.viewHeading}>
                        Currency
                      </Typography>
                      <Typography sx={getInputCourtStyle?.viewSubHeading}>
                        {viewRecord?.currency?.toUpperCase()}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {" "}
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={getInputCourtStyle?.viewHeading}>
                        Payment Method
                      </Typography>
                      <Typography sx={getInputCourtStyle?.viewSubHeading}>
                        {viewRecord?.paymentMethod
                          ? viewRecord?.paymentMethod
                          : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {" "}
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={getInputCourtStyle?.viewHeading}>
                        Quantity
                      </Typography>
                      <Typography sx={getInputCourtStyle?.viewSubHeading}>
                        {viewRecord?.quantity ? viewRecord?.quantity : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={getInputCourtStyle?.viewHeading}>
                        Seat Type
                      </Typography>
                      <Typography sx={getInputCourtStyle?.viewSubHeading}>
                        {viewRecord?.seatType ? viewRecord?.seatType : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={getInputCourtStyle?.viewHeading}>
                        Total Amount
                      </Typography>
                      <Typography sx={getInputCourtStyle?.viewSubHeading}>
                        {viewRecord?.totalAmount
                          ? viewRecord?.totalAmount
                          : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </PaperComponent>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default ViewPaymentRecord;
