import { InputBase } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { theme } from "../../constant/Theme";

export const styles = {
  input: {
    color: theme?.palette?.primary?.placeholderColor, // Set text color
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",

      color: theme?.palette?.primary?.placeholderColor, // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor,
      },
    },
    "& .MuiSvgIcon-root": {
      color: theme?.palette?.primary?.placeholderColor, // Set text color
    },
  },
  inpuDisable: {
    color: theme?.palette?.secondary?.inputColor,

    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      color: "#A6A7C1", // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#A6A7C1",
      },
    },
    "& .MuiSvgIcon-root": {
      color: "#C8C9DB",
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#A6A7C1",
      },
    },
  },
  paper: {
    backgroundColor: "#323249",
    color: "#C8C9DB",
    // height: "400px",
    "& .MuiMenuItem-root": {
      "&:hover": {
        backgroundColor: "#686DFC26", // Hover background color
        color: "#686DFC", // Hover text color
      },
    },
  },
  input2: {
    width: "100%",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      color: "#A6A7C1", // Set text color
      backgroundColor: "#2B2C40",

      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#C8C9DB",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#A6A7C1", // Yellow border color on focus
      },
    },
  },
};

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  // backgroundColor: alpha("#2B2C40", 0.15),
  backgroundColor: alpha("#242333", 0.5),
  border: "1px solid #FFFFFF",
  "&:hover": {
    backgroundColor: alpha("#242333", 0.5),
  },
  // marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    // marginLeft: theme.spacing(1),
    width: "auto",
  },
  borderRadius: "500px",
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "white",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
