import { Box, Button, Grid, Modal, Typography, Backdrop } from "@mui/material";
import React from "react";
import { theme } from "../../../constant/Theme";
import { FilterCancelBtn, FilterSubmitBtn } from "../../Button";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: { xs: "90%", sm: "auto" },
  width: { xs: "90%", sm: "500px" }, // Set a fixed width
  maxWidth: "100%", // Ensure it does not exceed screen size
  bgcolor: "background.paper",
  // boxShadow: 24,
  p: 4,
  height: "auto",
  backgroundColor: theme?.palette?.primary?.bodyColor,
  border: "1px solid #FFFFFF",
  borderRadius: "4px",
};

const StatusUpdate = ({ open, onClose, onconfirm }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        style: {
          //  backgroundColor: "transparent",
          opacity: "0.3",
        },
      }}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" color={theme?.palette?.primary?.main} sx={{ ...theme?.typography?.title, fontFamily: theme?.typography?.fontFamily }}>
          Status confirmation
        </Typography>
        <Typography id="modal-modal-description" color={theme?.palette?.primary?.placeholderColor} sx={{ mt: 2 }}>
          Are you sure you want to change the status?{" "}
        </Typography>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          > 
            <FilterCancelBtn onClick={onClose} text={"Cancel"} />
            <FilterSubmitBtn title={"Confirm"} onClick={onconfirm} />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default StatusUpdate;
