import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { useFormik } from "formik";
import QueriesForm from "../../Components/Forms/QueriesForm";
import HistoryForm from "../../Components/Forms/QueriesForm/HistoryForm";
import QuerieEditIcon from "../../Components/Icons/QuerieEditIcon";
import QueriesidebarIcon from "../../Components/Icons/QueriesidebarIcon";
import QuerieDropdwon from "../../Components/Forms/QueriesForm/QuerieDropdown";
import { useMultiFileUpload } from "../../context/useMultiFileUploade";
import { theme } from "../../constant/Theme";
const QueriesPage = () => {
  const { chatHistory, setChatHistory, setAllChat, matter } =
    useMultiFileUpload();
  const formik = useFormik({
    initialValues: {
      category: chatHistory?.moduleName || "",
      items: chatHistory?.files || [],
      caseId: chatHistory?.matter_id || [matter?._id] || [],
      chatId: "",
      maxTokens: chatHistory?.max_tokens || "1000",
      sources: chatHistory?.source_number ?? 4,
      personsId: chatHistory?.person_id || "",
      type: chatHistory?.type || "",
      fillingParty: chatHistory?.person_id || "",
      role: "",
      roleId: "",
      showFilter: true,
      exhibitListing:
        chatHistory?.is_trial !== undefined
          ? chatHistory?.is_trial
            ? { name: "Trial Exhibit List", is_trial: true }
            : { name: "All Exhibit List", is_trial: false }
          : "",
    },
    onSubmit: async (values, { resetForm }) => {},
    enableReinitialize: true,
  });

  useEffect(() => {
    if (chatHistory) {
      // Perform any action you need when chatHistory changes
      formik.setValues({
        category: chatHistory?.moduleName || "",
        items: chatHistory?.files || [],
        caseId: chatHistory?.matter_id || "",
        chatId: "",
        maxTokens: chatHistory?.max_tokens || "",
        sources: chatHistory?.source_number ?? 4,
        personsId: chatHistory?.person_id || "",
        type: chatHistory?.type || "",
        fillingParty: chatHistory?.person_id || "",
        role: "",
        roleId: "",
        showFilter: true,
        exhibitListing:
          chatHistory?.is_trial !== undefined
            ? chatHistory?.is_trial
              ? { name: "Trial Exhibit List", is_trial: true }
              : { name: "All Exhibit List", is_trial: false }
            : "",
      });
    }
  }, [chatHistory]);
  // State to manage the visibility of HistoryForm
  const [isHistoryFormVisible, setIsHistoryFormVisible] = useState(true);

  return (
    <MainLayout>
      <Grid item xs={12}>
        {/* First section with 20% height */}
        <Grid container spacing={2}>
          <QuerieDropdwon formik={formik} chatHistory={chatHistory} />
        </Grid>
        <Box
          sx={{
            outline: `1px solid ${theme?.palette?.primary?.tertiaryHeading}`,
            borderRadius: "8px",
            mt: 2,
          }}
        >
          <Grid container>
            <Grid
              item
              // xs={3}
              sx={{
                width: "22%",
                borderRight: isHistoryFormVisible
                  ? `1px solid ${theme?.palette?.primary?.backgroundSidebar}`
                  : "",
                backgroundColor: isHistoryFormVisible
                  ? theme?.palette?.background?.paper
                  : "#242333",
                color: "white",
                borderRadius: "8px",
              }}
            >
              <Paper
                sx={{
                  p: "15px",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: theme?.palette?.primary?.sidebar,
                  color: "white",
                  justifyContent: "center",
                  borderRadius: 0,
                  height: "100%",
                  boxShadow: 0,
                  borderRight: "8px",
                  // borderTopLeftRadiusRadius: "6px",
                  borderTopLeftRadius: "6px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: isHistoryFormVisible
                      ? "space-between"
                      : "start",
                    gap: isHistoryFormVisible ? "0" : "10px",
                  }}
                >
                  <Tooltip
                    title={
                      isHistoryFormVisible ? "Close sidebar" : "Open sidebar"
                    }
                    placement="bottom"
                    arrow
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() =>
                        setIsHistoryFormVisible((prevState) => !prevState)
                      }
                    >
                      <QueriesidebarIcon />
                    </Box>
                  </Tooltip>
                  <Tooltip title="New chat" placement="bottom" arrow>
                    <Box
                      onClick={() => {
                        setChatHistory(null);
                        setAllChat([]);
                      }}
                      sx={{
                        display: "flex",
                        cursor: "pointer",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <QuerieEditIcon />
                    </Box>
                  </Tooltip>
                </Box>
              </Paper>
            </Grid>

            <Grid item sx={{ width: "78%" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  color: theme?.palette?.primary?.main,
                  background: theme?.palette?.primary?.sidebar,
                  borderBottom: `1px solid ${theme?.palette?.primary?.backgroundSidebar}`,
                  height: "100%", // Ensures full height in this section
                  borderTopRightRadius: "8px",
                }}
              >
                <Box
                  component="img"
                  src="/images/logoD.png"
                  alt="logo"
                  sx={{
                    width: "55px",
                    height: "auto",
                    p: 1,
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: theme?.typography?.fontFamily,
                    ...theme?.typography?.inputPlaceHolder,
                  }}
                >
                  DocuLex AI Chatbot{" "}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {/* Third section with remaining height (70% of viewport) */}
          <Grid
            container
            direction={"column"}
            sx={(theme) => ({
              position: "relative",
              flex: "1",
              minHeight: "73vh",
              // overflow: "none",
            })}
          >
            <Grid
              item
              sx={{
                position: "absolute",
                left: isHistoryFormVisible ? 0 : "-25%",
                backgroundColor: theme?.palette?.primary?.sidebar,
                flex: 3,
                // overflow: "auto",
                transition: "left 0.5s ease, width 0.5s ease",
                borderRight: isHistoryFormVisible
                  ? `1px solid ${theme?.palette?.primary?.backgroundSidebar}`
                  : "none",
                height: "73vh",
                width: isHistoryFormVisible
                  ? { xs: "80%", sm: "25%", md: "25%", lg: "22%" }
                  : "0",
                // overflow: "auto",
                overflowY: "hidden",
                transition: "left 0.5s ease, width 0.5s ease",
              }}
            >
              <HistoryForm
                chatHistory={chatHistory}
                setChatHistory={setChatHistory}
                formik={formik}
              />
            </Grid>

            <Grid
              item
              sx={{
                width: isHistoryFormVisible ? "78%" : "100%",
                marginLeft: isHistoryFormVisible ? "22%" : "0",
                transition: "width 0.5s ease, margin-left 0.5s ease",
                flex: 3,
              }}
            >
              <QueriesForm
                chatValue={formik.values}
                chatHistory={chatHistory}
                // isLoading={isLoading}
                // data={allChat}
                setChatHistory={setChatHistory}
                isHistoryFormVisible={isHistoryFormVisible}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </MainLayout>
  );
};

export default QueriesPage;
