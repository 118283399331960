import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../constant/Theme";
import LabeledTextField, {
  StateSelect,
} from "../../../constant/ConstantInputField";
import { inputPersonStyles } from "../../../pages/Persons/style";
import { state } from "../../../constant/ConstantStateData";
const CompanyTabForm = ({ formik }) => {
  const [emailError, setEmailError] = useState();
  let stateNames = state;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <LabeledTextField
            formik={formik}
            name="companyName"
            label="Company Name"
            placeholder="Company Name"
            required={true}
            inputStyles={inputPersonStyles?.lastName(formik)} // Custom styles
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabeledTextField
            formik={formik}
            name="email"
            label="Email"
            placeholder="Email"
            error={emailError}
            helperText={emailError}
            inputStyles={inputPersonStyles?.email(formik)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabeledTextField
            formik={formik}
            name="phone"
            label="Phone No"
            placeholder="Phone No"
            inputStyles={inputPersonStyles?.phone(formik)} // Custom styles
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabeledTextField
            formik={formik}
            name="address"
            label="Street Address"
            placeholder="Street Address"
            sx={inputPersonStyles?.address(formik)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LabeledTextField
            formik={formik}
            name="city"
            label="City"
            placeholder="City"
            sx={inputPersonStyles?.city(formik)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            variant="body1"
            sx={{ color: theme?.palette?.primary?.main, mb: "3px" }} // Optional styles for the label
          >
            State
          </Typography>
          <StateSelect
            formik={formik} // Pass formik to handle form state and validation
            placeholder="Select State"
            options={stateNames} // State options array
            theme={theme} // Custom theme for styling
            inputPersonStyles={inputPersonStyles} // Custom input styles
            noOptionsText="No state found" // Custom message when no options are found
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LabeledTextField
            formik={formik}
            name="zipcode"
            label="Zip Code"
            placeholder="Zip Code"
            sx={inputPersonStyles?.zipCode(formik)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyTabForm;
