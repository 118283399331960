import { useFormik } from "formik";
import { theme } from "../../constant/Theme";

export const inputSettlementStyles = {
  cases: (formik) => ({
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      color: theme?.palette?.primary?.main,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor,
      },
    },
    "& .MuiSvgIcon-root": {
      color: theme?.palette?.primary?.placeholderColor,
    },
  }),
  document: (formik) => ({
    width: "100%",
    borderRadius: "5px",
    fontFamily: theme?.typography?.fontFamily,
    "& .MuiOutlinedInput-root": {
      color: theme?.palette?.primary?.main, // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.name && Boolean(formik.errors.name) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.name && Boolean(formik.errors.name) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Yellow border color on focus
      },
      "&.Mui-disabled": {
        backgroundColor: `${theme?.palette?.primary?.subSecondary} !important`, // Ensure red background for disabled state
        color: theme?.palette?.grey[500] + " !important", // Optional: text color in disabled state
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme?.palette?.secondary?.inputColor} !important`, // Red border color when disabled
        },
      },
    },
  }),
  Title: (formik) => ({
    width: "100%",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      color: theme?.palette?.primary?.placeholderColor, // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched?.title && Boolean(formik.errors?.title) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched?.title && Boolean(formik.errors?.title) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Yellow border color on focus
      },
    },
  }),

  cleintName: (formik) => ({
    width: "100%",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      color: theme?.palette?.primary?.main, // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik?.touched?.clientName && Boolean(formik?.errors?.clientName) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik?.touched?.clientName && Boolean(formik?.errors?.clientName) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Yellow border color on focus
      },
    },
    "& .MuiSvgIcon-root": {
      color: theme?.palette?.primary?.placeholderColor,
    },
  }),
  note: {
    width: "100%",
    fontFamily: theme?.typography?.fontFamily,
    mb: "20px",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.main, // Border color on hover
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Border color on focus
      },
      "& .MuiInputBase-input": {
        "&::-webkit-scrollbar": {
          display: "none",
        },
        msOverflowStyle: "none", // IE and Edge
      },
    },
  },
  Type: (formik) => ({
    "& .MuiOutlinedInput-root": {
      color: theme?.palette?.primary?.placeholderColor,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
        borderRadius: "4px",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderRadius: "4px",
        borderColor: theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.primary?.main,
      },
      ...theme?.typography?.inputPlaceHolder,
    },
    "& .MuiSvgIcon-root": {
      color: theme?.palette?.primary?.placeholderColor,
    },
  }),
  settlingParty: (formik) => ({
    "& .MuiOutlinedInput-root": {
      color: theme?.palette?.primary?.main,
      "& fieldset": {
        borderColor: formik.touched.settlingParty && Boolean(formik.errors.settlingParty) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor,
      },
      "&:hover fieldset": {
        borderColor: formik.touched.settlingParty && Boolean(formik.errors.settlingParty) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: formik.touched.settlingParty && Boolean(formik.errors.settlingParty) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor,
      },
      ...theme?.typography?.inputPlaceHolder,
    },
    "& .MuiSvgIcon-root": {
      color: theme?.palette?.primary?.placeholderColor,
    },
  }),
  note: {
    width: "100%",
    mb: "20px",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.main, // Border color on hover
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.primary?.main, // Yellow border color on focus
      },
      "& .MuiInputBase-input": {
        "&::-webkit-scrollbar": {
          display: "none",
        },
        msOverflowStyle: "none", // IE and Edge
        scrollbarWidth: "none", // Firefox
      },
    },
  },
  viewPaper: {
    width: "100%",
    backgroundColor: theme?.palette?.background?.paper,
    color: theme?.palette?.secondary?.main,
    padding: "20px",
  },
  viewHeading: {
    fontSize: "16px",
    lineHeight: "24px",
    color: theme?.palette?.primary?.main,
    fontFamily: theme?.typography?.fontFamily,
    fontWeight: "500",
  },
  viewSubHeading: {
    fontSize: "14px",
    lineHeight: "19.8px",
    color: theme?.palette?.primary?.placeholderColor,
    fontFamily: theme?.typography?.fontFamily,
  },
  backBtn: {
    padding: { xs: "10px 25px", xl: "20px 50px" },
    color: theme?.palette?.secondary?.btnPrimary,
    fontSize: { xs: "10px", xl: "15px" },
    borderColor: "white",
    border: "2px solid",
    borderRadius: "4px",
    background: "transparent",
    "&:hover": {
      border: "2px solid",
      borderColor: theme?.palette?.secondary?.btnPrimary,
      color: theme?.palette?.secondary?.btnPrimary,
      background: "transparent",
    },
  },
};
