import React from "react";

const CommingSoonIcon = () => {
  return (
    <div>
      <svg width="363" height="341" viewBox="0 0 363 341" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M170.934 48.4336V60.191" stroke="#8D43FE" stroke-width="1.20589" />
        <path d="M164.906 48.4336V60.191" stroke="#8D43FE" stroke-width="1.20589" />
        <path
          d="M175.467 300.524C188.6 298.252 201.488 288.16 207.222 280.264C213.999 270.93 219.203 257.224 222.161 246.198C216.112 249.815 200.965 252.73 194.148 253.735V260.97C169.985 266.758 146.698 263.382 138.074 260.97V253.735C125.292 252.046 112.667 248.007 107.952 246.198C109.454 252.227 111.506 257.895 113.853 262.779C120.415 276.433 129.496 287.454 135.84 292.021C148.74 301.308 165.889 302.181 175.467 300.524Z"
          fill="#8D43FE"
        />
        <path
          d="M142 179.874V181.683M189.934 179.874V181.683M189.934 181.683V187.109C186.316 202.484 157.874 200.334 149.537 196.479C145.096 194.426 142.419 190.986 142 187.109V181.683M189.934 181.683C207.31 183.782 220.807 196.573 224.351 206.705C225.942 211.254 226.362 223.991 224.351 236.294M142 181.683C130.453 183.078 122.145 187.373 116.389 193.432M224.351 236.294C229.721 261.271 231.53 276.799 243.891 279.38C248.722 280.388 253.175 278.841 255.489 276.646C258.152 274.121 259.887 270.167 260.332 266.999C261.274 260.289 259.55 249.619 257.449 242.279C253.904 229.894 246.777 214.783 242.24 208.514C235.657 199.418 225.435 192.132 214.981 193.44M224.351 236.294C223.889 239.119 223.15 242.508 222.161 246.198M116.389 193.432C104.451 206 103.492 226.159 107.386 243.786M116.389 193.432C104.342 191.115 89.1541 208.001 82.7295 223.587C79.8486 230.576 74.543 245.425 73.1122 256.146C72.2812 262.373 73.0407 270.571 76.2724 274.218C80.8495 279.382 101.295 292.021 107.386 243.786M107.386 243.786C107.565 244.596 107.754 245.4 107.952 246.198M107.952 246.198C109.454 252.227 111.506 257.895 113.853 262.779C120.415 276.433 129.496 287.454 135.84 292.021C148.74 301.308 165.889 302.181 175.467 300.524C188.6 298.252 201.488 288.16 207.222 280.264C213.999 270.93 219.203 257.224 222.161 246.198M107.952 246.198C112.667 248.007 125.292 252.046 138.074 253.735V260.97C146.698 263.382 169.985 266.758 194.148 260.97V253.735C200.965 252.73 216.112 249.815 222.161 246.198"
          stroke="#8D43FE"
          stroke-width="1.20589"
        />
        <g filter="url(#filter0_f_1650_7973)">
          <ellipse cx="166.713" cy="322.169" rx="31.6545" ry="6.02943" fill="#8D43FE" />
        </g>
        <rect x="95.8686" y="80.3891" width="142.294" height="98.8826" rx="35.5736" stroke="#8D43FE" stroke-width="1.20589" />
        <rect x="103.103" y="87.6244" width="126.015" height="84.412" rx="27.6695" stroke="#8D43FE" stroke-width="1.20589" />
        <ellipse cx="143.5" cy="124.405" rx="3.61766" ry="4.82354" fill="#8D43FE" />
        <ellipse cx="183.899" cy="124.405" rx="3.61766" ry="4.82354" fill="#8D43FE" />
        <path
          d="M174.854 147.317C174.854 149.715 173.901 152.016 172.205 153.712C170.509 155.408 168.208 156.361 165.81 156.361C163.411 156.361 161.111 155.408 159.415 153.712C157.718 152.016 156.766 149.715 156.766 147.317L165.81 147.317H174.854Z"
          fill="#8D43FE"
        />
        <path
          d="M91.6487 111.14H96.0044C96.0025 111.489 96 111.979 95.9972 112.581C95.9913 113.859 95.9842 115.645 95.9794 117.677C95.97 121.741 95.9699 126.794 96.0079 130.741C96.0313 133.174 95.9946 134.947 95.9606 136.591C95.8905 139.975 95.8318 142.81 96.3332 149.728H91.6487C85.3218 149.728 80.1928 144.599 80.1928 138.272V122.596C80.1928 116.269 85.3218 111.14 91.6487 111.14Z"
          fill="#8D43FE"
          stroke="#8D43FE"
          stroke-width="1.20589"
        />
        <path
          d="M242.383 111.14H238.526C238.528 111.489 238.53 111.979 238.533 112.581C238.539 113.859 238.545 115.645 238.55 117.677C238.559 121.741 238.559 126.794 238.522 130.741C238.5 133.175 238.535 134.948 238.568 136.592C238.636 139.976 238.693 142.81 238.207 149.728H242.383C248.709 149.728 253.838 144.599 253.838 138.272V122.596C253.838 116.269 248.709 111.14 242.383 111.14Z"
          fill="#8D43FE"
          stroke="#8D43FE"
          stroke-width="1.20589"
        />
        <path d="M238.162 110.537H242.383C249.042 110.537 254.441 115.936 254.441 122.596V138.272C254.441 144.932 249.042 150.331 242.383 150.331H237.559" stroke="#8D43FE" stroke-width="1.20589" />
        <rect x="149.227" y="60.1911" width="36.7795" height="19.8971" rx="5.72796" fill="#8D43FE" stroke="#8D43FE" stroke-width="0.602943" />
        <circle cx="167.92" cy="42.1022" r="7.53678" fill="#8D43FE" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M68.3363 9.54329L38.6124 15.8613C35.0295 16.6229 32.7424 20.1448 33.5039 23.7277L44.8546 77.1285C45.6162 80.7114 49.1381 82.9986 52.721 82.237L89.355 74.4502C92.9379 73.6886 95.2251 70.1667 94.4635 66.5838L84.582 20.0948L68.3363 9.54329Z"
          fill="#387CFF"
        />
        <path d="M81.9853 21.29L69.1784 12.2606C69.1784 12.2606 68.1374 18.9713 70.7814 21.5115C73.9373 24.5433 81.9853 21.29 81.9853 21.29Z" fill="white" />
        <path
          d="M49.2578 55.7623L47.4327 43.6206L52.223 42.9005C53.1439 42.7621 53.9549 42.82 54.656 43.0744C55.3564 43.3248 55.9233 43.7347 56.3564 44.3041C56.793 44.869 57.0718 45.5546 57.193 46.3608C57.3142 47.1671 57.2473 47.9047 56.9921 48.5735C56.737 49.2424 56.3053 49.7984 55.6969 50.2414C55.0925 50.6839 54.3239 50.9753 53.3911 51.1155L50.3379 51.5744L50.0287 49.5172L52.6669 49.1206C53.161 49.0464 53.5553 48.9002 53.8499 48.6821C54.1478 48.4595 54.353 48.1841 54.4653 47.856C54.5811 47.5234 54.6089 47.1575 54.5489 46.7583C54.4883 46.3552 54.3544 46.0156 54.1472 45.7395C53.9434 45.459 53.6669 45.26 53.3179 45.1428C52.9682 45.0215 52.5444 44.9983 52.0464 45.0732L50.3153 45.3334L51.8249 55.3764L49.2578 55.7623ZM64.3712 53.4905L60.0671 54.1375L58.242 41.9958L62.5817 41.3435C63.803 41.1599 64.8909 41.2449 65.8453 41.5986C66.7992 41.9483 67.5813 42.5401 68.1916 43.3739C68.8059 44.2071 69.2078 45.2541 69.3973 46.5149C69.5874 47.7797 69.5117 48.9025 69.1701 49.8834C68.8324 50.8638 68.2555 51.6638 67.4393 52.2837C66.627 52.9029 65.6043 53.3052 64.3712 53.4905ZM62.3035 51.5521L63.9339 51.3071C64.6927 51.193 65.3108 50.9627 65.7882 50.6161C66.2689 50.265 66.5969 49.7852 66.7722 49.1769C66.9509 48.564 66.9722 47.805 66.8362 46.8999C66.7013 46.0027 66.4587 45.2894 66.1083 44.7601C65.7619 44.2301 65.3097 43.8697 64.7515 43.6788C64.1933 43.4878 63.5348 43.4494 62.776 43.5635L61.1397 43.8094L62.3035 51.5521ZM72.2132 52.3118L70.3882 40.1701L78.4273 38.9617L78.7454 41.0782L73.2734 41.9007L73.7082 44.7938L78.6467 44.0515L78.9649 46.168L74.0264 46.9103L74.7803 51.9259L72.2132 52.3118Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M279.473 18.7469L249.877 11.854C246.31 11.0232 242.744 13.2416 241.914 16.8091L229.53 69.9799C228.699 73.5474 230.918 77.113 234.485 77.9438L270.961 86.4392C274.529 87.27 278.094 85.0516 278.925 81.4841L289.706 35.1953L279.473 18.7469Z"
          fill="#387CFF"
        />
        <path d="M286.848 35.1749L279.084 21.564C279.084 21.564 275.293 27.1987 276.609 30.6209C278.18 34.7054 286.848 35.1749 286.848 35.1749Z" fill="white" />
        <path d="M244.192 46.0215L277.354 53.2568M242.986 52.3524L275.846 59.5877M241.78 58.9848L273.736 65.3157M240.574 65.3157L256.854 68.6318" stroke="white" stroke-width="1.80883" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M333.876 113.615L301.038 107.502C297.08 106.765 293.274 109.377 292.537 113.335L281.555 172.33C280.818 176.288 283.43 180.094 287.388 180.831L327.86 188.365C331.818 189.102 335.624 186.49 336.361 182.532L345.921 131.173L333.876 113.615Z"
          fill="#387CFF"
        />
        <path d="M342.785 131.292L333.589 116.728C333.589 116.728 329.704 123.102 331.318 126.795C333.244 131.203 342.785 131.292 342.785 131.292Z" fill="white" />
        <path
          d="M310.214 145.016L297.25 142.492L296.045 149.125M310.214 145.016L331.317 149.125L330.01 155.757M310.214 145.016L307.199 165.054M307.199 165.054L327.398 169.022L328.704 162.389M307.199 165.054L293.633 162.389L294.839 155.757M296.045 149.125L330.01 155.757M296.045 149.125L294.839 155.757M330.01 155.757L328.704 162.389M294.839 155.757L328.704 162.389"
          stroke="white"
          stroke-width="1.80883"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M36.2113 115.796L6.48744 122.114C2.90452 122.876 0.617376 126.398 1.37895 129.981L12.7296 183.381C13.4912 186.964 17.0131 189.251 20.596 188.49L57.23 180.703C60.8129 179.942 63.1001 176.42 62.3385 172.837L52.457 126.348L36.2113 115.796Z"
          fill="#387CFF"
        />
        <path d="M49.8603 127.542L37.0534 118.513C37.0534 118.513 36.0124 125.223 38.6564 127.763C41.8123 130.795 49.8603 127.542 49.8603 127.542Z" fill="white" />
        <path d="M25.625 150.933L44.6177 146.713M27.1324 157.566L46.1251 153.044M28.6397 164.5L47.6324 159.978" stroke="white" stroke-width="1.80883" />
        <circle cx="21.4058" cy="166.309" r="1.50736" fill="white" />
        <circle cx="19.5972" cy="159.676" r="1.50736" fill="white" />
        <circle cx="17.7886" cy="153.044" r="1.50736" fill="white" />
        <defs>
          <filter id="filter0_f_1650_7973" x="123" y="304.081" width="87.4263" height="36.1763" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="6.02943" result="effect1_foregroundBlur_1650_7973" />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default CommingSoonIcon;
