import {
  Divider,
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
  Select,
  MenuItem,
  Box,
  FormControl,
  InputLabel,
  FormHelperText,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import axios from "axios";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade";
import { theme } from "../../../constant/Theme";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FilterCancelBtn, FilterSubmitBtn } from "../../Button";
const DepositionModel = ({ closeModal, filter, setFilter }) => {
  const user = useSelector((state) => state?.reducer?.user);
  // persons api
  const [allPerson, setAllPerson] = useState([]);
  const [isCleardisable, setisCleardisbale] = useState(
    filter && typeof filter == "object" && Object.keys(filter).length > 0
  );
  const [allCase, setAllCase] = useState([]);
  const [allType, setAllType] = useState([]);
  // for hidden the filter matter
  const { matter } = useMultiFileUpload();

  const getAllCase = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "depositionFilter",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status == 200) {
        setAllCase(response?.data?.uniqueCases);
        setAllPerson(response?.data?.uniqueWitnesses);
        setAllType(response?.data?.uniqueTypes);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllCase();
  }, []);
  const formik = useFormik({
    initialValues: {
      caseTitle: filter?.caseTitle || "",
      witnessId: filter?.witnessId || "",
      witness: filter?.witness || "",
      type: filter?.type || "",
      date: filter?.date || 0,
    },
    onSubmit: async (values, { resetForm }) => {
      setFilter(values);
      closeModal();
    },
  });

  const handleClearFilter = () => {
    setFilter({});
    closeModal();
  };
  const timestampToDateInputString = (timestamp) => {
    if (!timestamp) return "";
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  const today = dayjs();
  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
        maxHeight: 48 * 4.5 + 8,
      },
    },
  };
  return (
    <>
      <Grid container spacing={{ xs: 0, sm: 2 }}>

        <Grid item xs={11} sm={11} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h5" sx={{ color: theme?.palette?.primary?.main, fontFamily: theme?.typography?.fontFamily }}>
            Filters
          </Typography>
        </Grid>
        <Grid item xs={1} sm={1} sx={{ display: "flex", justifyContent: "flex-end" }} onClick={closeModal}>
          <CloseIcon sx={{ color: theme?.palette?.primary?.main, cursor: "pointer" }} />

        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ backgroundColor: theme?.palette?.divider, marginY: "10px" }} />
        </Grid>

        {/* filter input */}
      </Grid>
      <Box
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
        sx={{ mt: 2, position: "relative" }}
      >
        <Grid
          container
          spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}
        >
          {!matter && (
            <Grid item xs={12} sm={12}>
              <Typography
                variant="body1"
                sx={{
                  color: theme?.palette?.primary?.main,
                  marginBottom: "8px", // Spacing between label and input
                  fontFamily: theme?.typography?.fontFamily,
                  ...theme?.typography?.inputHeading,
                }}
              >
                Matter
              </Typography>
              <Autocomplete
                id="caseTitle"
                options={allCase}
                fullWidth
                size="small"
                disableClearable
                getOptionLabel={(option) => option?.title || ""}
                isOptionEqualToValue={(option, value) =>
                  option?.title === value?.title
                }
                value={
                  allCase.find(
                    (option) => option?.title === formik.values.caseTitle
                  ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue("caseTitle", newValue?.title || "");
                  // formik.setFieldValue("caseId", newValue?._id || "");
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Matter"
                    variant="outlined"
                    fullWidth
                    size="small"
                    error={
                      formik.touched.caseId && Boolean(formik.errors.caseId)
                    }
                    InputLabelProps={{
                      sx: {
                        color: theme?.palette?.primary?.main,
                        "&.Mui-focused": {
                          color: theme?.palette?.primary?.main,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",

                        color: theme?.palette?.primary?.placeholderColor, // Set text color
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme?.palette?.primary?.inputColor, // Normal border color
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme?.palette?.primary?.placeholderColor, // Yellow border color on focus

                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: theme?.palette?.primary?.placeholderColor,
                      },
                    }}
                  />
                )}

                PaperComponent={(props) => <Paper {...props} sx={{ backgroundColor: "#FFFFFF", color: theme?.palette?.primary?.placeholderColor }} />}

                ListboxProps={{
                  sx: {
                    maxHeight: 300,
                    overflowY: "auto",
                    "& .MuiAutocomplete-option": {
                      "&:hover": {
                        backgroundColor: "#686DFC26",
                        color: "#686DFC",
                      },
                    },
                  },
                }}
                noOptionsText="No matters found"
              />
            </Grid>
          )}

          {/* witness */}
          <Grid item xs={12} sm={12} style={{ paddingTop: "10px" }}>
            <Typography
              variant="body1"
              sx={{
                color: theme?.palette?.primary?.main,
                marginBottom: "8px", // Spacing between label and input
                fontFamily: theme?.typography?.fontFamily,
                ...theme?.typography?.inputHeading,
              }}
            >
              Witness name{" "}
            </Typography>
            <FormControl
              variant="outlined"
              fullWidth
              size="small"
              error={
                formik?.touched?.witness && Boolean(formik?.errors?.witness)
              }
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: theme?.palette?.primary?.placeholderColor, // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {

                    borderColor: theme?.palette?.primary?.inputColor,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.primary?.placeholderColor, // Yellow border color on focus

                  },
                },
                "& .MuiSvgIcon-root": {
                  color: theme?.palette?.primary?.main,
                },
              }}
            >
              <Select
                labelId="witness-label"
                id="witness"
                autoComplete="off"
                name="witness"
                displayEmpty
                placeholder="Select Witenss Name"
                value={formik.values.witnessId}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.setFieldValue("witness", e.target.value);
                  formik.setFieldValue("witnessId", e.target.value);
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#323249",
                      color: "#C8C9DB",
                      maxHeight: 300, // Adjust as needed
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor: "#686DFC26", // Hover background color
                          color: "#686DFC", // Hover text color
                        },
                      },
                    },
                  },
                  MenuProps,
                }}
                sx={{
                  width: "100%",
                  "& .MuiSelect-select": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography sx={{ fontStyle: "normal", color: theme?.palette?.primary?.dropDownColor }}>Select Witness Name</Typography>
                </MenuItem>
                {allPerson
                  ?.sort((a, b) => a.firstName.localeCompare(b.firstName))
                  .map((row) => (
                    <MenuItem key={row?._id} value={row?._id}>
                      {row?.personName}
                    </MenuItem>
                  ))}
              </Select>
              {formik?.touched?.witness && formik?.errors?.witness && (
                <FormHelperText>{formik?.errors?.witness}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} style={{ paddingTop: "10px" }}>
            <FormControl fullWidth>
              <Typography
                variant="body1"
                sx={{
                  color: theme?.palette?.primary?.main,
                  fontFamily: theme?.typography?.fontFamily,
                  ...theme?.typography?.inputHeading,
                }}
              >
                Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]} size="small">
                  <DatePicker
                    value={
                      formik?.values?.date
                        ? dayjs(new Date(formik?.values?.date).toString())
                        : null
                    }
                    autoComplete="given-date"
                    slotProps={{ textField: { size: "small" } }} //for height of date input
                    error={formik.touched.date && formik.errors?.date}
                    onChange={(date) => {
                      if (date && dayjs(date).isAfter(today)) {
                        formik.setFieldValue("date", today.valueOf());
                      } else {
                        formik.setFieldValue(
                          "date",
                          date ? new Date(date).getTime() : ""
                        );
                      }
                    }}
                    maxDate={today}
                    helperText={formik.touched?.date && formik.errors?.date}
                    sx={{
                      width: "100%",
                      borderRadius: "5px",

                      "& .MuiOutlinedInput-root": {
                        color: theme?.palette?.secondary?.main,
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme?.palette?.primary?.inputColor, // Normal border color
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme?.palette?.primary?.hoverColor, // Border color on hover
                        },
                        "& .MuiInputBase-input": {
                          color: "#505058 !important",
                        },
                      },
                      "& .MuiInputLabel-root.MuiInputLabel-formControl": {

                        color: theme?.palette?.primary?.placeholderColor, // Error label color

                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#A6A7C1", // Yellow border color on focus
                      },
                      "& .MuiSvgIcon-root": {
                        color: theme?.palette?.primary?.placeholderColor,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: theme?.palette?.primary?.main,
                      },
                    }}
                    InputProps={{
                      style: {
                        color: theme?.palette?.primary?.placeholderColor,
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: "10px" }}>
            <Typography
              variant="body1"
              sx={{
                color: theme?.palette?.primary?.main,
                marginBottom: "8px", // Spacing between label and input
                fontFamily: theme?.typography?.fontFamily,
                ...theme?.typography?.inputHeading,
              }}
            >
              Type
            </Typography>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: theme?.palette?.primary?.placeholderColor, // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.primary?.inputColor,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.primary?.placeholderColor, // Yellow border color on focus
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: theme?.palette?.primary?.main,
                },
              }}
            >
              <Select
                labelId="type-label"
                id="case"
                autoComplete="off"
                name="type"
                displayEmpty
                placeholder="Select Type"
                value={formik.values?.type}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#FFFFFF",
                      color: theme?.palette?.primary?.placeholderColor,
                      maxHeight: 300, // Adjust as needed
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor: "#686DFC26", // Hover background color
                          color: "#686DFC", // Hover text color
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography sx={{ fontStyle: "normal", color: theme?.palette?.primary?.dropDownColor }}>Select Type</Typography>
                </MenuItem>
                {allType?.map((row, index) => (
                  <MenuItem key={index} value={row}>
                    {row}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* button */}
        <Grid
          container
          spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}
        >
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",

              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "flex-end",
              gap: 1,
            }}
          >
             <FilterCancelBtn text={"clear filter"} onClick={handleClearFilter} disabled={!isCleardisable} />
             <FilterSubmitBtn title={"Apply Filter"} type={"submit"} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DepositionModel;
