import React, { useCallback, useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { BiSolidEditAlt } from "react-icons/bi";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAxios } from "../../../services/http.service";
import { useSelector } from "react-redux";
import PopupModel from "../../../Components/Modal";
import { useQuery, useQueryClient } from "react-query";
import QueriesConfirmationModel from "./QueriesDelete";

import CheckIcon from "@mui/icons-material/Check";
import notificationService from "../../../services/notification.service";
import axios from "axios";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade";
import { theme } from "../../../constant/Theme";
const HistoryForm = ({ chatHistory, setChatHistory, formik }) => {
  const [threadData, setThreadData] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const { token } = useSelector((state) => state?.reducer?.user?.userData);
  const queryClient = useQueryClient();
  const { matter } = useMultiFileUpload();
  const { user } = useSelector((state) => state.reducer);

  const { get, setBearerToken } = useAxios();
  const open = Boolean(anchorEl);
  const fetchData = async () => {
    const queryParams = [];

    // Append matter to query params if it exists
    if (matter) {
      queryParams.push(`global=${encodeURIComponent(JSON.stringify(matter))}`);
    }

    // Construct the endpoint with query params
    let endpoint = `/getThread/${user.organizationMemberIds[0]}`;
    if (queryParams.length) {
      endpoint += `?${queryParams.join("&")}`;
    }

    // Set the bearer token for authentication
    setBearerToken(token);
    const response = await get(endpoint);

    // setChatHistory(response?.data[0]);

    return response?.data;
  };

  const { data, isLoading } = useQuery(["thread", matter], fetchData, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleHistoryData = (v) => {
    formik.setValues({
      category: v?.moduleName || "",
      items: v?.files || [],
      caseId: v?.matter_id || "",
      chatId: "",
      maxTokens: v?.max_tokens || "",
      sources: v?.source_number || "",
      personsId: v?.person_id || "",
      type: v?.type || "",
      fillingParty: v?.person_id || "",
      role: "",
      roleId: "",
      showFilter: true,
      exhibitListing: v?.exhibitListing,
    });
    setChatHistory(v);
    queryClient.invalidateQueries("chatbot");
  };

  const [editingId, setEditingId] = useState(null); // Track the ID being edited
  const [renameValue, setRenameValue] = useState(""); // Track the input value
  const [openQueries, setOpenQueries] = useState(false);

  const handleOpen = (v) => {
    setOpenQueries(true);
    setThreadData(v);
  };
  const handleCloseQueries = () => {
    setOpenQueries(false);
    handleClose();
  };
  const handleRenameClick = (v) => {
    setEditingId(v._id); // Set the current editing thread
    setRenameValue(v.title); // Set the current answer as the initial value
  };

  // Handle input change in the TextField
  const handleInputChange = (e) => {
    setRenameValue(e.target.value);
  };

  // Handle saving the renamed value (e.g., on pressing Enter)
  const handleRenameSave = (v) => {
    // Update the data in place or through a function that updates state
    // Reset editing state
    setEditingId(null);
  };

  const handleCheckClick = async (v) => {
    try {
      // Log the current object
      // Prepare the data to be sent to the API
      const data = {
        thread_id: v.thread_id,
        title: renameValue, // Assuming renameValue holds the new title
      };

      // Send a PUT request to the server with the updated thread title
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}thread/update-title`,
        data
      );

      // Check if the request was successful
      if (response.status === 200) {
        // Invalidate the query to refresh the thread data
        queryClient.invalidateQueries("thread");

        // Show a success notification
        notificationService.success(
          "Thread title has been updated successfully."
        );
      } else {
        console.error("Failed to update thread title");
        notificationService.error("Failed to update thread title.");
      }
    } catch (error) {
      // Handle errors during the API request
      console.error("Error updating thread title:", error);
      notificationService.error("Failed to update the thread title.");
    }

    // Reset the state
    setEditingId(null); // Stop editing after save
    setRenameValue(""); // Clear the input field after saving
  };

  const handleMenuOpen = useCallback((event, item) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  }, []);

  return (
    <Box
      sx={{
        height: "80%",
        overflowY: "auto",
      }}
    >
      <PopupModel
        open={openQueries}
        styleProp={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // width: { xs: "90%", sm: "auto" },
          width: { xs: "90%", sm: "500px" }, // Set a fixed width
          maxWidth: "100%", // Ensure it does not exceed screen size
          boxShadow: 24,
          p: 4,
          height: "auto",
          backgroundColor: theme?.palette?.primary?.bodyColor,
          borderRadius: "4px",
          border: "1px solid #FFFFFF",
        }}
        handleClose={handleCloseQueries}
        cards={
          <QueriesConfirmationModel
            closeModal={handleCloseQueries}
            threadData={threadData}
            setChatHistory={setChatHistory}
          />
        }
      />
      {data?.map((v) => (
        <Box
          key={v?.thread_id} // Make sure to provide a unique key
          sx={{
            display: "flex",
            cursor: "pointer",
            justifyContent: "space-between",
            alignItems: "center",
            color: theme?.palette?.primary?.main,
            fontSize: "18px",
            background:
              chatHistory?.thread_id == v?.thread_id ? "#f5eeff" : "none",
            borderRadius: "4px",
            border:
              chatHistory?.thread_id == v?.thread_id ? `1px solid #D5BBFD` : "",
            margin: "10px",
            transition: "background-color 0.3s ease", // Smooth transition for hover effect
            "&:hover": {
              backgroundColor: "#f5eeff", // Change background color to red on hover
              color: "#fff", // Optionally change text color for better readability
              outline: "1px solid #D5BBFD",
            },
            "&:hover .hover-icon": {
              opacity: 1, // Make the icon fully visible
              visibility: "visible", // Ensure the icon is visible
            },
          }}
          onClick={() => {
            if (editingId !== v?._id) {
              setEditingId(null);
            }
            if (!anchorEl) {
              handleHistoryData(v); // Handle history data for each item
            }
          }}
        >
          {editingId === v?._id ? (
            <>
              <TextField
                value={renameValue}
                onChange={handleInputChange}
                size="small"
                variant="outlined"
                sx={{
                  pl: 1,
                  maxWidth: "220px",
                  color: theme?.palette?.primary?.main,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: theme?.palette?.secondary?.inputColor,
                    },
                    "&:hover fieldset": {
                      borderColor: theme?.palette?.secondary?.hoverColor,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: theme?.palette?.secondary?.inputColor,
                    },
                    "& input": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                  },
                }}
              />
              <IconButton onClick={() => handleCheckClick(v)}>
                <CheckIcon style={{ color: "green", fontSize: 28 }} />
              </IconButton>
            </>
          ) : (
            <>
              <Typography
                sx={{
                  color: theme?.palette?.primary?.main,
                  fontFamily: theme?.palette?.typography?.fontFamily,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  fontSize: "14px",
                  textOverflow: "ellipsis",
                  maxWidth: "200px",
                  paddingLeft: "10px",
                }}
              >
                {v?.title}
              </Typography>
              <IconButton
                onClick={(e) => handleMenuOpen(e, v)}
                // sx={{
                //   "&:hover .hover-icon": {
                //     visibility: "visible",
                //     opacity: 1,
                //   },
                // }}
                sx={{
                  "& .hover-icon": {
                    color: "#505058",
                    opacity: 0, // Initially hidden
                    visibility: "hidden", // Initially hidden
                    transition:
                      "opacity 0.2s ease-in-out, visibility 0.2s ease-in-out", // Smooth fade-in effect
                  },
                }}
              >
                <MoreVertIcon
                  className="hover-icon"
                  // sx={{
                  //   color: "#505058",
                  //   // visibility: "hidden", // Initially hide the icon
                  //   opacity: 0, // Initially set opacity to 0
                  //   transition: "opacity 0.2s ease-in-out", // Smooth fade-in effect
                  // }}
                />
              </IconButton>
            </>
          )}

          {/* Menu Dropdown */}
          {anchorEl && selectedItem?.thread_id === v?.thread_id && (
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                sx: {
                  // backgroundColor: "#4A4C64",
                  backgroundColor: theme?.palette?.primary?.subSecondary,
                  border: `1px solid ${theme?.palette?.secondary?.btnPrimary}`,
                  boxShadow: 0,
                  color: theme?.palette?.primary?.placeholderColor,
                  "& .MuiMenuItem-root": {
                    "&:hover": {
                      backgroundColor: theme?.palette?.primary?.tertiaryShades,
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                  },
                },
              }}
            >
              <MenuItem
                sx={{ gap: "7px" }}
                onClick={(e) => {
                  e.stopPropagation(); // Stop event propagation to avoid affecting the parent
                  handleRenameClick(selectedItem); // Use the selected item for rename
                  handleClose(); // Close the menu
                }}
              >
                <BiSolidEditAlt style={{ cursor: "pointer" }} fontSize="18px" />
                <Typography
                  sx={{ color: theme?.palette?.primary?.placeholderColor }}
                >
                  Rename
                </Typography>
              </MenuItem>

              <MenuItem
                sx={{ gap: "7px" }}
                onClick={(e) => {
                  e.stopPropagation(); // Stop event propagation to avoid affecting the parent
                  handleOpen(selectedItem); // Use the selected item for delete
                  handleClose(); // Close the menu
                }}
              >
                {/* <DeleteIcon sx={{ color: "#FFFFF" }} fontSize="medium" /> */}
                <DeleteIcon
                  sx={{ color: "#DC3545", fontSize: "22px" }}
                  size={20}
                />

                <Typography
                  sx={{ color: theme?.palette?.secondary?.dangerMain }}
                >
                  Delete{" "}
                </Typography>
              </MenuItem>
            </Menu>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default HistoryForm;
