import React, { useState } from "react";
import FileUpload from "../Icons/FileUpload";
import { Box, LinearProgress, Typography } from "@mui/material";
import ExpendFileIcon from "../Icons/ExpendIcon";
import { useMultiFileUpload } from "../../context/useMultiFileUploade";
import CloseIcon from "../Icons/CloseIcon";
import { theme } from "../../constant/Theme";

const UploadToaster = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { uploads } = useMultiFileUpload();
  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  if (uploads.length === 0) {
    return null; // Do not render the component if there are no uploads
  }

  return (
    <>
      {" "}
      <Box sx={{ display: "flex", justifyContent: "end", flexDirection: "column", flexWrap: "wrap", alignContent: "end" }}>
        <Box
          sx={{
            backgroundColor: "#F5EEFF",
            color: theme?.palette?.primary?.main,
            display: "flex",
            width: "19rem",
            justifyContent: "space-between",
            p: "7px",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            borderBottomLeftRadius: isExpanded ? "0" : "4px",
            borderBottomRightRadius: isExpanded ? "0" : "4px",
            borderBottom: isExpanded ? "1px solid #67687E" : "0px",
           }}
        >
          <Box sx={{ display: "flex", gap: "16px" }}>
            <FileUpload />
            <Box>
              <Typography>
                Uploading {uploads.length} {uploads.length === 1 ? "File" : "Files"}
              </Typography>
              <Typography>{uploads.length === 0 ? 0 : uploads.reduce((acc, file) => acc + file.progress, 0) / uploads.length}%</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box onClick={handleExpandClick} sx={{ cursor: "pointer" }}>
              {isExpanded ? <CloseIcon /> : <ExpendFileIcon />}
            </Box>
          </Box>
        </Box>

        {isExpanded && (
          <>
            {uploads?.map((file, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    backgroundColor: "#F5EEFF",
                    color: theme?.palette?.primary?.main,
                    width: "19rem",
                    gap: "50px",
                    p: "10px",
                    borderTop: "0px",
                    borderBottomLeftRadius: index === uploads.length - 1 ? "4px" : "0px",
                    borderBottomRightRadius: index === uploads.length - 1 ? "4px" : "0px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: theme?.palette?.primary?.main,
                      whiteSpace: "nowrap", // Prevents text from wrapping
                      overflow: "hidden", // Hides the overflowing text
                      textOverflow: "ellipsis",
                    }}
                  >
                    {" "}
                    {file?.title}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "stretch" }}>
                    <LinearProgress
                      variant="determinate"
                      value={file?.progress }
                      sx={{
                        flex: 1,
                        mt: "10px",
                        width: "80px",
                        height: "6px", // Adjusted to control overall component width
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#8D43FE",
                        },
                        borderRadius: "4px",
                        backgroundColor: "#E0CCFE",
                      }}
                    />
                    <Typography sx={{ fontSize: "16px", color: "#8D43FE", marginLeft: "5px" }}>{file?.progress}%</Typography>
                  </Box>
                </Box>
              );
            })}
          </>
        )}
      </Box>
    </>
  );
};

export default UploadToaster;
