import { useFormik } from "formik";
import { theme } from "../../constant/Theme";

export const inputDocumentPleadingStyles = {
  cases: (formik) => ({
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      color: theme?.palette?.primary?.main,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.primary?.inputColor,
      },
    },
    "& .MuiSvgIcon-root": {
      color: theme?.palette?.primary?.placeholderColor,
    },
  }),
  deposition: (formik) => ({
    "& .MuiOutlinedInput-root": {
      color: theme?.palette?.primary?.main,
      "& fieldset": {
        borderColor: formik.touched.type && Boolean(formik.errors.type) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor,
      },
      "&:hover fieldset": {
        borderColor: formik.touched.type && Boolean(formik.errors.type) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: formik.touched.type && Boolean(formik.errors.type) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor,
      },
      ...theme?.typography?.inputPlaceHolder,
    },
    "& .MuiSvgIcon-root": {
      color: theme?.palette?.primary?.placeholderColor,
    },
  }),
  title: (formik) => ({
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      color: theme?.palette?.primary?.main, // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.title && Boolean(formik.errors.title) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.title && Boolean(formik.errors.title) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.hoverColor,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Yellow border color on focus
      },
      "&.Mui-disable .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.primary?.inputColor, // Yellow border color on focus
      },
    },
    "& .MuiSvgIcon-root": {
      color: theme?.palette?.primary?.placeholderColor,
    },
  }),
  submit: {
    width: { xs: "100%", sm: "150px" },
    mt: { xs: 1, sm: 1.5 },
    mb: { xs: 0, sm: 2 },
    height: "45px",
    "&:hover, &:focus": {
      backgroundColor: "white",
      color: "#FFFFFF",
      backgroundColor: theme?.palette?.secondary?.btnPrimary,
      boxShadow: 0,
    },
    "&:disabled": {
      backgroundColor: theme?.palette?.secondary?.btnPrimary,
      color: "white",
      boxShadow: 0,
    },
    boxShadow: 0,
    fontSize: "12px",
    backgroundColor: theme?.palette?.secondary?.btnPrimary,
    fontWeight: "600",
    fontFamily: theme?.typography?.fontFamily,
  },
  viewHeading: {
    fontSize: "16px",
    lineHeight: "24px",
    color: theme?.palette?.primary?.main,
    fontFamily: theme?.typography?.fontFamily,
    fontWeight: "500",
  },
  viewSubHeading: {
    fontSize: "14px",
    lineHeight: "19.8px",
    color: theme?.palette?.primary?.placeholderColor,
    fontFamily: theme?.typography?.fontFamily,
  },
  backBtn: {
    padding: { xs: "10px 25px", xl: "20px 50px" },
    color: theme?.palette?.secondary?.btnPrimary,
    fontSize: { xs: "10px", xl: "15px" },
    borderColor: "white",
    border: "2px solid",
    borderRadius: "4px",
    background: "transparent",
    "&:hover": {
      border: "2px solid",
      borderColor: theme?.palette?.secondary?.btnPrimary,
      color: theme?.palette?.secondary?.btnPrimary,
      background: "transparent",
    },
  },
};
