import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { state } from "../../constant/ConstantStateData";
import { inputPersonStyles } from "../../pages/Persons/style";
import { theme } from "../../constant/Theme";
import LabeledTextField, {
  CustomSelectDropDown,
  NoteInput,
} from "../../constant/ConstantInputField";
import TabsConstants from "../../constant/TabsConstants";

const baseSchema = Yup.object({
  email: Yup.string().email("Invalid email address").nullable().notRequired(), // Optional and allows null

  phone: Yup.string()
    .nullable()
    .notRequired()
    .test("phone", "Phone number is not valid", function (value) {
      if (!value || value === "") {
        return true; // Skip validation if the value is empty or null
      }
      return /^(\+?\d{1,4}[\s-]?)?(?!0+\s+,?$)\d{10,15}$/.test(value); // Apply regex validation only if value exists
    }),

  address: Yup.string()
    .nullable()
    .notRequired()
    .test(
      "address",
      "Address must be at least 10 characters.",
      function (value) {
        if (!value || value === "") {
          return true; // Skip validation if the value is empty or null
        }
        return value.length >= 10; // Check only if value exists
      }
    ),

  city: Yup.string()
    .nullable()
    .notRequired()
    .test(
      "city",
      "City must contain only letters and spaces.",
      function (value) {
        if (!value || value === "") {
          return true; // Skip validation if the value is empty or null
        }
        return /^[A-Za-z\s]+$/.test(value); // Apply regex validation only if value exists
      }
    ),

  state: Yup.string().nullable().notRequired(),

  zipcode: Yup.string().nullable().notRequired(),
  // .test(
  //   "zipcode",
  //   "The zip code must be exactly 5 digits long.",
  //   function (value) {
  //     if (!value || value === "") {
  //       return true; // Skip validation if the value is empty or null
  //     }
  //     return /^\d{5}$/.test(value); // Apply regex validation only if value exists
  //   }
  // ),
});

// Define schema for 'person' type fields
const personFields = Yup.object({
  firstName: Yup.string()
    .min(3, "First name must be at least 3 characters.")
    .required("First name is required for a person"),
  lastName: Yup.string()
    .min(3, "Last name must be at least 3 characters.")
    .required("Last name is required for a person"),
  // gender: Yup.string(),
});

// Function to dynamically choose schema based on 'type'
export const validationSchemaStepOne = Yup.object({
  type: Yup.string().required("Type is required"),
}).shape({
  ...baseSchema.fields,
  firstName: Yup.string().when("type", {
    is: "person",
    then: () =>
      Yup.string()
        .min(3, "First name must be at least 3 characters.")
        .required("Required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  lastName: Yup.string().when("type", {
    is: "person",
    then: () =>
      Yup.string()
        .min(2, "Last name must be at least 2 characters.")
        .required("Required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  // gender: Yup.string().when("type", {
  //   is: "person",
  //   then: () => Yup.string().required("Required"),
  //   otherwise: () => Yup.string().notRequired(),
  // }),
});

export const validationSchemaStepTwo = Yup.object({
  caseId: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
  policyLimit: Yup.string(),
  policyNumber: Yup.string(),
  note: Yup.string(),
});
let stateNames = state;

export const PersonRenderStep = (activeStep, formik) => {
  const { user } = useSelector((state) => state?.reducer);
  const [allClient, setAllClient] = useState([]);
  const [allCase, setAllCase] = useState([]);
  const getAllCase = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "caseDropdown",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status == 200) {
        setAllCase(response?.data?.data);
      }
    } catch (error) {}
  };
  const getAllClients = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "getClients",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        let result = response?.data?.data
          .map((v) => v.companyName)
          .filter((name) => name && name.trim() !== ""); // Filter out empty names

        if (result.length === 0) {
          result = ["N/A"]; // Set to "N/A" if no valid data is returned
        }

        const currentCompanyName = formik.values.companyName;
        if (currentCompanyName && currentCompanyName.trim() !== "") {
          setAllClient([...result, currentCompanyName]);
        } else {
          setAllClient(result);
        }
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
      setAllClient(["N/A"]); // Set to "N/A" if the API call fails
    }
  };

  useEffect(() => {
    getAllCase();
    getAllClients();
  }, []);

  const [checked, setChecked] = React.useState(false);
  const [newchecked, setNewChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleNewChange = (event) => {
    setNewChecked(event?.target?.newchecked);
  };
  switch (activeStep) {
    case 0:
      return (
        <>
          <TabsConstants formik={formik} />
        </>
      );
    case 1:
      return (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{
                  color:
                    formik.touched.caseId && Boolean(formik.errors?.caseId)
                      ? theme?.palette?.secondary?.dangerMain
                      : theme?.palette?.primary?.main,
                  marginBottom: "4px", // Spacing between label and input
                  fontFamily: theme?.typography?.fontFamily,
                  ...theme?.typography?.inputHeading,
                  display: "flex",
                  gap: "4px",
                }}
              >
                Matters
                <Box
                  variant="span"
                  sx={{
                    color: theme?.palette?.error?.main, // Set the asterisk color to red
                  }}
                >
                  *
                </Box>
              </Typography>
              <FormControl
                required
                variant="outlined"
                size="small"
                fullWidth
                error={formik.touched.caseId && Boolean(formik.errors.caseId)}
                sx={inputPersonStyles?.matterName(formik)}
              >
                <Autocomplete
                  id="case"
                  options={allCase}
                  disableClearable={true}
                  getOptionLabel={(option) => option.title}
                  autoComplete
                  size="small"
                  includeInputInList
                  value={
                    allCase.find(
                      (option) => option._id === formik.values.caseId
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue("case", newValue?._id || "");
                    formik.setFieldValue("caseId", newValue?._id || "");
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Matter"
                      variant="outlined"
                      fullWidth
                      error={
                        formik.touched.caseId && Boolean(formik.errors.caseId)
                      }
                      helperText={
                        formik.touched.caseId && formik.errors.caseId
                          ? formik.errors.caseId
                          : ""
                      }
                      InputLabelProps={{
                        sx: {
                          color: theme?.palette?.primary?.main,
                          ...theme?.typography?.inputPlaceHolder,
                          "&.Mui-focused": {
                            color: theme?.palette?.primary?.main,
                          },
                        },
                      }}
                      sx={inputPersonStyles?.TextField}
                    />
                  )}
                  PaperComponent={(props) => (
                    <Paper
                      {...props}
                      sx={{
                        backgroundColor: "#FFFFFF",
                        color: theme?.palette?.primary?.placeholderColor,
                      }}
                    />
                  )}
                  ListboxProps={{
                    sx: inputPersonStyles.listbox,
                  }}
                  noOptionsText="No matters found"
                />

                {allCase.length <= 0 && (
                  <FormHelperText
                    sx={{ color: theme?.palette?.secondary?.dangerMain }}
                  >
                    No matter is added.
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{
                  color:
                    formik.touched.role && Boolean(formik.errors?.role)
                      ? theme?.palette?.secondary?.dangerMain
                      : theme?.palette?.primary?.main,
                  mb: "4px",
                  ...theme?.typography?.inputHeading,
                  display: "flex",
                  gap: "4px",
                }} // Optional styles for the label
              >
                Role{" "}
                <Box
                  variant="span"
                  sx={{
                    color: theme?.palette?.error?.main, // Set the asterisk color to red
                  }}
                >
                  *
                </Box>
              </Typography>
              <CustomSelectDropDown
                label="Role"
                name="role"
                handleBlur
                value={formik.values.role}
                error={formik.touched.role && Boolean(formik.errors.role)}
                helperText={formik.touched.role ? formik.errors.role : ""}
                options={[
                  "Co-Counsel",
                  "Defense Counsel",
                  "Defendant",
                  "Expert Witness",
                  "Fact Witness",
                  "Healthcare Provider",
                  "Insurance Adjuster",
                  "Judge",
                  "Judicial Assistant",
                  "Medical Provider",
                  "Other",
                  "PI Liability Adjuster",
                  "PI UM Adjuster",
                  "Plaintiff",
                  "Police",
                  "Property Adjuster",
                ]}
                formik={formik}
                theme={theme}
                inputStyles={inputPersonStyles?.role(formik)}
                placeholder={"Select Role"}
              />
            </Grid>

            {formik.values.role && formik.values.role.includes("Adjuster") && (
              <>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: theme?.palette?.primary?.main,
                      mb: "4px",
                      ...theme?.typography?.inputHeading,
                    }} // Optional styles for the label
                  >
                    Insurance Type
                  </Typography>
                  <CustomSelectDropDown
                    label="Insurance Type"
                    name="insuranceType"
                    value={formik.values.insuranceType}
                    error={
                      formik.touched.role &&
                      Boolean(formik.errors.insuranceType)
                    }
                    helperText={
                      formik.touched.role ? formik.errors.insuranceType : ""
                    }
                    options={[
                      "Liability Adjuster",
                      "Property Damage Adjuster",
                      "UM Adjuster",
                    ]}
                    formik={formik}
                    theme={theme}
                    inputStyles={inputPersonStyles?.insuranceType(formik)}
                    placeholder={"Select Insurance Type"}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LabeledTextField
                    formik={formik}
                    name="policyNumber"
                    label="Policy Number"
                    placeholder="Enter Policy number"
                    required={false}
                    sx={inputPersonStyles?.inputText}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LabeledTextField
                    formik={formik}
                    name="policyLimit"
                    label="Policy Limit"
                    placeholder="Enter Policy Limit"
                    required={false}
                    sx={inputPersonStyles?.inputText}
                  />
                </Grid>
              </>
            )}

            {formik.values.role === "Healthcare Provider" && (
              <>
                {" "}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: theme?.palette?.primary?.main,
                      mb: "4px",
                      ...theme?.typography?.inputHeading,
                    }} // Optional styles for the label
                  >
                    Physician Type
                  </Typography>
                  <CustomSelectDropDown
                    label="physician Type"
                    name="physicianType"
                    value={formik.values.physicianType}
                    error={
                      formik.touched.role &&
                      Boolean(formik.errors.physicianType)
                    }
                    helperText={
                      formik.touched.role ? formik.errors.physicianType : ""
                    }
                    options={["Non-treating Physician", "Treating Physician"]}
                    formik={formik}
                    theme={theme}
                    inputStyles={inputPersonStyles?.insuranceType(formik)}
                    placeholder={"Select Physician Type"}
                  />
                </Grid>
                <Grid item sm={6} />
              </>
            )}
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  name="pleadingServe"
                  checked={formik.values.pleadingServe}
                  onChange={() => {
                    formik.setFieldValue(
                      "pleadingServe",
                      !formik.values.pleadingServe
                    );
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography
                  sx={{
                    color: theme?.palette?.primary?.placeholderColor,
                    ...theme?.typography?.inputPlaceHolder,
                  }}
                >
                  Served with Pleadings
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  name="depositionRequired"
                  checked={formik.values.depositionRequired}
                  onChange={() => {
                    formik.setFieldValue(
                      "depositionRequired",
                      !formik.values.depositionRequired
                    );
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography
                  sx={{
                    color: theme?.palette?.primary?.placeholderColor,
                    ...theme?.typography?.inputPlaceHolder,
                  }}
                >
                  Deposition Required
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <NoteInput
                formik={formik} // Pass formik to manage form state and validation
                label="Note " // Custom label
                placeholder="Add your notes here" // Custom placeholder
                name="note" // Field name for formik to manage the state
                rows={6} // Number of rows
                theme={theme} // Custom theme for color and styles
                inputStyles={inputPersonStyles?.note} // Custom styles for the input
              />
            </Grid>
          </Grid>
        </>
      );
    default:
      return null;
  }
};
