import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Paper,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../../services/http.service.js";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade.js";
import { NewAddHeading } from "../../Card/SettingCard.js";
import { inputDocumentPleadingStyles } from "../../../pages/DocumentPleading/style.js";
import LabeledTextField from "../../../constant/ConstantInputField.js";
import { ButtonUICancel } from "../../Button/index.js";
import { theme } from "../../../constant/Theme.js";
import FeedIcons from "../../Icons/FeedIcon.js";

const UpdateGeneratePleadingForm = () => {
  const { user } = useSelector((state) => state?.reducer);
  const location = useLocation();
  const { generateDocument } = useMultiFileUpload();

  const { userData } = location.state || {};

  const pleadingGenerate = userData;

  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };
  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = user?.userData?.token;

  const [allCase, setAllCase] = useState([]);
  const [allDocument, setAllDocument] = useState([]);

  const formik = useFormik({
    initialValues: {
      pleadingId: pleadingGenerate?.id,
      caseId: pleadingGenerate?.caseId,
      title: pleadingGenerate?.title,
      case: pleadingGenerate?.case,
      type: userData?.deposition,
      caseId: pleadingGenerate?.caseId,
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Rquired"),
      case: Yup.string().required("Required"),
      type: Yup.string().required("Required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const selectedDoc = allDocument.find((doc) => doc._id === values.type);
      const token = user?.userData?.token;

      const UpdatepleadingDataSaving = {
        pleadingId: values?.pleadingId,
        caseId: values.caseId,
        depositionId: values.type,
        title: values.title,
        status:
          values.caseId !== userData.caseId ||
          values.type !== userData?.deposition
            ? "pending"
            : pleadingGenerate.status,
        witnessId: selectedDoc?.witness[0]?._id,
      };

      const response = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "pleadingGenerate",
        UpdatepleadingDataSaving,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status == 200) {
        const formData = new FormData();

        const data = {
          firstName: user.userData.firstName,
          lastName: user.userData.lastName,
          email: user.userData.email,
          caseName: values.case,
          witnessName:
            selectedDoc.witness[0].firstName +
            " " +
            selectedDoc.witness[0].lastName,
          identifyIssue: "", // Assuming this will be filled in later
          uploadDepostion: selectedDoc.fileUrl,
          organizationId: user?.organizationData?._id,
          userId: user.userData.userId,
          path: `organization/${user?.organizationData?._id}/${user.userData.userId}/statementOfMaterialFact/${values.title}`,
          ModuleName: "statementOfMaterialFact",
        };

        const data1 = {
          file: selectedDoc.fileUrl,
          name: values.title,
          issue: "",
          organization_id: user?.organizationData?._id,
          user_id: user?.userData.userId,
          api_key:
            process.env.CHATGTP_OPEN_API_KEY ||
            "sk-rVLvFpg5-Fdc6Q3p2oSzHsOVfixz2XHa0Vm-j_77oqT3BlbkFJG--HBmDoS18CUIXsz5dbEhim-O_4lFMTewxllN_YQA",
        };

        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });

        if (
          values.caseId !== userData.caseId ||
          values.type !== userData?.deposition
        ) {
          generateDocument({
            id: values.pleadingId,
            title: values?.title,
            formData: data1,
          });
        }
        // notificationService.success("Pleading record has been updated successfully.");
        resetForm();
        setIsSubmitting(true);

        navigate("/Pleading");
      }
      // mutation.mutate(values);
    },
  });

  const getAllCase = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "caseDropdown",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status == 200) {
        setAllCase(response?.data?.data);
      }
    } catch (error) {}
  };
  const getAllDepositon = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          `pleadingGenerate/depositionDropdown/${formik?.values.caseId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setAllDocument(response.data);
      }
    } catch (error) {}
  };

  const queryClient = useQueryClient();
  const { setBearerToken, put } = useAxios();
  const setClientData = async (newData) => {
    setBearerToken(token);
    const response = await put(`/pleadingGenerate`, newData);
    return response.data;
  };

  const mutation = useMutation(setClientData, {
    onSuccess: () => {
      showSuccessToastMessage("Record is successfull updated!");
      navigate("/pleading");
      queryClient.invalidateQueries("pleadingGenerate");
    },
    onError: () => {
      showErrorToastMessage("Something was wrong!");
    },
  });

  useEffect(() => {
    getAllCase();
    getAllDepositon();
  }, [formik?.values?.caseId]);
  return (
    <Box
      sx={{
        // minHeight: "100vh",
        maxHeight: { xs: 2000, sm: 2000 },
      }}
    >
      <CssBaseline />
      <NewAddHeading
        title="Basic Details"
        subTitle="Document  Information"
        icon={<FeedIcons color="#387CFF" />}
      />

      <Box
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
        sx={{ mt: 3, position: "relative", p: 2 }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              sx={{
                color:
                  formik.touched.case && Boolean(formik.errors?.caseId)
                    ? theme?.palette?.secondary?.dangerMain
                    : theme?.palette?.primary?.main,
                marginBottom: "4px", // Spacing between label and input
                fontFamily: theme?.typography?.fontFamily,
                ...theme?.typography?.inputHeading,
                display: "flex",
                gap: "10px",
              }}
            >
              Matter{" "}
              <Box
                variant="span"
                sx={{
                  color: theme?.palette?.error?.main, // Set the asterisk color to red
                }}
              >
                *
              </Box>
            </Typography>
            <FormControl
              required
              variant="outlined"
              size="small"
              fullWidth
              error={formik.touched.case && Boolean(formik.errors.case)}
              sx={inputDocumentPleadingStyles?.cases(formik)}
            >
              <Autocomplete
                id="case"
                options={allCase}
                disableClearable
                getOptionLabel={(option) => option.title}
                autoComplete
                size="small"
                includeInputInList
                value={
                  allCase.find(
                    (option) => option.title === formik.values.case
                  ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue("case", newValue?.title || "");
                  formik.setFieldValue("caseId", newValue?._id || "");
                  formik.setFieldValue("type", "");
                  formik.setFieldValue("data", "");
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Matter"
                    fullWidth
                    error={formik.touched.case && Boolean(formik.errors.case)}
                    InputLabelProps={{
                      sx: {
                        color: theme?.palette?.primary?.main,
                        ...theme?.typography?.inputPlaceHolder,
                        "&.Mui-focused": {
                          color: theme?.palette?.primary?.main,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        color: theme?.palette?.primary?.main,
                        "& fieldset": {
                          borderColor:
                            formik.touched.case && Boolean(formik.errors.case)
                              ? theme?.palette?.secondary?.dangerMain
                              : theme?.palette?.secondary?.inputColor,
                        },
                        "&:hover fieldset": {
                          borderColor:
                            formik.touched.case && Boolean(formik.errors.case)
                              ? theme?.palette?.secondary?.dangerMain
                              : theme?.palette?.secondary?.main,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor:
                            formik.touched.case && Boolean(formik.errors.case)
                              ? theme?.palette?.secondary?.dangerMain
                              : theme?.palette?.primary?.main,
                        },
                        ...theme?.typography?.inputPlaceHolder,
                      },
                      "& .MuiSvgIcon-root": {
                        color: theme?.palette?.primary?.placeholderColor,
                      },
                    }}
                  />
                )}
                PaperComponent={(props) => (
                  <Paper
                    {...props}
                    {...props}
                    sx={{
                      backgroundColor: "#FFFFFF",
                      color: theme?.palette?.primary?.placeholderColor,
                    }}
                  />
                )}
                ListboxProps={{
                  sx: {
                    maxHeight: 200,
                    overflowY: "auto",
                    "& .MuiAutocomplete-option": {
                      "&:hover": {
                        backgroundColor: "#686DFC26",
                        color: "#686DFC",
                      },
                    },
                    "& .MuiAutocomplete-noOptions": {
                      color: "#FF7799", // Customize the color of the noOptionsText
                    },
                  },
                }}
                noOptionsText="No matter found"
              />
              {allCase.length <= 0 && (
                <FormHelperText
                  sx={{ color: theme?.palette?.secondary?.dangerMain }}
                >
                  No matter is added.
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          {/* deposiiton */}

          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              sx={{
                color:
                  formik.touched.type && Boolean(formik.errors?.type)
                    ? theme?.palette?.secondary?.dangerMain
                    : theme?.palette?.primary?.main,
                marginBottom: "4px", // Spacing between label and input
                fontFamily: theme?.typography?.fontFamily,
                ...theme?.typography?.inputHeading,
                display: "flex",
                gap: "10px",
              }}
            >
              Deposition{" "}
              <Box
                variant="span"
                sx={{
                  color: theme?.palette?.error?.main, // Set the asterisk color to red
                }}
              >
                *
              </Box>
            </Typography>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              required
              error={formik.touched?.type && Boolean(formik.errors?.type)}
              sx={inputDocumentPleadingStyles?.deposition(formik)}
            >
              <Select
                labelId="type-label"
                id="type"
                autoComplete="off"
                name="type"
                value={formik.values.type || ""}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.setFieldValue("type", event.target.value);
                }}
                placeholder="Select Deposition File"
                displayEmpty
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#FFFFFF",
                      color: theme?.palette?.primary?.placeholderColor,
                      maxHeight: 200, // Adjust as needed
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor: "#686DFC26", // Hover background color
                          color: "#686DFC", // Hover text color
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography
                    sx={{
                      fontStyle: "normal",
                      color: theme?.palette?.primary?.dropDownColor,
                    }}
                  >
                    Select Deposition File
                  </Typography>
                </MenuItem>

                {allDocument.map((doc) => (
                  <MenuItem key={doc._id} value={doc._id}>
                    {doc.depositionFile}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.type && formik.errors.type && (
                <FormHelperText>{formik.errors.type}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <TextField
              error={formik.touched.title && formik.errors?.title}
              autoComplete="given-title"
              required
              name="title"
              id="title"
              label="Title"
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.title}
              helperText={formik.touched?.title && formik.errors?.title}
              sx={{
                width: "100%",
                borderRadius: "5px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: "#A6A7C1", // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik.touched.title && Boolean(formik.errors.title) ? "#D32F2F" : "#4A4C64", // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik.touched.title && Boolean(formik.errors.title) ? "#D32F2F" : "#C8C9DB",
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  color: formik.touched?.title && Boolean(formik.errors?.title) ? "#D32F2F" : "#C8C9DB",
                },
              }}
              InputProps={{
                style: {
                  color: "#A6A7C1",
                },
              }}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <LabeledTextField
              formik={formik}
              name="title"
              label="Title"
              placeholder="Title"
              required={true}
              inputStyles={inputDocumentPleadingStyles?.title(formik)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}
        >
          '{" "}
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row-reverse" },
              gap: 1,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              sx={inputDocumentPleadingStyles?.submit}
            >
              Generate
            </Button>

            <ButtonUICancel
              text={"Cancel"}
              onClick={() => navigate("/pleading")}
              variant={"contined"}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UpdateGeneratePleadingForm;
