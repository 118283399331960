// import React, { createContext, useContext, useState } from "react";
// import {
//   CompleteMultipartUploadCommand,
//   CreateMultipartUploadCommand,
//   S3Client,
//   UploadPartCommand,
// } from "@aws-sdk/client-s3";
// import { useAxios } from "../services/http.service";
// import {
//   fetchDepositionData,
//   fetchTokenAndDeleteEmbedding,
//   fetchTokenAndPostChatbot,
// } from "../services/http.chatbot.service";
// import axios from "axios";
// import notificationService from "../services/notification.service";
// import { useQueryClient } from "react-query";
// import { useSelector } from "react-redux";
// import {
//   AWS_ACCESS_KEY_ID,
//   AWS_REGION,
//   AWS_BUCKET_NAME,
//   AWS_SECRET_ACCESS_KEY,
// } from "../utils/config";
// import { AssistWalker } from "@mui/icons-material";

// const CHUNK_SIZE = 5 * 1024 * 1024;

// const MultiFileUploadContext = createContext(null);

// export const useMultiFileUpload = () => {
//   const context = useContext(MultiFileUploadContext);
//   if (context === null) {
//     throw new Error(
//       "useMultiFileUpload must be used within a MultiFileUploadProvider"
//     );
//   }
//   return context;
// };

// export const MultiFileUploadProvider = ({ children, token }) => {
//   const [uploads, setUploads] = useState([]);
//   const [isGenerated, setIsGenerated] = useState(false);
//   const [matter, setMatter] = useState();
//   const [allChat, setAllChat] = useState([]);
//   const [chatHistory, setChatHistory] = useState(null);
//   const [openDropdown, setOpenDropdown] = useState("dashboard");
//   const { user } = useSelector((state) => state.reducer);
//   const [isView, setIsView] = useState(false);
//   const { setBearerToken, post } = useAxios();
//   const queryClient = useQueryClient();

//   const s3 = new S3Client({
//     region: AWS_REGION,
//     credentials: {
//       accessKeyId: AWS_ACCESS_KEY_ID,
//       secretAccessKey: AWS_SECRET_ACCESS_KEY,
//     },
//   });

//   const uploadFiles = async ({
//     id,
//     title,
//     file,
//     url,
//     uniquePath,
//     formData,
//     oldUrl,
//     openKey,
//   }) => {
//     try {
//       const existingUploadIndex = uploads.findIndex(
//         (upload) => upload.id === id
//       );

//       if (existingUploadIndex !== -1) {
//         console.warn("File with same ID is already being uploaded");
//         return;
//       }

//       setUploads((prevUploads) => [
//         ...prevUploads,
//         { id, title, progress: 0, file, uniquePath },
//       ]);

//       const params = {
//         Bucket: AWS_BUCKET_NAME,
//         Key: uniquePath,
//         ContentType: file.type,
//       };

//       const data = await s3.send(new CreateMultipartUploadCommand(params));
//       const uploadId = data.UploadId;
//       let totalUploaded = 0;
//       const parts = [];

//       for (
//         let i = 0, partNumber = 1;
//         i < file.size;
//         i += CHUNK_SIZE, partNumber++
//       ) {
//         const chunk = file.slice(i, CHUNK_SIZE + i);
//         const partParams = {
//           Bucket: AWS_BUCKET_NAME,
//           Key: uniquePath,
//           PartNumber: partNumber,
//           UploadId: uploadId,
//           Body: chunk,
//         };

//         const res = await s3.send(new UploadPartCommand(partParams));
//         totalUploaded += chunk.size;

//         const progress = (totalUploaded / file.size) * 100;
//         parts.push({ ETag: res.ETag, PartNumber: partNumber });

//         setUploads((prevUploads) => {
//           return prevUploads.map((upload) =>
//             upload.id === id
//               ? { ...upload, progress: Math.round(progress) }
//               : upload
//           );
//         });
//       }

//       const completeParams = {
//         Bucket: AWS_BUCKET_NAME,
//         Key: uniquePath,
//         UploadId: uploadId,
//         MultipartUpload: { Parts: parts },
//       };

//       await s3.send(new CompleteMultipartUploadCommand(completeParams));
//       let response = await succesStatus(url, id, formData, oldUrl);

//       return response;
//     } catch (error) {
//       console.error("Error uploading file:", error);
//     }
//   };
//   const embeddingTypeMap = {
//     "Single Page": "single_page",
//     "Entire Document": "entire_document",
//     "Page Range": "page_range",
//   };
//   const succesStatus = async (url, id, formData, oldUrl, openKey) => {
//     setBearerToken(token);
//     setUploads((prevUploads) =>
//       prevUploads.filter((upload) => upload.id !== id)
//     );

//     const response = await post(url, { id });

//     if (response && response.data) {
//       if (
//         url === "status/deposition" ||
//         url === "/status/exhibit" ||
//         url === "status/discovery" ||
//         url === "status/medical" ||
//         url === "status/pleadings"
//       ) {
//         const isImageUrl = (url) => {
//           const imageExtensions = [
//             "jpg",
//             "jpeg",
//             "png",
//             "gif",
//             "bmp",
//             "webp",
//             "svg",
//           ];
//           const urlExtension = url.split(".").pop().toLowerCase();
//           return imageExtensions.includes(urlExtension);
//         };

//         const apiData = {
//           file: isImageUrl(response.data.fileUrl)
//             ? null
//             : response.data.fileUrl,
//           image: isImageUrl(response.data.fileUrl)
//             ? response.data.fileUrl
//             : null,
//           organization: user?.organizationData?._id,

//           embeddingType: formData?.embeddingType
//             ? embeddingTypeMap[formData.embeddingType]
//             : [],
//           matter_id: response.data.matter_id,
//           citation: formData?.citation ? formData?.citation : "",
//           separator:
//             formData?.embeddingType === "Page Range"
//               ? formData.pageRange[0].split(",").map((item) => item.trim())
//               : [],
//           api_key:
//             openKey ||
//             "sk-rVLvFpg5-Fdc6Q3p2oSzHsOVfixz2XHa0Vm-j_77oqT3BlbkFJG--HBmDoS18CUIXsz5dbEhim-O_4lFMTewxllN_YQA",
//         };

//         // deposition post body
//         const depositionData = {
//           file: response.data.fileUrl,

//           organization: user?.organizationData?._id,

//           matter_id: [response.data.matter_id],
//           citation: formData?.citation ? formData?.citation : "",
//           witness: formData?.witness ? formData?.witness : "",
//           thread_id: "",
//           max_tokens: "1000",

//           assistant_id: "asst_2PfbZgOGLP9M7Enf9yItXYSr",
//           api_key:
//             openKey ||
//             "sk-rVLvFpg5-Fdc6Q3p2oSzHsOVfixz2XHa0Vm-j_77oqT3BlbkFJG--HBmDoS18CUIXsz5dbEhim-O_4lFMTewxllN_YQA",
//         };

//         const deleteApiData = {
//           file: oldUrl,
//           organization: user?.organizationData?._id,
//         };

//         const getURl = [
//           {
//             url: "status/deposition",
//             endpoint: "/depositions/add",
//             endpointDelete: "/depositions/delete",
//           },
//           {
//             url: "/status/exhibit",
//             endpoint: "/exhibit/add",
//             endpointDelete: "/exhibit/delete",
//           },
//           {
//             url: "status/discovery",
//             endpoint: "/discovery/add",
//             endpointDelete: "/discovery/delete",
//           },
//           {
//             url: "status/medical",
//             endpoint: "/medical/add",
//             endpointDelete: "/medical/delete",
//           },
//           {
//             url: "status/pleadings",
//             endpoint: "/pleading/add",
//             endpointDelete: "/pleading/delete",
//           },
//         ];
//         const endpoint = getURl.find((v) => v.url === url).endpoint;
//         const endpointDelete = getURl.find((v) => v.url === url).endpointDelete;

//         // Call the external API
//         const externalApiResponse = await fetchTokenAndPostChatbot(
//           endpoint,
//           apiData,
//           depositionData
//         );

//         if (oldUrl) {
//           const deletApi = await fetchTokenAndDeleteEmbedding(
//             endpointDelete,
//             deleteApiData
//           );
//         }
//         const token = user?.userData?.token;
//         setBearerToken(token);
//         await post(`tokenUsage`, {
//           organizationMemberId: user?.organizationData?.organizationMemberId,
//           organizationId: user?.organizationData?._id,
//           tokenUsage:
//             externalApiResponse?.data?.prompt_tokens?.embedding_tokens,
//           tokenType: "embedding",
//           tokenPrice: externalApiResponse?.data?.prompt_tokens?.tokens_price,
//         });

//         // Debugging the response
//         //  const depositionAPIResponse = await fetchDepositionData(
//         //           endpoint,
//         //           depositionData
//         //         );
//         // console.log("depositionAPIResponse", depositionAPIResponse);
//         // Check if the external API call was successful
//         if (externalApiResponse.status === 200) {
//           return response;
//         } else {
//           return response;
//         }
//       }
//     }
//   };

//   const generateDocument = async (data) => {
//     try {
//       const { id, title, formData } = data;

//       const config = {
//         onUploadProgress: (progressEvent) => {
//           setIsGenerated(true);
//           const percentCompleted = Math.round(
//             (progressEvent.loaded * 100) / progressEvent.total
//           );
//           updateUploadProgress(id, percentCompleted, title);
//         },
//       };

//       const response = await axios.post(
//         `https://api.soon.magicpleadings.ai/make/process-pdf`,
//         formData,
//         config
//       );

//       if (response.data) {
//         // If response contains data, update the upload status to "success"
//         updateUploadStatus(id, "success", response.data, title);

//         const deepCopy = JSON.parse(JSON.stringify(response.data));

//         const generatedData = {
//           id: id,
//           docx_file_url: response?.data?.docx_file_url,
//           excel_file_url: response?.data?.excel_file_url,
//           status: "generated",
//         };

//         try {
//           const res = await post("/status/pleadingGenerate", generatedData);

//           if (res && res.data) {
//             if (deepCopy === "Accepted") {
//               notificationService.error("File generation failed.");
//             } else {
//               notificationService.success(
//                 "Files have been generated successfully."
//               );
//             }

//             setIsView(false);
//             setIsGenerated(false);
//             queryClient.invalidateQueries("pleadingGenerate");
//           } else {
//             // If the API call fails, update the status to "failed"
//             await post("/status/pleadingGenerate", {
//               id: id,
//               status: "failed",
//             });
//             notificationService.error("File generation has failed.");
//             setIsGenerated(false);
//           }
//         } catch (error) {
//           console.error("Error during API call:", error);
//           setIsGenerated(false);
//           notificationService.error("Error generating file.");
//           // Send "failed" status to the API if an error occurs
//           await post("/status/pleadingGenerate", {
//             id: id,
//             status: "failed",
//           });
//         }
//       } else {
//         // If no data in the response, send failed status
//         console.error("No data in response.");
//         await post("/status/pleadingGenerate", {
//           id: id,
//           status: "failed",
//         });
//         setIsGenerated(false);
//         notificationService.error("Missing file1 or file2 in response data.");
//       }

//       return response.data;
//     } catch (error) {
//       console.error("Error generating document:", error);
//       // Send "failed" status if the whole process fails
//       await post("/status/pleadingGenerate", {
//         id: data.id,
//         status: "failed",
//       });
//       notificationService.error("File generation has failed.");
//       setIsGenerated(false);
//       throw error;
//     }
//   };

//   const updateUploadProgress = (id, progress, title) => {
//     setUploads((prevUploads) =>
//       prevUploads.map((upload) =>
//         upload.id === id ? { ...upload, progress, title } : upload
//       )
//     );
//   };

//   const updateUploadStatus = (id, status, data = null, title) => {
//     setUploads((prevUploads) =>
//       prevUploads.map((upload) =>
//         upload.id === id ? { ...upload, status, data, title } : upload
//       )
//     );
//   };

//   return (
//     <MultiFileUploadContext.Provider
//       value={{
//         uploads,
//         setUploads,
//         uploadFiles,
//         generateDocument,
//         matter,
//         setMatter,
//         allChat,
//         setAllChat,
//         chatHistory,
//         setChatHistory,
//         openDropdown,
//         setOpenDropdown,
//         isGenerated,
//       }}
//     >
//       {children}
//     </MultiFileUploadContext.Provider>
//   );
// };

import React, { createContext, useContext, useState } from "react";
import {
  CompleteMultipartUploadCommand,
  CreateMultipartUploadCommand,
  S3Client,
  UploadPartCommand,
} from "@aws-sdk/client-s3";
import { useAxios } from "../services/http.service";
import {
  fetchDepositionData,
  fetchTokenAndDeleteEmbedding,
  fetchTokenAndPostChatbot,
} from "../services/http.chatbot.service";
import axios from "axios";
import notificationService from "../services/notification.service";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import {
  AWS_ACCESS_KEY_ID,
  AWS_REGION,
  AWS_BUCKET_NAME,
  AWS_SECRET_ACCESS_KEY,
} from "../utils/config";
import { AssistWalker } from "@mui/icons-material";

const CHUNK_SIZE = 5 * 1024 * 1024;

const MultiFileUploadContext = createContext(null);

export const useMultiFileUpload = () => {
  const context = useContext(MultiFileUploadContext);
  if (context === null) {
    throw new Error(
      "useMultiFileUpload must be used within a MultiFileUploadProvider"
    );
  }
  return context;
};

export const MultiFileUploadProvider = ({ children, token }) => {
  const [uploads, setUploads] = useState([]);
  const [isGenerated, setIsGenerated] = useState(false);
  const [matter, setMatter] = useState();
  const [allChat, setAllChat] = useState([]);
  const [chatHistory, setChatHistory] = useState(null);
  const [openDropdown, setOpenDropdown] = useState("dashboard");
  const { user } = useSelector((state) => state.reducer);
  console.log(user);

  const [isView, setIsView] = useState(false);
  const { setBearerToken, post } = useAxios();
  const queryClient = useQueryClient();

  const s3 = new S3Client({
    region: AWS_REGION,
    credentials: {
      accessKeyId: AWS_ACCESS_KEY_ID,
      secretAccessKey: AWS_SECRET_ACCESS_KEY,
    },
  });

  const uploadFiles = async ({
    id,
    title,
    file,
    url,
    uniquePath,
    formData,
    oldUrl,
  }) => {
    try {
      const existingUploadIndex = uploads.findIndex(
        (upload) => upload.id === id
      );
      if (existingUploadIndex !== -1) {
        console.warn("File with same ID is already being uploaded");
        return;
      }

      setUploads((prevUploads) => [
        ...prevUploads,
        { id, title, progress: 0, file, uniquePath },
      ]);

      const params = {
        Bucket: AWS_BUCKET_NAME,
        Key: uniquePath,
        ContentType: file.type,
      };

      const data = await s3.send(new CreateMultipartUploadCommand(params));
      const uploadId = data.UploadId;
      let totalUploaded = 0;
      const parts = [];

      for (
        let i = 0, partNumber = 1;
        i < file.size;
        i += CHUNK_SIZE, partNumber++
      ) {
        const chunk = file.slice(i, CHUNK_SIZE + i);
        const partParams = {
          Bucket: AWS_BUCKET_NAME,
          Key: uniquePath,
          PartNumber: partNumber,
          UploadId: uploadId,
          Body: chunk,
        };

        const res = await s3.send(new UploadPartCommand(partParams));
        totalUploaded += chunk.size;

        const progress = (totalUploaded / file.size) * 100;
        parts.push({ ETag: res.ETag, PartNumber: partNumber });

        setUploads((prevUploads) => {
          return prevUploads.map((upload) =>
            upload.id === id
              ? { ...upload, progress: Math.round(progress) }
              : upload
          );
        });
      }

      const completeParams = {
        Bucket: AWS_BUCKET_NAME,
        Key: uniquePath,
        UploadId: uploadId,
        MultipartUpload: { Parts: parts },
      };

      await s3.send(new CompleteMultipartUploadCommand(completeParams));
      let response = await succesStatus(url, id, formData, oldUrl);

      return response;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  const embeddingTypeMap = Object.fromEntries([
    ["Single Page", "single_page"],
    ["Entire Document", "entire_document"],
    ["Page Range", "page_range"],
    ["None", "single_page"],
  ]);
  // const embeddingTypeMap = {
  //   "Single Page": "single_page",
  //   "Entire Document": "entire_document",
  //   "Page Range": "page_range",
  //   None: "single_page",
  // };
  const succesStatus = async (url, id, formData, oldUrl) => {
    setBearerToken(token);
    setUploads((prevUploads) =>
      prevUploads.filter((upload) => upload.id !== id)
    );
    let response;
    if (url == "status/medical") {
      setBearerToken(user?.userData?.token);

      response = await post(url, {
        id,
        organization: user.organizationData._id,
        embeddingType: formData.embeddingType,
        separator: formData.pageRange,
        api_key: user.organizationData?.API_KEY,
        assistant_id: user.organizationData?.medicalTags,
        citation: formData?.citation,
        matter_id: formData?.caseId,
      });
    } else {
      response = await post(url, { id });
    }

    if (response && response.data) {
      if (
        url === "status/deposition" ||
        url === "/status/exhibit" ||
        url === "status/discovery" ||
        url === "status/medical" ||
        url === "status/pleadings"
      ) {
        const isImageUrl = (url) => {
          const imageExtensions = [
            "jpg",
            "jpeg",
            "png",
            "gif",
            "bmp",
            "webp",
            "svg",
          ];
          const urlExtension = url.split(".").pop().toLowerCase();
          return imageExtensions.includes(urlExtension);
        };

        const apiData = {
          file: isImageUrl(response.data.fileUrl)
            ? null
            : response.data.fileUrl,
          image: isImageUrl(response.data.fileUrl)
            ? response.data.fileUrl
            : null,
          organization: user?.organizationData?._id,

          embeddingType: formData?.embeddingType
            ? embeddingTypeMap[formData.embeddingType]
            : [],
          matter_id: response.data.matter_id,
          citation: formData?.citation ? formData?.citation : "",
          separator:
            formData?.embeddingType === "Page Range"
              ? formData.pageRange[0].split(",").map((item) => item.trim())
              : [],
          api_key:
            "sk-rVLvFpg5-Fdc6Q3p2oSzHsOVfixz2XHa0Vm-j_77oqT3BlbkFJG--HBmDoS18CUIXsz5dbEhim-O_4lFMTewxllN_YQA",
        };

        // deposition post body
        const depositionData = {
          file: response.data.fileUrl,

          organization: user?.organizationData?._id,

          matter_id: [response.data.matter_id],
          citation: formData?.citation ? formData?.citation : "",
          witness: formData?.witness ? formData?.witness : "",
          thread_id: "",
          max_tokens: "1000",

          assistant_id: "asst_2PfbZgOGLP9M7Enf9yItXYSr",
          api_key:
            "sk-rVLvFpg5-Fdc6Q3p2oSzHsOVfixz2XHa0Vm-j_77oqT3BlbkFJG--HBmDoS18CUIXsz5dbEhim-O_4lFMTewxllN_YQA",
        };

        const deleteApiData = {
          file: oldUrl,
          organization: user?.organizationData?._id,
        };

        const getURl = [
          {
            url: "status/deposition",
            endpoint: "/depositions/add",
            endpointDelete: "/depositions/delete",
          },
          {
            url: "/status/exhibit",
            endpoint: "/exhibit/add",
            endpointDelete: "/exhibit/delete",
          },
          {
            url: "status/discovery",
            endpoint: "/discovery/add",
            endpointDelete: "/discovery/delete",
          },
          {
            url: "status/medical",
            endpoint: "/medical/add",
            endpointDelete: "/medical/delete",
          },
          {
            url: "status/pleadings",
            endpoint: "/pleading/add",
            endpointDelete: "/pleading/delete",
          },
        ];
        const endpoint = getURl.find((v) => v.url === url).endpoint;
        console.log(endpoint, "endpoint");
        const endpointDelete = getURl.find((v) => v.url === url).endpointDelete;

        // Call the external API
        const externalApiResponse = await fetchTokenAndPostChatbot(
          endpoint,
          apiData,
          depositionData
        );

        if (oldUrl) {
          const deletApi = await fetchTokenAndDeleteEmbedding(
            endpointDelete,
            deleteApiData
          );
        }
        const token = user?.userData?.token;
        setBearerToken(token);
        await post(`tokenUsage`, {
          organizationMemberId: user?.organizationData?.organizationMemberId,
          organizationId: user?.organizationData?._id,
          tokenUsage:
            externalApiResponse?.data?.prompt_tokens?.embedding_tokens,
          caseId: formData?.caseId,
          tokenType: "embedding",
          tokenPrice: externalApiResponse?.data?.prompt_tokens?.tokens_price,
        });

        // Debugging the response
        if (url === "status/deposition") {
          try {
            const depositionAPIResponse = await fetchDepositionData(
              endpoint,
              depositionData
            );

            console.log(
              "depositionAPIResponse and called",
              depositionAPIResponse
            );

            if (depositionAPIResponse && depositionAPIResponse.final_answer) {
              const depositionData = {
                depositionId: depositionAPIResponse.final_answer.Deposition_ID,
                deponentName:
                  depositionAPIResponse.final_answer.Deponent_Name || "Unknown",
                pageAnalysis:
                  depositionAPIResponse.final_answer.Page_Analysis.map(
                    (item) => ({
                      factualStatement: item.Factual_Statement,
                      issueTags: item.Relevant_Issue_Tags || [],
                      pageNumber: item.Page_Number,
                    })
                  ),
                testimonySummary:
                  depositionAPIResponse.testimony_summary ||
                  "No summary provided",
              };

              // API call to save deposition data
              console.log("deposition", id, "depositionData", depositionData);
              const saveResponse = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}depositionUpdate/${id}`,
                depositionData
              );

              if (saveResponse?.data) {
                console.log(
                  "Deposition data saved successfully:",
                  saveResponse.data
                );
              } else {
                console.error("Failed to save deposition data");
              }
            }
          } catch (error) {
            console.error("Error handling deposition data:", error);
          }
        }

        // Check if the external API call was successful
        if (externalApiResponse.status === 200) {
          return response;
        } else {
          return response;
        }
      }
    }
  };

  const generateDocument = async (data) => {
    try {
      const { id, title, formData } = data;

      const config = {
        onUploadProgress: (progressEvent) => {
          setIsGenerated(true);
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          updateUploadProgress(id, percentCompleted, title);
        },
      };

      const response = await axios.post(
        `https://api.soon.magicpleadings.ai/make/process-pdf`,
        formData,
        config
      );

      if (response.data) {
        // If response contains data, update the upload status to "success"
        updateUploadStatus(id, "success", response.data, title);

        const deepCopy = JSON.parse(JSON.stringify(response.data));

        const generatedData = {
          id: id,
          docx_file_url: response?.data?.docx_file_url,
          excel_file_url: response?.data?.excel_file_url,
          status: "generated",
        };

        try {
          const res = await post("/status/pleadingGenerate", generatedData);

          if (res && res.data) {
            if (deepCopy === "Accepted") {
              notificationService.error("File generation failed.");
            } else {
              notificationService.success(
                "Files have been generated successfully."
              );
            }

            setIsView(false);
            setIsGenerated(false);
            queryClient.invalidateQueries("pleadingGenerate");
          } else {
            // If the API call fails, update the status to "failed"
            await post("/status/pleadingGenerate", {
              id: id,
              status: "failed",
            });
            notificationService.error("File generation has failed.");
            setIsGenerated(false);
          }
        } catch (error) {
          console.error("Error during API call:", error);
          setIsGenerated(false);
          notificationService.error("Error generating file.");
          // Send "failed" status to the API if an error occurs
          await post("/status/pleadingGenerate", {
            id: id,
            status: "failed",
          });
        }
      } else {
        // If no data in the response, send failed status
        console.error("No data in response.");
        await post("/status/pleadingGenerate", {
          id: id,
          status: "failed",
        });
        setIsGenerated(false);
        notificationService.error("Missing file1 or file2 in response data.");
      }

      return response.data;
    } catch (error) {
      console.error("Error generating document:", error);
      // Send "failed" status if the whole process fails
      await post("/status/pleadingGenerate", {
        id: data.id,
        status: "failed",
      });
      notificationService.error("File generation has failed.");
      setIsGenerated(false);
      throw error;
    }
  };

  const updateUploadProgress = (id, progress, title) => {
    setUploads((prevUploads) =>
      prevUploads.map((upload) =>
        upload.id === id ? { ...upload, progress, title } : upload
      )
    );
  };

  const updateUploadStatus = (id, status, data = null, title) => {
    setUploads((prevUploads) =>
      prevUploads.map((upload) =>
        upload.id === id ? { ...upload, status, data, title } : upload
      )
    );
  };

  return (
    <MultiFileUploadContext.Provider
      value={{
        uploads,
        setUploads,
        uploadFiles,
        generateDocument,
        matter,
        setMatter,
        allChat,
        setAllChat,
        chatHistory,
        setChatHistory,
        openDropdown,
        setOpenDropdown,
        isGenerated,
      }}
    >
      {children}
    </MultiFileUploadContext.Provider>
  );
};
