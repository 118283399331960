import React from "react";

function ChatDisabledIcon() {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="22.5" r="22.5" fill="#F2F3FB" />
      <path
        d="M14.01 32L35 23L14.01 14L14 21L29 23L14 25L14.01 32Z"
        fill="#8A8BA1"
      />
    </svg>
  );
}

export default ChatDisabledIcon;
