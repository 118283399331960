import React from "react";
import { Formik, Form } from "formik";
import { Box, Paper, Button } from "@mui/material";
import LoaderIcon from "../../Icons/LoaderIcon";
import styles from "./styles";
import { ButtonUICancel, ButtonUIPrevious } from "../../Button";
import useSettlementForm from "./useSettlementsForm";
import { SettlementsRenderStep } from "../../Validation/SettlementValidation";
const SettlementsForm = ({ activeStep, steps, handleBack, handleNext }) => {
  const { initialValues, handleSubmit, isSubmitting, navigate, currentValidationSchema } = useSettlementForm(activeStep, handleNext);
  return (
    <Formik initialValues={initialValues} validationSchema={currentValidationSchema} onSubmit={handleSubmit} enableReinitialize>
      {(formik) => (
        <Form>
          <Paper sx={styles.paper}>
            {SettlementsRenderStep(activeStep, formik)}
            {console.log("formik of settlements:", formik?.values)}
            <Box sx={styles.box}>
              {activeStep === 0 && <ButtonUICancel text={"Cancel"} onClick={() => navigate("/settlements")} variant={"contined"} />}
              {activeStep > 0 && activeStep < 3 && <ButtonUIPrevious variant="outlined" text={"Previous"} onClick={handleBack} disabled={activeStep === 0} />}
              <Box sx={{ flex: "1 1 auto" }} />

              <Box sx={{ flex: "1 1 auto" }} />

              <Button type="submit" disabled={isSubmitting} variant="contained" sx={styles?.submitButton}>
                {activeStep === steps.length - 1 && isSubmitting ? (
                  <>
                    <LoaderIcon color="#f7f7f7" /> Processing...
                  </>
                ) : activeStep === steps.length - 1 ? (
                  "Finish"
                ) : (
                  "Next"
                )}
              </Button>
            </Box>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default SettlementsForm;
