import React from "react";

const QueriesidebarIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.13426 26.5574H25.8682C28.4927 26.5574 29.8111 25.2519 29.8111 22.6655V7.33386C29.8111 4.74743 28.4927 3.44189 25.8682 3.44189H4.13426C1.52266 3.44243 0.191406 4.73564 0.191406 7.33386V22.666C0.191406 25.2647 1.52266 26.5574 4.13426 26.5574ZM4.17176 24.5367C2.91605 24.5367 2.21319 23.8714 2.21319 22.5653V7.43564C2.21319 6.12957 2.91605 5.46422 4.17176 5.46422H9.75926V24.5367H4.17176ZM25.8307 5.46422C27.0736 5.46422 27.7893 6.12957 27.7893 7.43564V22.5642C27.7893 23.8703 27.0736 24.5356 25.8307 24.5356H11.7307V5.46368L25.8307 5.46422ZM7.34855 10.1228C7.72516 10.1228 8.06426 9.78368 8.06426 9.41939C8.06426 9.04279 7.72516 8.71654 7.34855 8.71654H4.64855C4.27248 8.71654 3.94569 9.04279 3.94569 9.41939C3.94569 9.78368 4.27248 10.1228 4.64909 10.1228H7.34855ZM7.34855 13.3746C7.72516 13.3746 8.06426 13.0355 8.06426 12.6589C8.06426 12.2822 7.72516 11.9678 7.34855 11.9678H4.64855C4.27248 11.9678 3.94569 12.2822 3.94569 12.6589C3.94569 13.0355 4.27248 13.3746 4.64909 13.3746H7.34855ZM7.34855 16.614C7.72516 16.614 8.06426 16.3001 8.06426 15.923C8.06426 15.5469 7.72516 15.2201 7.34855 15.2201H4.64855C4.27248 15.2201 3.94569 15.5469 3.94569 15.9235C3.94569 16.3001 4.27248 16.614 4.64909 16.614H7.34855Z"
        fill="#387CFF"
      />
    </svg>
  );
};

export default QueriesidebarIcon;
