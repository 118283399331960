import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { theme } from "../../constant/Theme";
import LadyJustic from "../../Components/Icons/LadyJustic";
const defaultTheme = createTheme();

const ForgetPassword = () => {
  const navigate = useNavigate();

  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };

  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address.").required("Required"),
    }),

    onSubmit: async (values) => {
      try {
        const formData = new FormData();

        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });

        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "adminForgetpassword", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response?.data) {
          const { token } = response?.data;
          localStorage.setItem("forget_token", token);
          navigate("/login");
          showSuccessToastMessage("Kindly check your email for the verification link.");
        }
        // Handle successful response
      } catch (error) {
        showErrorToastMessage("Please use correct email and try again.");
      }
    },
  });

  React.useEffect(() => {
    if (localStorage.getItem("tokenExpire")) {
      showErrorToastMessage("Sorry! token Expires please try again");
      localStorage.removeItem("tokenExpire");
    }
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
   
      <Box
        sx={{
          minHeight: "100vh",
          maxHeight: { xs: 1000, sm: 1150 },
         
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme?.palette?.primary?.loginBackground,
        }}
      >
        {/* // main form  */}
          <Container component="main" maxWidth="xs" sx={{ paddingY: 2 }}>
          <CssBaseline />
          <Box
            sx={{
           
              boxShadow: "0px 0px 20px 2px rgba(0, 0, 0, 0.05)",
              padding: "30px",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "15px",
              backgroundColor: theme?.palette?.primary?.bodyColor,
              color: theme?.palette?.primary?.textColor,
            }}
          >
            <Link to={"/"}>
              <Avatar alt="Logo" sx={{ m: 2, width: 65, height: 65, cursor: "pointer" }} src="magic_pleadings.png" />
            </Link>
            <Typography component="h4" variant="h4" sx={{ fontFamily: theme?.palette?.typography?.title }}>
              Forgot Password
            </Typography>
            <Typography
              component="body2"
              variant="body2"
              sx={{
                fontFamily: theme?.palette?.typography?.bodyText,
                color: theme?.palette?.primary?.placeholderColor,
                textAlign: "center",
              }}
            >
              Enter your email and we’ll send you instructions to reset your password
            </Typography>

            <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={formik.touched.email && formik.errors?.email}
                    required
                    fullWidth
                    id="email"
                    name="email"
                    label="  Email Address"
                    variant="filled"
                    autoComplete="email"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    helperText={formik.touched?.email && formik.errors?.email}
                    // sx={{ backgroundColor: "white", borderRadius: "5px" }}
                    InputProps={{
                      disableUnderline: true, // Removes underline to enhance the filled look
                      sx: {
                        backgroundColor: "#F9FAFB",
                        color: theme?.palette?.primary?.placeholderColor,
                        outline: "1px solid #E6E7F2",
                        borderRadius: "5px",
                        transition: "background-color 0.3s ease", // Smooth transition for hover
                        "&:hover": {
                          backgroundColor: "#F9FAFB", // Background color on hover
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ pt: 0, pl: 2 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      // fontFamily: "poppins",
                      mt: 3,
                      mb: 2,

                      boxShadow: 0,
                      "&:hover, &:focus": {
                        boxShadow: 0,
                        backgroundColor: theme?.palette?.primary?.btnPrimary,
                      },
                      backgroundColor: theme?.palette?.primary?.btnPrimary,
                      fontWeight: "600",
                    }}
                  >
                    Send reset link
                  </Button>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Link
                  to={"/login"}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                  variant="body2"
                >
                  <ArrowBackIosIcon
                    sx={{
                      width: "15px",
                      height: "15px",
                      color: theme?.palette?.primary?.btnPrimary,
                    }}
                  />
                  <Grid
                    item
                    sx={{
                      fontSize: { xs: "16px", sm: "14px" },
                      color: theme?.palette?.primary?.btnPrimary,
                      // fontFamily: "poppins",
                    }}
                  >
                    Back to login
                  </Grid>
                </Link>
              </Grid>
            </Box>
          </Box>
        </Container>
       
   {/* // Image  */}
   <Box 
  sx={{ 
    position: "absolute", 
    top: "19.9rem", 
    left: ".5rem", 
    "@sm": { display: "none" } 
  }} 
>
        <LadyJustic />
</Box>
{/* // CopyWrite section  */}
        <Box
          sx={{
            display: "flex",
            // width: "60%",
            flexDirection: { xs: "column", sm: "row" },
            textAlign: { xs: "center", sm: "" },
            width: { xs: "100%", md: "60%" },
            marginX: { xs: "10px", md: "0px" },
            justifyContent: { xs: "space-around", md: "space-between" },
            alignItems: { xs: "center", md: "end" },
            // justifyContent: "space-between",
            
          }}
        >
          <Typography
            component="body2"
            variant="body2"
            sx={{ fontFamily: theme?.palette?.typography?.title }}
          >
            Copyright © 2024 | DocuLex. All rights reserved.
          </Typography>
          <Typography
            component="body2"
            variant="body2"
            sx={{ fontFamily: theme?.palette?.typography?.title }}
          >
            Privacy Policy | Terms of Condition
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ForgetPassword;
