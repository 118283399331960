export const state = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "Baker Island",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Howland Island",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Jarvis Island",
  "Johnston Atoll",
  "Kansas",
  "Kentucky",
  "Kingman Reef",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Midway Atoll",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Navassa Island",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palmyra Atoll",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "United States Minor Outlying Islands",
  "United States Virgin Islands",
  "Utah",
  "Vermont",
  "Virginia",
  "Wake Island",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

// here explain the type

export const courtTypes = [
  "Supreme Court of the United States",
  "United States Courts of Appeals (Circuit Courts)",
  "United States District Courts",
  "United States Court of International Trade",
  "United States Tax Court",
  "Military Courts (Courts-Martial)",
  "State Courts",
  "Specialized Courts",
  "Tribal Courts ",
];

export const cardComponent = [
  { id: 1, heading: "Attorney Seats", subtitle: "Total Seats" },
  { id: 1, heading: "Staff Seats", subtitle: "Total Seats" },
];
