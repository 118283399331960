import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Typography } from "@mui/material";
import { theme } from "../../../constant/Theme";
import { FilterCancelBtn, FilterSubmitBtn } from "../../Button";

const ConfirmationModel = ({ handleLogoutConfirm, onClose }) => {
  return (
    <Box onClose={onClose}>
      <Grid container spacing={{ xs: 0, sm: 1 }}>
        <Grid item xs={11} sm={11}>
          <Typography variant="h4" sx={{ color: theme?.palette?.primary?.main, fontFamily: theme?.typography?.fontFamily, ...theme?.typography?.title, textAlign: "center", marginLeft: "15px" }}>
            Are you sure?
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
          <Typography variant="p" sx={{ color: theme?.palette?.primary?.main, fontFamily: theme?.typography?.fontFamily, ...theme?.typography?.subheading }}>
            Are you sure you want to log out?{" "}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            marginTop: 2,
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            gap: 1,
          }}
        >
          <FilterSubmitBtn title={"yes"} onClick={handleLogoutConfirm} />
          <FilterCancelBtn onClick={onClose} text={"No"} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConfirmationModel;
