import { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import NotificationService from "../../../services/notification.service";

import { useMultiFileUpload } from "../../../context/useMultiFileUploade";
import useStorageValidation from "../../../hooks/useStorageValidation";
import moment from "moment";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { validationSchemaStepOneSettlements, validationSchemaStepThreeSettlements, validationSchemaStepTwoSettlements } from "../../Validation/SettlementValidation";
import { useAxios } from "../../../services/http.service";
import { AWS_ACCESS_KEY_ID } from './../../../utils/config';


const useSettlementForm = (activeStep, handleNext) => {
  dayjs.extend(utc);
  const validationSchemas = [validationSchemaStepOneSettlements, validationSchemaStepTwoSettlements, validationSchemaStepThreeSettlements];
  const currentValidationSchema = validationSchemas[activeStep];
  const { user } = useSelector((state) => state.reducer);
  const { setBearerToken, post, put } = useAxios();
  const navigate = useNavigate();
  const { uploadFiles, uploads } = useMultiFileUpload();
  const { validateAndUpload } = useStorageValidation();
  const location = useLocation();
  const { matter } = useMultiFileUpload();

  const { userData } = location.state || {};


// Initial values for new settlement
const initialValues = useMemo(
  () => ({
    title: "",
    caseId: matter?._id,
    case: matter?.title,
    type: "",
    settlingParty: "",
    personId:"",
    settlingId:"",
    disputedLiability: "",
    amount: "",
    totalMedicalBills: "",  
    totalMedicalBills:"",
    monthTreated: "",
    diagnoseTag: [],
    treatmentsTag: []
  }),
  [matter]
);
console.log(userData, "user data before " )

// Initial values for updating settlement
const initialUpdateValues = useMemo(() => ({
  settlementId: userData?._id || "",
  caseId: userData?.caseId || "",
  case: userData?.case || "",
  type: userData?.type || "",
  personsId: userData?.personsId,

  settlingParty: userData?.settlingParty || "",
  disputedLiability: userData?.disputedLiability || "",
  amount: userData?.amount || "",
  totalMedicalBills: userData?.totalMedicalBills || "",
  monthTreated: userData?.monthTreated || "",
  diagnoseTag: Array.isArray(userData?.diagnoseTag)
      ? userData?.diagnoseTag
      : userData?.diagnoseTag?.split(", ").filter(Boolean) || [],
  treatmentsTag: Array.isArray(userData?.treatmentsTag)
      ? userData?.treatmentsTag
      : userData?.treatmentsTag?.split(", ").filter(Boolean) || []
}), [userData]);

// const initialUpdateValues = useMemo(() => {
//   if (!userData) return initialValues;  // Fallback to initial values if no data
//   return {
//     settlementId: userData?._id || "",
//     caseId: userData?.caseId || "",
//     case: userData?.case || "",
//     type: userData?.type || "",
//     settlingParty: userData?.settlingParty || "",
//     disputedLiability: userData?.disputedLiability || "",
//     amount: userData?.amount || "",
//     monthTreated: userData?.monthTreated || "",
//     diagnoseTag: Array.isArray(userData?.diagnoseTag)
//       ? userData.diagnoseTag
//       : userData?.diagnoseTag?.split(", ").filter(Boolean) || [],
//     treatmentsTag: Array.isArray(userData?.treatmentsTag)
//       ? userData.treatmentsTag
//       : userData?.treatmentsTag?.split(", ").filter(Boolean) || []
//   };
// }, [userData]);


  
  
  console.log("userdata in after:", initialUpdateValues);

  const mutation = useMutation(
    async ({ formData, file }) => {
      const token = user?.userData?.token;
      setBearerToken(token);
 
      return post("settlements", formData);
    },
    {
      onSuccess: async (response, { formData, file }) => {
       
        if (response.data.uniquePath) {
          if (response.status === 200) {
            // Use `response.status` here
            NotificationService.success("Settlements record has been added successfully.");
            navigate("/settlements");
          } else {
            NotificationService.error("An error occurred. Please try again.");
            navigate("/settlements");
          }
        } else {
          NotificationService.success("Settlements record has been updated successfully.");
          navigate("/settlements");
        }
      },
      onError: (error) => {
        if (error.response && error.response.status === 403) {
          NotificationService.error("Storage limit has been exceeded");
        } else {
          NotificationService.error("An error occurred. Please try again.");
        }
      },
    }
  );

  const handleSubmit = useCallback(
    async (values, actions) => {
      const { title, caseId, case: caseValue, type, settlingParty, amount, monthTreated, disputedLiability, diagnoseTag, treatmentsTag, totalMedicalBills } = values; // Destructure values early
  console.log(values, "values in submit")
      try {
        // Validate the current step
        await currentValidationSchema.validate(values, { abortEarly: false });
        console.log("validation", activeStep < validationSchemas.length - 1);
  
        if (activeStep < validationSchemas.length - 1) {
          actions.setTouched({});
          actions.setSubmitting(false);
          handleNext();
  
          return; // Early return for readability
        }
  
        // Prepare formData only when on the final step
        const formData = {
          title,
          caseId,
          case: caseValue,
          type,
          settlingParty,
          amount,
          totalMedicalBills,
          monthTreated,
          disputedLiability,
          diagnoseTag,
          treatmentsTag
        };
        console.log(formData, "formdata here ")
        mutation.mutate({ formData });
      } catch (error) {
        console.log("error:", error);
  
        if (error.name === "ValidationError") {
          const formattedErrors = error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {});
          actions.setErrors(formattedErrors);
        } else {
          NotificationService.error("Error! Please try again.");
        }
      } finally {
        actions.setSubmitting(false);
      }
    },
    [activeStep, currentValidationSchema, handleNext, mutation]
  );
  

  const mutationUpdate = useMutation(
    async ({ formData }) => {
      const token = user?.userData?.token;
      setBearerToken(token);
      // Make sure to include settlementId in the URL
      return put(`settlements/${formData.settlementId}`, formData);
    },
    {
      onSuccess: async (response) => {
        NotificationService.success("Settlement record has been updated successfully.");
        navigate("/settlements");
      },
      onError: (error) => {
        console.error("Update Error:", error);
        if (error.response?.status === 403) {
          NotificationService.error("Permission denied or storage limit exceeded");
        } else {
          NotificationService.error(error.response?.data?.message || "An error occurred. Please try again.");
        }
      },
    }
);

const handleUpdateSubmit = useCallback(
    async (values, actions) => {
      console.log(values,"values in handle update ")
      try {
        await currentValidationSchema.validate(values, { abortEarly: false });

        if (activeStep < validationSchemas.length - 1) {
          actions.setTouched({});
          actions.setSubmitting(false);
          handleNext();
          return;
        }

        const formData = {
          settlementId: values.settlementId, // Include settlementId for update
          title: values.title,
          caseId: values.caseId,
          case: values.case,
          type: values.type,
          personsId:values.personsId,
          settlingParty: values.settlingParty,
          amount: Number(values.amount) || 0,
          totalMedicalBills: Number(values.totalMedicalBills) || 0,
          monthTreated: Number(values.monthTreated) || 0,
          disputedLiability: typeof values.disputedLiability === 'boolean' 
          ? values.disputedLiability 
          : values.disputedLiability === "true" ? true : false,
          diagnoseTag: Array.isArray(values.diagnoseTag) 
            ? values.diagnoseTag 
            : values.diagnoseTag?.split(',').map(tag => tag.trim()).filter(Boolean) || [],
          treatmentsTag: Array.isArray(values.treatmentsTag) 
            ? values.treatmentsTag 
            : values.treatmentsTag?.split(',').map(tag => tag.trim()).filter(Boolean) || []
        };
console.log(formData, "formdata in update ")
        mutationUpdate.mutate({ formData });
      } catch (error) {
        console.error("Submit Error:", error);
        
        if (error.name === "ValidationError") {
          const formattedErrors = error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {});
          actions.setErrors(formattedErrors);
        } else {
          NotificationService.error(error.message || "Error! Please try again");
        }
        
        actions.setSubmitting(false);
      }
    },
    [activeStep, currentValidationSchema, handleNext, mutationUpdate]
);
  

  return {
    initialValues,
    initialUpdateValues,
    handleUpdateSubmit,
    isLoading: mutationUpdate.isLoading,
    handleSubmit,
    uploads,
    isSubmitting: mutation.isLoading,
    navigate,
    currentValidationSchema,
  };
};

export default useSettlementForm;
