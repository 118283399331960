import React from "react";
import { Formik, Form } from "formik";
import { Box, Paper, Button } from "@mui/material";
import { validationSchemaStepTwo } from "../../Validation/PersonValidation";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../../services/http.service";
import { theme } from "../../../constant/Theme";
import { PersonMatterRenderStep } from "../../Validation/PersonMatterValidation";
import LoaderIcon from "../../Icons/LoaderIcon";
import { ButtonUICancel, ButtonUIPrevious } from "../../Button";
const UpdatePersonMatterForm = ({ activeStep, steps, handleBack, handleSkip, isStepOptional, handleNext }) => {
  const validationSchemas = [validationSchemaStepTwo];
  const currentValidationSchema = validationSchemas[activeStep];
  const { user } = useSelector((state) => state?.reducer);
  const { token } = user?.userData;
  const navigate = useNavigate();
  const location = useLocation();
  const { userData } = location.state || {};
  const queryClient = useQueryClient();

  const { setBearerToken, post } = useAxios();
  const setUserData = async (newData) => {
    setBearerToken(token);
    const response = await post(`/personUpdateAssinMatter`, newData);
    return response.data;
  };
  const mutation = useMutation(setUserData, {
    onSuccess: (response) => {
      showSuccessToastMessage("Person Matter has been updated successfully.");
      navigate(`/persons/personsMatter/${response?._id}`);
      queryClient.invalidateQueries("personData");
    },
    onError: () => {
      showErrorToastMessage("Something was wrong!");
    },
  });
  const initialValues = {
    personId: userData?._id,
    caseId: userData?.caseId,
    role: userData?.role,
    policyLimit: userData?.policyLimit,
    insuranceType: userData?.insuranceType,
    physicianType: userData?.physicianType,
    policyNumber: userData?.policyNumber,
    note: userData?.note,
    pleadingServe: userData?.pleadingServe,
    depositionRequired: userData?.depositionRequired,
  };
  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };
  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };
  const handleFormSubmit = (values, actions) => {
    // Check if the fields of the current step are valid
    currentValidationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        if (true) {
          actions.setTouched({});
          actions.setSubmitting(false);
          mutation.mutate(values);
        }
      })
      .catch((errors) => {
        actions.setErrors(errors);
        actions.setSubmitting(false);
      });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={currentValidationSchema} onSubmit={handleFormSubmit} enableReinitialize>
      {(formik) => (
        <Form>
          <Paper
            sx={{
              width: "100%",
              backgroundColor: theme?.palette?.background?.paper,
              color: "lightgray",
              // margin: "25px 0 ",
              boxShadow: "none",
              padding: { xs: 0, sm: 2 },
              borderRadius:'8px'
            }}
            style={{ boxShadow: 0 }}
          >
            {PersonMatterRenderStep(activeStep, formik)}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                pt: 2,
              }}
            >
              {activeStep === 0 && <ButtonUICancel text={"Cancel"} onClick={() => navigate(`/persons/personsMatter/${userData?._id}`)} variant={"contined"} />}

              {activeStep > 0 && activeStep != 2 && <ButtonUIPrevious variant="outlined" text={"Previous"} onClick={handleBack} disabled={activeStep === 0} />}

              <Box sx={{ flex: "1 1 auto" }} />

              <Button
                type="submit"
                variant="contained"
                disabled={mutation.isLoading}
                sx={{
                  width: { xs: "100%", sm: "150px" },
                  mt: { xs: 1, sm: 1.5 },
                  mb: { xs: 0, sm: 2 },
                  "&:hover, &:focus": {
                    backgroundColor: "white",
                    color: "#FFFFFF",
                    backgroundColor: theme?.palette?.secondary?.btnPrimary,
                    boxShadow: 0,
                  },
                  "&:disabled": {
                    backgroundColor: theme?.palette?.secondary?.btnPrimary,
                    color: "white",
                    boxShadow: 0,
                  },
                  boxShadow: 0,
                  fontSize: "12px",
                  backgroundColor: theme?.palette?.secondary?.btnPrimary,
                  fontWeight: "600",
                  fontFamily: theme?.typography?.fontFamily,
                }}
              >
                {activeStep === steps.length - 1 && mutation.isLoading ? (
                  <>
                    <LoaderIcon color="#f7f7f7" /> Processing...
                  </>
                ) : activeStep === steps.length - 1 ? (
                  "Update"
                ) : (
                  "Next"
                )}
              </Button>
            </Box>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default UpdatePersonMatterForm;
