import {
    Grid,
    Typography,
    TextField,
    Autocomplete,
    Select,
    MenuItem,
    Box,
    Divider,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { FilterCancelBtn, FilterSubmitBtn } from "../../Button";
import CloseIcon from "@mui/icons-material/Close";
import { theme } from "../../../constant/Theme";

const SettlementModel = ({ closeModal, filter, setFilter }) => {
  
    const [isClearDisabled, setIsClearDisabled] = useState(
        filter && typeof filter == "object" && Object.keys(filter).length > 0
    );
    const { user } = useSelector((state) => state?.reducer);
    const [allCases, setAllCases] = useState([]);
    const [allPersons, setAllPersons] = useState([]);

    const formik = useFormik({
        initialValues: {
            caseTitle: filter?.caseTitle || "",
            settlingParty: filter?.settlingParty || "",
            amount: filter?.amount || "",
            // monthTreated: filter?.monthTreated || dayjs().format("YYYY-MM-DD"),
        },
        onSubmit: (values) => {
            console.log(values, "values here ")
            setFilter(values);
            closeModal();
        },
    });

    const getAllCases = async () => {
        const token = user?.userData?.token;
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}settlements`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                const settlements = response.data.data;
                setAllCases(settlements.map(settlement => settlement.case.title));
                setAllPersons(
                    settlements.flatMap(settlement =>
                        settlement.settlingPartyDetails.map(party => ({
                            _id: party._id,
                            personName: party.companyName || `${party.firstName} ${party.lastName}`.trim() || "Unknown",
                        }))
                    )
                );
            }
        } catch (error) {
            console.error("Error fetching settlement data", error);
        }
    };

    useEffect(() => {
        getAllCases();
    }, []);

    return (
        <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 2 }}>
            <Grid container spacing={3}>
                <Grid item xs={11} sm={11} sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                        variant="h5"
                        sx={{
                            color: theme.palette.primary.main,
                            fontFamily: theme.typography.fontFamily,
                        }}
                    >
                        Filters
                    </Typography>
                </Grid>

                <Grid item xs={1} sm={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <CloseIcon sx={{ cursor: "pointer" }} onClick={closeModal} />
                </Grid>

                <Grid item xs={12}>
                    <Divider sx={{ backgroundColor: theme.palette.divider, marginY: "2px" }} />
                </Grid>

                <Grid item xs={12} sm={12} sx={{ paddingTop: "5px" }}>
                    <Typography variant="body1" sx={{ marginBottom: "4px" }}>Matter</Typography>
                    <Autocomplete
                        id="caseTitle"
                        options={allCases}
                        value={formik.values.caseTitle || null}
                        onChange={(event, newValue) => formik.setFieldValue("caseTitle", newValue || "")}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select Matter"
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} sm={12} sx={{ paddingTop: "5px" }}>
    <Typography variant="body1" sx={{ marginBottom: "4px" }}>Settling Party</Typography>
    <Autocomplete
        id="settlingParty"
        options={allPersons}
        value={allPersons.find(person => person._id === formik.values.settlingParty) || null}
        onChange={(event, newValue) => formik.setFieldValue("settlingParty", newValue ? newValue._id : "")}
        getOptionLabel={(option) => option.personName || ""}
        renderInput={(params) => (
            <TextField
                {...params}
                placeholder="Select a Settling Party"
                variant="outlined"
                size="small"
                fullWidth
            />
        )}
    />
</Grid>


                <Grid item xs={12} sm={12} sx={{ paddingTop: "10px" }}>
                    <Typography variant="body1" sx={{ marginBottom: "4px" }}>Amount</Typography>
                    <TextField
                        fullWidth
                        size="small"
                        type="number"
                        name="amount"
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                    />
                </Grid>

                <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", paddingTop: "10px" }}>
                    <FilterCancelBtn
                        text="Clear Filter"
                        onClick={() => {
                            setFilter({});
                            closeModal();
                        }}
                        disabled={!isClearDisabled}
                    />
                    <FilterSubmitBtn title="Apply Filter" type="submit" />
                </Grid>
            </Grid>
        </Box>
    );
};

export default SettlementModel;
