import React, { useState } from "react";
import { Formik, Form } from "formik";
import { Box, Paper, Button } from "@mui/material";
import { validationSchemaStepOne, validationSchemaStepTwo, validationSchemaStepThree, validationSchemaStepFour, JurisprudenceRenderStep } from "../../Validation/JurisprudenceCaseValiation";
import axios from "axios";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderIcon from "../../Icons/LoaderIcon";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade";
import notificationService from "../../../services/notification.service";
import useStorageValidation from "../../../hooks/useStorageValidation";
import styles from "./style";
import { theme } from "../../../constant/Theme";
import { ButtonUICancel, ButtonUIPrevious } from "../../Button";
const UpdateJurisprudenceForm = ({ activeStep, steps, handleBack, scrollToRef, handleSkip, isStepOptional, handleNext, ref }) => {
  const validationSchemas = [validationSchemaStepOne, validationSchemaStepTwo, validationSchemaStepThree, validationSchemaStepFour];
  const currentValidationSchema = validationSchemas[activeStep];
  const user = useSelector((state) => state?.reducer?.user);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { uploadFiles, uploads } = useMultiFileUpload();
  const { validateAndUpload } = useStorageValidation();
  const location = useLocation();
  const { userData } = location.state || {};
  const initialValues = {
    issueId: userData?._id,
    name: userData?.name || "",
    summaryOfLaw: userData?.summaryOfLaw || "",
    caseOneCitation: userData?.caseOneCitation || "",
    factOne: userData?.factOne || "",
    lawOne: userData?.lawOne || "",
    rullingOne: userData?.rullingOne || "",
    issueCaseFileOne: userData?.issueCaseFileOne || "",
    caseTwoCitation: userData?.caseTwoCitation || "",
    factTwo: userData?.factTwo || "",
    lawTwo: userData?.lawTwo || "",
    rullingTwo: userData?.rullingTwo || "",
    issueCaseFileTwo: userData?.issueCaseFileTwo || "",
    caseThreeCitation: userData?.caseThreeCitation || "",
    factThree: userData?.factThree || "",
    lawThree: userData?.lawThree || "",
    rullingThree: userData?.rullingThree || "",
    issueCaseFileThree: userData?.issueCaseFileThree || "",
  };
  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };
  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };
  const handleFormSubmit = async (values, actions) => {
    // Check if the fields of the current step are valid
    currentValidationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        scrollToRef();
        if (activeStep < validationSchemas.length - 1) {
          if (checkIfFieldsAreEmpty(values) && activeStep === steps.length - 2) {
            await submitForm(values, actions);
          } else {
            actions.setTouched({});
            actions.setSubmitting(false);
            handleNext();
          }
        } else {
          await submitForm(values, actions);
        }
      })
      .catch((error) => {
        notificationService.error("An error occurred. Please try again.");
        actions.setErrors(error);
        actions.setSubmitting(false);
        setIsSubmitting(false);
      });
  };

  const submitForm = async (values, actions) => {
    const token = user?.userData?.token;

    const data = {
      ...values,
      fileName1: values.issueCaseFileOne?.name || "",
      fileSize1: values.issueCaseFileOne?.size || 0,
      fileEdited: values.editedCaseFileOne,
      fileName2: values.issueCaseFileTwo?.name || "",
      fileSize2: values.issueCaseFileTwo?.size || 0,
      fileEdited: values.editedCaseFileTwo,
      fileName3: values.issueCaseFileThree?.name || "",
      fileSize3: values.issueCaseFileThree?.size || 0,
      fileEdited: values.editedCaseFileThree,
    };
    setIsSubmitting(true);

    const fileSizeValidation = data.fileSize1 + data.fileSize2 + data.fileSize3;
    const validationResult = await validateAndUpload(fileSizeValidation);

    if (validationResult) {
      try {
        const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}Jurisprudence`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response?.status === 200) {
          const { uniquePathOne, uniquePathTwo, uniquePathThree, updatedIssue } = response.data;

          const uploadConfigs = [
            { file: values.issueCaseFileOne, uniquePath: uniquePathOne, index: 1 },
            { file: values.issueCaseFileTwo, uniquePath: uniquePathTwo, index: 2 },
            { file: values.issueCaseFileThree, uniquePath: uniquePathThree, index: 3 },
          ];

          // Use a for...of loop to ensure asynchronous uploads are handled properly
          for (const { file, uniquePath, index } of uploadConfigs) {
            if (file && uniquePath) {
              await uploadFiles({
                id: `${updatedIssue?._id}_${index}`,
                title: `Jurisprudence Record - ${values.name}`,
                file,
                url: `status/Jurisprudence/${updatedIssue?._id}/${index}`,
                uniquePath,
              });
            }
          }

          showSuccessToastMessage("Jurisprudence Record has been Update successfully.");

          navigate("/Jurisprudence");
        }

        actions.setSubmitting(false);
        setIsSubmitting(false);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          notificationService.error("Storage limit has been exceeded");
        } else {
          notificationService.error("An error occurred. Please try again.");
        }

        setIsSubmitting(false);
        actions.setSubmitting(false);
      }
    }
  };

  const checkIfFieldsAreEmpty = (values) => {
    return (
      !values.caseTwoCitation &&
      !values.factTwo &&
      !values.lawTwo &&
      !values.rullingTwo &&
      !values.issueCaseFileTwo &&
      !values.caseThreeCitation &&
      !values.factThree &&
      !values.lawThree &&
      !values.rullingThree &&
      !values.issueCaseFileThree
    );
  };
  return (
    <Formik initialValues={initialValues} validationSchema={currentValidationSchema} onSubmit={handleFormSubmit} enableReinitialize innerRef={ref}>
      {(formik) => (
        <Form>
          <Paper
            sx={{
              boxShadow: "none",
              width: "100%",
              backgroundColor: theme?.palette?.background?.paper,
              padding: { xs: 0, sm: 2 },
              borderRadius: "8px",
            }}
          >
            {JurisprudenceRenderStep(activeStep, formik)}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                pt: 2,
              }}
            >
              {activeStep === 0 && <ButtonUICancel text={"Cancel"} onClick={() => navigate("/Jurisprudence")} variant={"contined"} />}
              {activeStep > 0 && activeStep < 4 && <ButtonUIPrevious variant="outlined" text={"Previous"} onClick={handleBack} disabled={activeStep === 0} />}

              <Box sx={{ flex: "1 1 auto" }} />
              <Button type="submit" disabled={isSubmitting} variant="contained" sx={styles?.submitButton}>
                {checkIfFieldsAreEmpty(formik.values) && activeStep === steps.length - 2 ? (
                  isSubmitting ? (
                    <>
                      <LoaderIcon color="#f7f7f7" /> Processing...
                    </>
                  ) : (
                    "Finish"
                  )
                ) : activeStep === steps.length - 1 && isSubmitting ? (
                  <>
                    <LoaderIcon color="#f7f7f7" /> Processing...
                  </>
                ) : activeStep === steps.length - 1 ? (
                  "Finish"
                ) : (
                  "Next"
                )}
              </Button>
            </Box>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateJurisprudenceForm;
