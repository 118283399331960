import React from "react";

const SettlementMedicalIcon = ({ color }) => {
  return (
    <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5 0.75V9H4.52525L5 7.815V2.25H12.5V6.75H17V18.75H5V14.4345L4.96025 14.3948L4.067 13.5H3.5V20.25H18.5V5.6955L18.2892 5.46075L13.7892 0.96075L13.5545 0.75H3.5ZM14 3.3285L15.9222 5.25H14V3.3285ZM7.22675 6.29025L5.47475 10.6642L5.3105 10.5H0.5V12H4.6895L6.02225 13.3358L7.27325 10.2098L9.52325 15.4598L11.0758 11.5785L11.2872 12H12.7025C12.8676 12.286 13.1224 12.5095 13.4275 12.6358C13.7326 12.7622 14.0708 12.7844 14.3898 12.6989C14.7087 12.6134 14.9906 12.4251 15.1916 12.1631C15.3926 11.9012 15.5015 11.5802 15.5015 11.25C15.5015 10.9198 15.3926 10.5988 15.1916 10.3369C14.9906 10.0749 14.7087 9.88657 14.3898 9.80111C14.0708 9.71565 13.7326 9.73782 13.4275 9.86418C13.1224 9.99054 12.8676 10.214 12.7025 10.5H12.2128L10.9235 7.9215L9.476 11.5402L7.22675 6.29025Z"
        fill={color}
      />
    </svg>
  );
};

export default SettlementMedicalIcon;
