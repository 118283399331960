import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useAxios } from "../../services/http.service";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { theme } from "../../constant/Theme";
import { FilterCancelBtn } from "../Button";

const style = {
  // position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  // width: { xs: " 325px", sm: "400px" },
  // bgcolor: "#4A4C64",
  // borderRadius: "8px",
  // boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
  // p: 4,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: { xs: "90%", sm: "auto" },
  width: { xs: "90%", sm: "500px" }, // Set a fixed width
  maxWidth: "100%", // Ensure it does not exceed screen size
  boxShadow: 24,
  p: 4,
  height: "auto",
  backgroundColor: theme?.palette?.primary?.bodyColor,
  borderRadius: "4px",
  border: "1px solid #FFFFFF",
};

export default function DeleteModel({ data, deleteUrl, setOpen, open }) {
  const handleClose = () => setOpen(false);
  const queryClient = useQueryClient();
  const { token } = useSelector((state) => state?.reducer?.user?.userData);
  const { user } = useSelector((state) => state?.reducer);
  console.log(user.organizationData._id);

  const { setBearerToken, post } = useAxios();
  const showSuccessToastMessage = (msg) => {
    return toast.success(msg, {
      position: "bottom-right",
    });
  };
  const showErrorToastMessage = (msg) => {
    return toast.error(msg, {
      position: "bottom-right",
    });
  };

  const DeleteUser = async (newData) => {
    setBearerToken(token);
    newData.organizationId = user.organizationData._id;
    const response = await post(deleteUrl, newData);
    return response.data;
  };

  const mutation = useMutation(DeleteUser, {
    onSuccess: () => {
      showSuccessToastMessage("Record has been deleted successfully.", {
        position: "bottom-right",
      });
      handleClose();

      const urlToQueryMap = {
        // "/deleteCase": "caseData",
        "/deleteClient": "clientData",
        // "/matterDelete": "matterData",
        "/deleteMedical": "medicalData",
        "/deleteExhibit": "exhibitData",
        "/deleteDiscovery": "discoveryData",
        "/deleteDeposition": "depositionData",
        "/deleteCorrespondence": "correspondenceData",
        "/deletePleadings": "pleadingsData",
        "/deleteCourt": "courtData",
        "/deleteJurisprudence": "JurisprudenceData",
        "/personDeleteAssinMatter": "personData",
        "/personDelete": "personData",
        "/deletesettlements": "settlementsData",
      };

      const queryKey = urlToQueryMap[deleteUrl] || "userData";
      queryClient.invalidateQueries(queryKey);
    },
    onError: (error) => {
      if (deleteUrl == "/deleteClient") {
        showErrorToastMessage(
          "This client cannot be deleted because they are linked to a  matter.",
          {
            position: "bottom-right",
          }
        );
      } else if ("/personDeleteAssinMatter" == deleteUrl) {
        if (
          error?.response?.data?.message ===
          "Cannot delete the last case. A person must have at least one case."
        ) {
          showErrorToastMessage(
            "Cannot delete the last matter for this person.",
            {
              position: "bottom-right",
            }
          );
        }
      } else if ("/personDelete" == deleteUrl) {
        if (error?.response) {
          showErrorToastMessage(error?.response?.data?.message, {
            position: "bottom-right",
          });
        }
      } else {
        showErrorToastMessage("Something was wrong!", {
          position: "bottom-right",
        });
      }
      handleClose();
    },
  });

  const handleDelete = () => {
    const newdata = {
      personId: data._id,
      caseId: data.caseId,
    };
    if ("/personDeleteAssinMatter" == deleteUrl) {
      mutation.mutate(newdata);
    } else {
      const values = { id: data._id };

      mutation.mutate(values);
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{
              ...theme?.typography?.title,
              fontFamily: theme?.palette?.primary?.main,
            }}
          >
            Delete confirmation
          </Typography>
          <Typography
            id="modal-modal-description"
            color={theme?.palette?.primary?.placeholderColor}
            sx={{ mt: 2 }}
          >
            Are you sure you want to delete this record?
          </Typography>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <FilterCancelBtn text={"Cancel"} onClick={handleClose} />
              <Button
                onClick={() => handleDelete()}
                variant="contained"
                sx={{
                  width: { xs: "100%", sm: "150px" },
                  mt: { xs: 1, sm: 1.5 },
                  mb: { xs: 0, sm: 2 },
                  height: "45px",
                  "&:hover, &:focus": {
                    boxShadow: theme?.shadows?.[1],
                    backgroundColor: "white",
                    color: "#FFFFFF",
                    color: "#FFFFFF",
                    backgroundColor: theme?.palette?.secondary?.dangerMain,
                    boxShadow: 0,
                  },

                  boxShadow: 0,
                  fontSize: "12px",
                  backgroundColor: theme?.palette?.secondary?.dangerMain,
                  fontWeight: "600",
                  fontFamily: theme?.typography?.fontFamily,
                  ...theme?.typography?.smallText,
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
