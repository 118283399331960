import MainLayout from "../../layouts/MainLayout";
import { Box, Button, CssBaseline, Grid, Paper, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TableComponent from "../../Components/Table";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import PopupModel from "../../Components/Modal";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useAxios } from "../../services/http.service";
import { useQuery } from "react-query";
import { useMultiFileUpload } from "../../context/useMultiFileUploade";
import { theme } from "../../constant/Theme";
import PersonsMatterModel from "../../Components/Modal/PersonsModel/PersonMatterFilter";
import { AddBtnUI } from "../../Components/Button";
import { PaperComponent } from "../../Components/Card/SettingCard";
import ArrowBack from "../../Components/Icons/ArrowBack";
const PersonsMattersPages = () => {
  const navigate = useNavigate();
  const [openPersons, setOpenPersons] = useState(false);
  const handleOpen = () => setOpenPersons(true);
  const handleClose = () => setOpenPersons(false);
  const { personId } = useParams();
 
  const HeadingTable = ["Matter", "Role", "Type", "Policy Limit", "Policy Number", "Action"];
  const ValuesKeysTable = ["matter", "role", "type", "policyLimit", "policyNumber"];
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [filterSearch, setFilterSearch] = useState({});

  const { token } = useSelector((state) => state?.reducer?.user?.userData);

  const [limit, setLimit] = useState(10);
  const { get, setBearerToken } = useAxios();
  const { matter } = useMultiFileUpload();
  const location = useLocation();

  const fetchData = async () => {
    if (!personId) {
      return;
    }
    let endpoint = `/personSignle/${personId}/?page=${currentPage}&limit=${limit}`;

    if (searchText) {
      endpoint += `&search=${searchText}`;
    }

    // Add global filter if available
    if (matter) {
      endpoint += `&global=${encodeURIComponent(JSON.stringify(matter))}`;
    }

    // Add filterSearch if available
    if (filterSearch && Object.keys(filterSearch).length > 0) {
      endpoint += `&filter=${encodeURIComponent(JSON.stringify(filterSearch))}`;
    }
    setBearerToken(token);
    const response = await get(endpoint);
    return response?.data?.data;
  };
  const { data, isLoading } = useQuery(["personData", personId, matter, currentPage, searchText, limit, filterSearch], fetchData, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  let finalData = data;
  if (data) {
    const manipulatedData = data?.person?.paginatedCases?.map((caseDoc) => {
      // Create a deep copy of cases
      // const casesCopy = caseDoc?.cases ? JSON.parse(JSON.stringify(caseDoc.cases)) : [];
      // const caseFilter = casesCopy.map((v) => v.title);

      const casesCopy = caseDoc?.cases ? JSON.parse(JSON.stringify(caseDoc.cases)) : [];
      const caseFilterId = casesCopy.map((v) => v._id);
      // Filter out any cases with missing or empty titles, then map the titles and join them
      const caseFilter = Array.isArray(casesCopy)
        ? casesCopy
            .filter((v) => v?.title && v.title.trim() !== "") // Ensure the title exists and is not empty
            .map((v) => v.title) // Map over the valid titles
            .join(", ") // Join all valid titles with commas
        : ""; // Fallback if no valid cases

      return {
        _id: data?.person?._id,
        caseId: caseDoc?.caseId,
        matter: caseDoc?.caseDetails?.title,
        role: caseDoc?.role,
        policyLimit: caseDoc?.policyLimit,
        policyNumber: caseDoc?.policyNumber,
        insuranceType: caseDoc?.insuranceType,
        type: caseDoc?.insuranceType ? caseDoc?.insuranceType : caseDoc?.physicianType,
        physicianType: caseDoc?.physicianType,
        depositionRequired: caseDoc?.depositionRequired,
        pleadingServe: caseDoc?.pleadingServe,
        createdAt: caseDoc?.createdAt,
        pleadingServe: caseDoc?.pleadingServe,
        depositionRequired: caseDoc?.depositionRequired,
      };
    });
    // Further processing or table rendering logic can go here...
    finalData = {
      ...finalData,
      data: manipulatedData,
    };
  }

  return (
    <MainLayout>
      <PopupModel
        open={openPersons}
        styleProp={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // width: { xs: "90%", sm: "auto" },
          width: { xs: "90%", sm: "500px" }, // Set a fixed width
          maxWidth: "100%", // Ensure it does not exceed screen size
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          height: "auto",
          backgroundColor: "#FFFFFF",
          boxShadow: 0,
          border: "1px solid #FFFFFF",
          borderRadius: "4px",
        }}
        handleClose={handleClose}
        cards={<PersonsMatterModel closeModal={handleClose} filter={filterSearch} setFilter={setFilterSearch} />}
      />
      <Grid item xs={12}>
        <PaperComponent>
          <Box
            sx={{
              minHeight: "100vh",
              maxHeight: { xs: 2000, sm: 2000 },
              position: "relative",
            }}
          >
            <CssBaseline />
            {/* here is heading  */}
            <Grid container spacing={2} style={{ padding: "14px" }}>
              <Grid
                item
                xs={8}
                sm={8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Link to={"/persons"} style={{ paddingTop: "10px", color: "black" }}>
                  <ArrowBack />
                </Link>
                <Typography variant="h5" typography={theme?.typography?.fontFamily} color={theme?.palette?.primary?.main} sx={{ ...theme?.typography?.title }}>
                  Persons /&nbsp;
                  <Box component="span" typography={theme?.typography?.fontFamily} color={theme?.palette?.primary?.subPrimary} sx={{ ...theme?.typography?.title, textTransform: "capitalize" }}>
                    {data?.person?.firstName ? `${data.person.firstName} ${data?.person?.lastName}` : data?.person?.companyName}
                  </Box>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <AddBtnUI title="ASSIGN NEW MATTER" onClick={() => navigate(`/persons/personsMatter/addpersonMatter/${personId}`)} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {/* table section */}
              <Grid item xs={12} sm={12}>
                <TableComponent
                  filterSearch={filterSearch}
                  setFilterSearch={setFilterSearch}
                  filter={true}
                  handleOpen={handleOpen}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  limit={limit}
                  setLimit={setLimit}
                  data={finalData}
                  headingTable={HeadingTable}
                  valuesKeys={ValuesKeysTable}
                  status={false}
                  statusActiveUrl={""}
                  viewPageUrl={`/persons/personsMatter/ViewPersonMatter`}
                  deleteUrl={"/personDeleteAssinMatter"}
                  isDelete={true}
                  editPageUrl={"/persons/personsMatter/updatepersonMatter"}
                />
              </Grid>
            </Grid>
          </Box>
        </PaperComponent>
      </Grid>
    </MainLayout>
  );
};

export default PersonsMattersPages;
